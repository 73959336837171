export const isPermissionExist = (featId: any[]) => {
    const logInUserData = JSON.parse(localStorage.getItem('currentUser'));
    const accessFlag = logInUserData?.menuAccessObject || []
    const features = []
    accessFlag?.forEach((feat) => {
        const ids = feat?.features?.split(',');
        ids?.forEach((fe) => {
            if(!features.includes(+fe))
            features.push(+fe);

        } )
    })
    // console.log('features', features)
    const scopeCheck = features?.some((acc) => featId?.includes(acc));
    const admin = features?.includes(1);
    return scopeCheck || admin;
    // return true;
}

export const isPermissionExistMenuLevel = (menu: string, subParentMenu: string, subMenu: string, scope: string) => {
    let logInUserData = JSON.parse(localStorage.getItem('currentUser'));
    const accessFlag = logInUserData.menuAccessObject.find((item: any) => item.title == menu).scopes;
    const scopeCheck = accessFlag.includes(scope);
    return scopeCheck
}

export const isPermissionExistThirdLayer = (menu: string, subParentMenu: string, subMenu: string, scope: string) => {
    let logInUserData = JSON.parse(localStorage.getItem('currentUser'));
    const accessFlag = logInUserData.menuAccessObject.find((item: any) => item.title == menu).subMenuData.find((submenuItem: any) => submenuItem.title == subMenu).scopes;
    const scopeCheck = accessFlag.includes(scope);
    return scopeCheck
}

export const isRoleExist = (role: string) => {

}
