import { BPDropDownDto, BusinessPartnerService, CommodityDropDownDto, CommodityService, TaxDropDownDto, TaxesAndItemsCreate, TaxesAndItemsService, TaxesService } from '@exportx/shared-models-and-services'
import { Button, Card, Col, Form, Row, Select, } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertMessages, useAuthState } from '../../../../common'



export const TaxesAndItemsForm = () => {

    const { Option } = Select

    const businessPartnerService = new BusinessPartnerService()
    const taxesService = new TaxesService()
    const [businessPartner, setBusinessPartner] = useState<BPDropDownDto[]>([])
    const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
    const [commodityDropDown, setCommodityDropDown] = useState<CommodityDropDownDto[]>([])
    const commodityService = new CommodityService();
    const taxesAndItemsService = new TaxesAndItemsService()
    const [formRef] = Form.useForm()
    const { authContext } = useAuthState()
    const { state }: any = useLocation()
    const { record, hidden } = state ? state : { record: null, hidden: null }
    const navigate = useNavigate()

    useEffect(() => {
        getAllBusinessPartnersDropDown();
        taxActiveDropDown();
        getAllCommodityDropDown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllBusinessPartnersDropDown = () => {
        businessPartnerService.getAllBusinessPartnersDropDown().then(res => {
            if (res.status) {
                setBusinessPartner(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }


    const taxActiveDropDown = () => {
        taxesService.getActiveTaxDropDown().then(res => {
            if (res.status) {
                setTaxDropDown(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const getAllCommodityDropDown = () => {
        commodityService.getAllCommodityDropDown().then(res => {
          
            if (res.status) {
                setCommodityDropDown(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const taxesAndItemsCreation = () => {
        formRef.validateFields().then(values => {
            const req = new TaxesAndItemsCreate(
                values.businessPartner,
                values.taxName,
                values.taxPercentage,
                values.commodityName,
                authContext.user.userName,
                record?.taxesItemsId,
                record?.isActive,
                record?.versionFlag
            )
            taxesAndItemsService.createTaxesAndItems(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setTimeout(() => {
                        navigate('/items-and-taxes')
                    }, 1000)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    return (
        <>
            <Card title={"Taxes and Items"}
                className='default-card-class' extra={<span><Button onClick={() => { navigate('/items-and-taxes') }}>View</Button></span>}>
                <Form layout='vertical' initialValues={record} form={formRef} >
                    <Row>
                        <Col
                        // xs={{ span: 24 }} lg={{ span: 5 }} offset={1}
                        >
                            <Form.Item name={"businessPartner"} label={"Business Partner"} style={{ width: '80%', display: "none" }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder="PLease Select"     >
                                    {businessPartner.map((rec) => {
                                        return <Option value={rec.bpId} key={rec.bpId}>{rec.bpName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item name={"taxName"} label={"Tax Name"} style={{ width: '80%' }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder="PLease Select"   >
                                    {taxDropDown.map((rec) => {
                                        return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item name={"taxPercentage"} label={"Tax Percentage"} style={{ width: '80%' }}>
                                <Select   filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}  allowClear showSearch placeholder="PLease Select" allowClear >
                                    <Option value={''}>Please Select</Option>
                                    {taxDropDown.map((rec) => {
                                        return <Option value={rec.taxId}>{rec.taxPercentage}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item name={"commodityName"} label={"Item Name"} style={{ width: '80%' }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder="PLease Select"  >
                                    {commodityDropDown.map((rec) => {
                                        return <Option value={rec.commodityId} key={rec.commodityId}>{rec.commodityName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row><br></br>
                    <span style={{ float: 'right' }}><Button key={Date.now()} type='primary' style={{ display: hidden ? 'none' : 'block' }} onClick={() => { taxesAndItemsCreation(); }}>Submit</Button></span>

                </Form>
            </Card>

        </>
    )
}

export default TaxesAndItemsForm