import React, { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { CalculationFilterDataReq, CommercialCoalCostingService, ContractModesEnum, CostingWorkLogsEnum, CurrencyConverterService, PerFormaReqIdDto } from "@exportx/shared-models-and-services";
import { getLocalFormat, SequenceUtils } from "@exportx/ui-utils";
import { Table, Typography } from "antd";
import moment from "moment";
import { ApprovalTracking } from "./approval-tracking";
import { QualityPriceAdjustmentsDeatils } from "./quality-price-adjustments-deatils";

interface CmApprovalDetailsProps {
    costingId: string;
    contractType: ContractModesEnum;
    remainingAmount?: number;
}

export const CmApprovalDetails = (props: CmApprovalDetailsProps) => {
    const cmService = new CommercialCoalCostingService();
    const { costingId, contractType } = props;
    const { authContext } = useAuthState();
    const [advTotal, setAdvTotal] = useState<number>(0);

    const [data, setData] = useState<any>();
    const [BargeData, setBargeData] = useState([])
    const { Text } = Typography;

    useEffect(() => {
        getAllCommercialData()
    }, [])

    const getAllCommercialData = () => {

        const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
        cmService.getAllCommercialCostingsData(req).then(async res => {
            if (res.status) {
                const totalCoalPrice = (+res?.data?.totalBargeQuantity * +res?.data?.exchangeRateValue)
                const totalPayable = (+totalCoalPrice + (+res.data?.taxTotal1 + +res?.data?.taxTotal2) - +res?.data?.tdsTotal)
                const currencyConverterService = new CurrencyConverterService();
                let exchangeRate = 1;
                const currencyData = await currencyConverterService.getCurrencyExchangeRate({ baseCurrency: res?.data?.baseCurrency, exchangeDate: res?.data?.exchangeDate, quoteCurrency: res?.data?.quoteCurrency });
                if (currencyData?.data)
                    exchangeRate = currencyData?.data?.exchangeRate;
                setData({ ...res.data, totalPayable: totalPayable, totalCoalPrice: totalCoalPrice, exchangeRate: exchangeRate })
                const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
                // const bargeDetails = res.data?.transactionBarges?.map((item) => ({ ...item, businessNo: res.data?.bnId, incoterm: res.data?.incoterm, advAdjustment: advTotal, paid: 0 }))
                const bargeDetails = res.data?.transactionBarges?.map((item) => ({
                    ...item, incoterm: res.data?.incoterm, advAdjustment: advTotal,
                    totalPayable: totalPayable,
                    layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
                    remaining: + res.data?.invoiceTotal

                }))
                setBargeData(bargeDetails)
                setAdvTotal(advTotal)

            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                // setInitialValues(null);
            }
        }).catch(err => { console.log(err.message) });
    };

    const showFreightPrice = () => (contractType !== ContractModesEnum.PROCUREMENT_FEES && contractType !== ContractModesEnum.MARKETING_FEES)

    const sharedOnCell = (_, index?: number) => {
        if (index == 0) return { rowSpan: BargeData.length }
        else return { rowSpan: 0 }
    };

    const columns = [
        {
            title: 'Barge ID',
            dataIndex: 'bargeNo',
            key: 'bargeNo',
            render: (value, record) => {
                if (record.bgUId) {
                    const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
                    return <a href={link} className="link-primary" target="_blank">
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
                    </a>

                }
                return <span>{value}</span>

            }
        },
        {
            title: 'Barge Nomination',
            dataIndex: 'bgName',
            key: 'bgName',
        },
        {
            title: 'Business No',
            dataIndex: 'businessNo',
            key: 'businessNo',
        },
        {
            title: 'Laycan (Quality)',
            dataIndex: 'layCan',
            key: 'layCan',
            onCell: sharedOnCell,
        },
        {
            title: 'Inco Term',
            dataIndex: 'incoterm',
            key: 'incoterm',
            onCell: sharedOnCell,
        },
        {
            title: 'Quantity',
            dataIndex: 'inWardQty',
            key: 'inWardQty',
            onCell: sharedOnCell,
        },
        {
            title: 'Price PMT',
            dataIndex: 'adjustedPriceValue',
            key: 'adjustedPriceValue',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.adjustedPriceValue, record?.baseCurrency)}</span>

        },
        ...showFreightPrice() ? [{
            title: 'Freight Price',
            dataIndex: 'freightPricePmt',
            key: 'freightPricePmt',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.freightPricePmt, record?.baseCurrency)}</span>

        },] : [],

        {
            title: 'Price',
            dataIndex: 'exchangeRate',
            key: 'exchangeRate',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.exchangeRate, record?.quoteCurrency || record?.baseCurrency)}</span>
        },
        {
            title: 'Total Payable',
            dataIndex: 'totalPayable',
            key: 'totalPayable',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.totalPayable, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Advance Adjustments',
            dataIndex: 'advAdjustment',
            key: 'advAdjustment',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.advAdjustment, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.remaining, record?.quoteCurrency)}</span>

        },
    ];

    console.log('contractTye', contractType)
    return <>
        <Table dataSource={BargeData} columns={columns} pagination={false} style={{ width: '100%' }} className="inner-table" />

        <table className="table table-bordered table-sm mt-4">
            <tr>
                <th colSpan={2}>Exchange Date</th>
                <td>{data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</td>
            </tr>
            <tr>
                <th colSpan={2}>Exchange Rate</th>
                <td>
                    <Text>{getLocalFormat(data?.exchangeDate ? data?.exchangeRate : 1, data?.quoteCurrency)}</Text>
                </td>
            </tr>
            <tr>
                <th colSpan={2}>Price After Exchange Rate</th>
                <td>
                    <Text>{getLocalFormat(data?.exchangeRateValue, data?.quoteCurrency)}</Text>
                </td>
            </tr>
            <tr>
                <th colSpan={2}>Total Coal Price</th>
                <td>
                    <Text>{getLocalFormat(data?.totalCoalPrice, data?.quoteCurrency)}</Text>
                </td>
            </tr>
            <tr>
                <th colSpan={2}>FC Charges</th>
                <td>
                    <Text>{getLocalFormat(data?.fcCharges, data?.quoteCurrency)}</Text>

                </td>
            </tr>
            {/* <tr>
                <th colSpan={2}>Freight</th>
                <td>
                    <Text>{getLocalFormat(data?.freightPricePmt, data?.quoteCurrency)}</Text>

                </td>
            </tr> */}
            {/* <tr>
                <th colSpan={2}>Amount</th>
                <td>
                    <Text>{getLocalFormat(data?.totalPayable, data?.quoteCurrency)}</Text>

                </td>
            </tr> */}
            <tr>
                <th>TAX 1</th>
                <td>
                    <Text>{`${data?.taxName1 || ''} - ${data?.taxPercentage1 || ''}`}</Text>
                </td>
                <td>{getLocalFormat(data?.taxTotal1, data?.quoteCurrency)}</td>
            </tr>
            <tr>
                <th>TAX 2</th>
                <td>
                    <Text>{`${data?.taxName2 || ''} - ${data?.taxPercentage2 || ''}`}</Text>
                </td>
                <td>
                    <Text>{getLocalFormat(data?.taxTotal2, data?.quoteCurrency)}</Text>
                </td>
            </tr>
            <tr>
                <th>TDS:</th>
                <td><Text>{`${data?.tdsName || ''} - ${data?.tdsPercentage || ''}`}</Text></td>
                <td>{getLocalFormat(data?.tdsTotal, data?.quoteCurrency)}</td>
            </tr>
            <tr>
                <th colSpan={2} > Total Amount</th>
                <td>{getLocalFormat(data?.totalPayable, data?.quoteCurrency)}</td>

            </tr>
            <tr>
                <th colSpan={3} >
                    Advance Adjustments
                </th>
                <td></td>

            </tr>
            {data?.advanceTarnsactionsData?.map((adv) => (
                <tr key={adv.id}>
                    <th colSpan={2} >
                        Adv deduction against contract : {adv.adjustedCostingNo}
                    </th>
                    <td>{getLocalFormat(adv.totalAdjustedAmount, data?.quoteCurrency)}</td>

                </tr>
            ))}
            <tr>
                <th colSpan={2} >
                    Total Advance Adjustments
                </th>
                <td> <Text>{getLocalFormat(advTotal, data?.quoteCurrency)}</Text>
                </td>

            </tr>
            <tr>
                <th colSpan={2} >
                    To be paid to Supplier
                </th>
                <td>
                    <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.invoiceTotal, data?.quoteCurrency)}</span>
                </td>
            </tr>
            {props.remainingAmount &&
                <tr>
                    <th colSpan={2} >
                        Remaining Amount
                    </th>
                    <td>
                        <span style={{ color: 'red', fontWeight: 'bold' }}>{getLocalFormat(props?.remainingAmount, data?.quoteCurrency)}</span>
                    </td>
                </tr>
            }
        </table>
        <QualityPriceAdjustmentsDeatils
            contractType={contractType}
            selectedRowData={BargeData[0]}
            formInitialValues={data}

        />
        {/* {!props.remainingAmount && */}
        <>
            <br />
            <ApprovalTracking history={data?.workLog} />
        </>
        {/* } */}
    </>
}