import { CurrencyDeactivateDto, CurrencyDto, CurrencyService } from '@exportx/shared-models-and-services';
import type { ColumnsType } from 'antd/lib/table';
import { useIntl } from 'react-intl';
//import { AlertMessages } from '../../../../../common/notifications';
import { Button, Card } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, FilterableGrid, useAuthState } from '../../../../../common';


export const CurrencyGrid = () => {

    const { formatMessage: fm } = useIntl();
    const navigate = useNavigate();
    const { authContext } = useAuthState();
    const [gridData, setgridData] = useState<CurrencyDto[]>([]);

    const service = new CurrencyService();

    useEffect(() => {
        getGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getGridData = () => {
        service.getAllCurrencies().then(res => {
            if (res.status) {
                setgridData(res.data);
            } else {
                // AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }


    const activateOrDeactivate = (currencyData: CurrencyDto) => {
        const currencyDeactivateDto: CurrencyDeactivateDto = new CurrencyDeactivateDto(currencyData.currencyId, authContext.user.userName, currencyData.versionFlag);
        service.activateOrDeactivate(currencyDeactivateDto).then(res => {
            if (res.status) {
                getGridData();
                AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Currency ${currencyData.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }


    const view = (currencyData: CurrencyDto, isUpdate: boolean = false) => {
        let isNewRecord = true;
        if (currencyData) {
            isNewRecord = false;
        }
        let pathToreDirect = '/createCurrency';
        navigate(pathToreDirect, { state: { currencyData, isNewRecord, isUpdate } });
    }









    const columns: ColumnsType<any> = [
        {
            title: fm({ id: "mdm.components.currencyGrid.currencyName", defaultMessage: "Currency Name" }),
            dataIndex: 'currencyName',
            key: 'currencyName',
        },
        {
            title: fm({ id: "mdm.components.currencyGrid.currencyCode", defaultMessage: "Currency Code" }),
            dataIndex: 'currencyCode',
            key: 'currencyCode',
        },
        {
            title: fm({ id: "mdm.components.currencyGrid.currencySymbol", defaultMessage: "Currency Symbol" }),
            dataIndex: 'currencySymbol',
            key: 'currencySymbol',
        }


    ]
    return (
        <Card
            title={fm({ id: `Currency.header`, defaultMessage: `Currency Details` })}
            style={{ overflow: 'auto' }}
            className='default-card-class'
            extra={
                <Button
                    onClick={() => view(undefined, false)}
                >
                    {fm({ id: 'common.create', defaultMessage: 'Create' })}
                </Button>
            }
            bodyStyle={{ overflow: 'auto' }}
        >
            <FilterableGrid
                rowKey='currencyId'
                mainColumns={columns}
                data={gridData}
                filterColumnsData={[{ dataIndex: 'currency Name', placeHolder: fm({ id: "mdm.components.currencygrid.currencyName", defaultMessage: "CurrencyName" }) }, { dataIndex: 'currencyCode', placeHolder: fm({ id: "mdm.components.currencygrid.currencyCode", defaultMessage: "currencyCode" }) }, { dataIndex: 'currencySymbol', placeHolder: fm({ id: "mdm.components.currencygrid.currencySymbol", defaultMessage: "CurrencySymbol" }) }]}
                otherTableProps={{ bordered: true }} activateOrDeactivate={activateOrDeactivate} view={view}
            /></Card>



    )
}

export default CurrencyGrid;

