import { Card, Col, Form, Input, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import BusinessNumber from "../business-number/business-number";
import { BusinessNumberService, PlantCodeReqDto } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../../common";
import { SequenceUtils } from "@exportx/ui-utils";

const PlReports = () => {
    const [plReports, setPlReports] = useState<any[]>([])
    const businessNumberService = new BusinessNumberService()
    const { authContext } = useAuthState()
    const [searchedText, setSearchedText] = useState("");


    useEffect(() => {
        getAllPlReports()
        
    }, [])

    const getAllPlReports = () => {
        const req = new PlantCodeReqDto(authContext.defaultPlant);
businessNumberService.getAllPlReports(req).then(res => {
            if (res.status) {
                setPlReports(res.data);
            } 
        }).catch(err => {
            console.log(err.message)
        });
    }



    const columns: any = [
        {
            title: "Business number",
            dataIndex: "businessNo",
            width: '130px',
            align: 'center',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
           
        },
        {
            title: "Business Number Name",
            dataIndex: "motherVesselName",
            width: '130px',
            align: 'center',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: "MV BL date",
            dataIndex: "",
            width: '130px',
            align: 'center',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: "Total Purchases",
            dataIndex: "",
            width: '130px',
            align: 'center',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: "Total Sales",
            dataIndex: "",
            width: '130px',
            align: 'center',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
        },
    ]
    return (
        <Card title="PL Reports">
             <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />
            <Table
                columns={columns}
                pagination={false}
                dataSource={plReports} />
        </Card>
    )

}
export default PlReports;