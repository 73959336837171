import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const ContractsSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    purchase_contract: [],
    purchase_transfer: [],
    sales_contract: [],
    sales_transfer: [],
    weekly: [],
    price_calculation: [],
    monthly: [],
    barge_agreement: [],
    procurement_service: [],
    coal_service_fees: [],
    marketing_fees: [],
    vendor_advance: [],
    vendor_advance_invoices: [],
    vendor_advance_payments: [],
    down_payment: [],
    down_payment_invoices: [],
    down_payment_payments: [],
    advance_against_contracts: [],
    advance_against_contracts_invoices: [],
    advance_against_contracts_payments: [],
    advance_receivables: [],
    advance_receovables_invoices: [],
    advance_receivables_payments: [],

  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          sales_contract: features?.filter((cou) => 95 <= +cou && +cou <= 107)?.map(Number),
          sales_transfer: features?.filter((cou) => 108 <= +cou && +cou <= 109)?.map(Number),
          purchase_contract: features?.filter((cou) => 110 <= +cou && +cou <= 122)?.map(Number),
          purchase_transfer: features?.filter((cou) => 123 <= +cou && +cou <= 124)?.map(Number),
          weekly: features?.filter((cou) => 125 <= +cou && +cou <= 128)?.map(Number),
          price_calculation: features?.filter((cou) => 129 <= +cou && +cou <= 132)?.map(Number),
          monthly: features?.filter((cou) => 133 <= +cou && +cou <= 136)?.map(Number),
          barge_agreement: features?.filter((cou) => 137 <= +cou && +cou <= 145)?.map(Number),
          procurement_service: features?.filter((cou) => 146 <= +cou && +cou <= 154)?.map(Number),
          coal_service_fees: features?.filter((cou) => 155 <= +cou && +cou <= 163)?.map(Number),
          marketing_fees: features?.filter((cou) => 164 <= +cou && +cou <= 172)?.map(Number),
          vendor_advance: features?.filter((cou) => 173 <= +cou && +cou <= 180)?.map(Number),
          vendor_advance_invoices: features?.filter((cou) => 181 <= +cou && +cou <= 188)?.map(Number),
          vendor_advance_payments: features?.filter((cou) => 189 <= +cou && +cou <= 192)?.map(Number),
          down_payment: features?.filter((cou) => 193 <= +cou && +cou <= 200)?.map(Number),
          down_payment_invoices: features?.filter((cou) => 201 <= +cou && +cou <= 208)?.map(Number),
          down_payment_payments: features?.filter((cou) => 209 <= +cou && +cou <= 212)?.map(Number),
          advance_against_contracts: features?.filter((cou) => 213 <= +cou && +cou <= 220)?.map(Number),
          advance_against_contracts_invoices: features?.filter((cou) => 221 <= +cou && +cou <= 228)?.map(Number),
          advance_against_contracts_payments: features?.filter((cou) => 229 <= +cou && +cou <= 232)?.map(Number),
          advance_receivables: features?.filter((cou) => 233 <= +cou && +cou <= 240)?.map(Number),
          advance_receovables_invoices: features?.filter((cou) => 241 <= +cou && +cou <= 248)?.map(Number),
          advance_receivables_payments: features?.filter((cou) => 249 <= +cou && +cou <= 252)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Card size="small" title='Purchase'>
        <Card size="small" type="inner" title="Purchase Contract">
          <Form.Item name="purchase_contract" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled

                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={110}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={111}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={112}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={113}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={114}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={115}>Clone</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={116}>Addendum</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={117}>Transfer</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={118}>Freeze</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={119}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={120}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={121}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={122}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Purchase Transfer">
          <Form.Item name="purchase_transfer" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled

                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={123}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={124}>Approve</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>

      </Card>

      <br />
      <Card size="small" title='Sales'>

        <Card size="small" type="inner" title="Sales Contract">
          <Form.Item name="sales_contract" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={95}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={96}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={97}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={98}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={99}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={100}>Clone</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={101}>Addendum</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={102}>Transfer</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={103}>Freeze</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={104}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={105}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={106}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={107}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Sales Transfer">
          <Form.Item name="sales_transfer" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled

                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={108}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={109}>Approve</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />
      <Card size="small" title='Coal Price Indexes'>

        <Card size="small" type="inner" title="Weekly">
          <Form.Item name="weekly" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={125}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={126}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={127}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={128}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Price Calculation">
          <Form.Item name="price_calculation" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled

                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={129}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={130}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={131}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={132}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Monthly">
          <Form.Item name="monthly" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled

                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={133}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={134}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={135}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={136}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />

      <Card size="small" title="Barging Agreeent">
        <Form.Item name="barge_agreement" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              <Col span={6}>
                <Checkbox
                  value='1,2,3,4'
                  disabled
                >
                  Select All
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={137}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={138}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={139}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={140}>Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={141}>View Price</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={142}>Approve</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={143}>Reject</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={144}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={145}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title='Service Contracts'>

        <Card size="small" type="inner" title="Procurement Service">
          <Form.Item name="procurement_service" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={146}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={147}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={148}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={149}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={150}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={151}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={152}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={153}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={154}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Coal Service Fees">
          <Form.Item name="coal_service_fees" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={155}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={156}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={157}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={158}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={159}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={160}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={161}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={162}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={163}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Marketing Fees">
          <Form.Item name="marketing_fees" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Checkbox
                    value='1,2,3,4'
                    disabled
                  >
                    Select All
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={164}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={165}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={166}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={167}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={168}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={169}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={170}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={171}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={172}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />
      <Card size="small" title='Advances'>

        <Card size="small" title='Vendor Advance'>
          <Card size="small" type="inner" title="Vendor Advance">
            <Form.Item name="vendor_advance" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={173}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={174}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={175}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={176}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={177}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={178}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={179}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={180}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Vendor Advance Invoices">
            <Form.Item name="vendor_advance_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={181}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={182}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={183}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={184}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={185}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={186}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={187}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={188}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Vendor Advance Payments">
            <Form.Item name="vendor_advance_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={189}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={190}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={191}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={192}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Down Payment'>

          <Card size="small" type="inner" title="Down Payment">
            <Form.Item name="down_payment" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={193}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={194}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={195}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={196}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={197}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={198}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={199}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={200}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Down Payment Invoices">
            <Form.Item name="down_payment_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={201}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={202}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={203}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={204}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={205}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={206}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={207}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={208}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Down Payment Payments">
            <Form.Item name="down_payment_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={209}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={210}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={211}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={212}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Advance Against Contracts'>

          <Card size="small" type="inner" title="Advance Against Contracts">
            <Form.Item name="advance_against_contracts" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={213}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={214}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={215}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={216}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={217}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={218}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={219}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={220}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Against Contracts Invoices">
            <Form.Item name="advance_against_contracts_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={221}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={222}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={223}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={224}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={225}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={226}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={226}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={228}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Against Contracts payments">
            <Form.Item name="advance_against_contracts_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={229}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={230}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={231}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={232}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Advance Receivables'>

          <Card size="small" type="inner" title="Advance Receivables">
            <Form.Item name="advance_receivables" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={233}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={234}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={235}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={236}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={237}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={238}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={239}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={240}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Receivables Invoices">
            <Form.Item name="advance_receovables_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={241}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={242}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={243}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={244}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={245}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={246}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={247}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={248}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Receivables payments">
            <Form.Item name="advance_receivables_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={249}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={250}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={251}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={252}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
      </Card>
    </Form>
  )
}