import { ColaIndexTypeEnum } from '@exportx/shared-models-and-services';
import { Card } from 'antd';
import { useState } from 'react';
import CoalIndexPricesForm from '../coal-index-prices-form';
import CoalIndexPricesGrid from '../coal-index-prices-grid';
interface ICoalIndexPageProps {
  coalIndexType: ColaIndexTypeEnum
}
export const CoalIndexPricesPage = (props: ICoalIndexPageProps) => {
  const [refreshKey, setRefreshKey] = useState(1);
  const { coalIndexType } = props;
  const saveCallBack = () => {
    setRefreshKey(key => key + 1)
  }
  return (
    <Card>
      {coalIndexType === ColaIndexTypeEnum.WEEKLY ?
        <CoalIndexPricesForm coalIndexType={coalIndexType} saveCallBack={saveCallBack} /> : <></>
      }
      <CoalIndexPricesGrid coalIndexType={coalIndexType} key={refreshKey} />
    </Card>
  )
}

export default CoalIndexPricesPage;