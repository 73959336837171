import { CountryDropDownDto, CountryService, DestinationService, JettyCreateDto, JettyService, JettyUpdateDto } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../../common';


const { Option } = Select;
const JettyForm = () => {
  const pathToreDirect = '/jettyGrid'

  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();
  const [, setSelectedCountry] = useState<string>(null);
  const { state }: any = useLocation();
  const { isUpdate, jettyData, hidden } = state ? state : { isUpdate: false, jettyData: null, hidden: null };
  const service = new JettyService();
  const destinationService = new DestinationService()
  const [destination, setDestination] = useState<any[]>()
  const [countryData, setCountryData] = useState<CountryDropDownDto[]>([]);
  const countryService = new CountryService();

  useEffect(() => {
    destinationDropDown();
    getAllActiveCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }
  const destinationDropDown = () => {
    destinationService.getDestinationDropDown().then(res => {
      if (res.status) {
        setDestination(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }
  const getAllActiveCountries = () => {
    countryService.getAllCountryDropDownDto().then(res => {
      if (res.status) {
        setCountryData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const clearData = () => {
    formRef.resetFields();
  }
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }

  const handleCountry = (value) => {
    setSelectedCountry(value);
  }

  const submitForm = (values: any) => {

    if (isUpdate && jettyData?.jettyId) {
      update(values);
      return;
    }

    const createDto: JettyCreateDto = new JettyCreateDto(values.jettyName, authContext.user.userName, values.port, values.country, values.area,);
    service.create(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(`Jetty Created SuccessFully`);
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      }
      else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  console.log(jettyData, "update")
  const update = (values: any) => {
    console.log(values, "value")
    const updateDto: JettyUpdateDto = new JettyUpdateDto(values.jettyId, values.jettyName, values.port, values.country, values.area, authContext.user.userName, jettyData.versionFlag);
    console.log(updateDto, "dto")
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(`Jetty Updated SuccessFully`);
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      }
      else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  return (
    <div>
      <Card title={<span>Jetty Details</span>}
        className='default-card-class'
        extra={<span ><Button onClick={() => navigate('/jettyGrid')}>View</Button></span>}>
        <Form layout="vertical" form={formRef} initialValues={jettyData} autoComplete="off">
          <Row>
            <Form.Item name="jettyId" hidden><Input hidden placeholder="Enter Jetty Name" type="text" style={{ width: "70%" }} />
            </Form.Item>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Jetty Name" rules={[{ required: true, },]}
                name="jettyName"><Input placeholder="Enter Jetty Name" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Area" rules={[{ required: true, },]}
                name="area"><Input placeholder="Enter Area" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item
                name="country"
                label="Country Name"
                rules={[
                  {
                    required: true,
                    message: 'Country Name is Required'
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: `Enter the Country Name`
                  }
                ]}
              >
                <Select
                  showSearch
                  //style={{ width: 200 }}
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  onChange={handleCountry}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  style={{ width: "80%" }}
                >
                  {countryData.map((country) => {
                    return <Option key={country.countryId} value={country.countryId}>{country.countryName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
              <Form.Item label="Province" rules={[{ required: true, },]}
                name="port">
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={"Please Select Port"} style={{ width: "80%" }} >
                  {destination?.map((rec) => {
                    return <Option value={rec.destinationId} key={rec.destinationId}>{rec.destinationName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>



            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button htmlType="button" style={{ display: hidden ? 'none' : 'block', margin: '0 14px' }} onClick={() => { clearData(); }} >
                Clear
              </Button>
              <Button type="primary" onClick={submitFormHandler} style={{ display: hidden ? 'none' : 'block' }} >
                Submit
              </Button>
            </Col>

          </Row>
        </Form>
      </Card>
    </div>
  )

}
export default JettyForm






