import { DeleteFilled, PlusCircleOutlined, DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { BNoRequest, BusinessNumberService, BusinessPartnerCategoryReqDto, BusinessPartnerTypeEnum, ContractStatusEnum, MvDeadFreightQtyApprove, ResponsePersonEnum } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Descriptions, Form, FormInstance, Input, InputNumber, Row, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, StatusTag, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';

interface IVesselDataProps {

  businessNo?: string;
  closeDrawer?: () => void;
  formRef?: FormInstance<any>;
  hatchData?: any;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>
}

const { Option } = Select
export const VesselData = (props: IVesselDataProps) => {
  const { businessNo, closeDrawer, setDummyRefresh } = props
  const { formatMessage: fm } = useIntl();
  const businessNoService = new BusinessNumberService();
  const [motherVesselData, setMotherVesselData] = useState<any>({ deadFreightTransaction: [''], vesselFinalQtyData: [''] });
  const [qualityDropDown, setQualityDropDown] = useState([]);
  const [finalQty, setFinalQty] = useState<any>(0)
  const service = new BusinessNumberService();
  const [formRef] = Form.useForm();
  const [dfFormRef] = Form.useForm();
  const { authContext } = useAuthState();
  const { Item } = Descriptions;
  const [motherVesselForm, setMotherVesselForm] = useState<boolean>(false)
  const [hatchQuantity, setHatchQuantity] = useState<any>()
  const bnAllocationService = new BusinessNumberService()
  const [, setBuyerTotalQuantity] = useState<number>();
  const [, setSupplierTotalQuantity] = useState<number>();
  const [requiredState, setRequiredState] = useState<boolean>(false)
  const [vesselOwnerDropDown, setVesselOwnerDropDown] = useState<any>([])
  const [businessPartnerDropDown, setBusinessPartnerDropDown] = useState<any>([])
  const [expandedIndex, setExpandedIndex] = useState([]);

  useEffect(() => {
    if (motherVesselData === undefined) {
      getAllShippingDetailsData();
    }
    motherVesselDataForUpdate(false);

    getBnVesselLinkingDetails();
    getBnaPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessNo]);


  const hatchQtyTotal = motherVesselData?.vesselFinalQtyData.reduce((a, c) => a + Number(c.hatchQty), 0);
  const QtyTotal = motherVesselData?.vesselFinalQtyData.reduce((a, c) => a + Number(c.finalQty), 0);

  useEffect(() => {
    if (motherVesselData) {
      formRef.setFieldsValue(motherVesselData);
      let sum = 0;
      motherVesselData?.vesselFinalQtyData?.forEach(element => {
        sum += element?.finalQty ? Number(element.finalQty) : 0;
      });
      setFinalQty(sum);
      const empty = [];
      motherVesselData.vesselFinalQtyData.forEach((rec) => {
        empty?.push(rec?.hatchQty?.split('&#@'))
      })
    };
    deadFreightAccountHandler(motherVesselData?.deadFreightAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motherVesselData]);

  useEffect(() => {
    if (motherVesselData?.deadFreightTransaction) {
      formRef.setFieldsValue({ deadFreightTransaction: motherVesselData?.deadFreightTransaction })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motherVesselData?.deadFreightTransaction])

  useEffect(() => {
    if (hatchQuantity) {
      formRef.setFieldsValue({ vesselFinalQtyData: hatchQuantity })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hatchQuantity]);

  const getBnaPartners = () => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant);
    businessNoService.businessNoAllocatedPartners(req).then(res => {
      if (res.status) {
        setBusinessPartnerDropDown(res.data)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getBnVesselLinkingDetails = () => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant);
    businessNoService.getBnVesselLinkingDetails(req).then(res => {
      if (res.status) {
        dfFormRef.setFieldValue('dfVesselId', res?.data?.vesselVednor)
        dfFormRef.setFieldValue('dfVesselPrice', res?.data?.price)
        if (res.data) {
          setVesselOwnerDropDown([{ id: res.data.vesselVednor, value: res.data.vesselVendorName }])
        }
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getAllShippingDetailsData = () => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant);
    businessNoService.getAllShippingDetailsData(req).then(res => {
      if (res.status) {
        setHatchQuantity(res?.data?.stowagePlanData)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  useEffect(() => {
    scqDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const motherVesselDataForUpdate = (makeRefresh?: boolean) => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant)
    service.motherVesselDataForUpdate(req).then(res => {
      if (res.status) {
        let motherVesselDataLocal: any = { ...res.data };
        motherVesselDataLocal.estimatedETA = res.data?.estimatedETA ? moment(res.data?.estimatedETA) : undefined;
        motherVesselDataLocal.documentOnLoad = res.data?.documentOnLoad ? moment(res.data?.documentOnLoad) : undefined;
        motherVesselDataLocal.norAcceptance = res.data?.norAcceptance ? moment(res.data?.norAcceptance) : undefined;
        motherVesselDataLocal.vesselArrived = res.data?.vesselArrived ? moment(res.data?.vesselArrived) : undefined;
        motherVesselDataLocal.vesselSailing = res.data?.vesselSailing ? moment(res.data?.vesselSailing) : undefined;
        motherVesselDataLocal.loadingCompletion = res.data?.loadingCompletion ? moment(res.data?.loadingCompletion) : undefined;
        motherVesselDataLocal.loadingCommenCement = res.data?.loadingCommenCement ? moment(res.data?.loadingCommenCement) : undefined;

        setMotherVesselData(motherVesselDataLocal);
        if (makeRefresh) {
          setDummyRefresh(prev => prev + 1);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const clearMotherVesselData = () => {
    formRef.resetFields();
    setMotherVesselData(undefined);
  }

  const onClear = () => {
    formRef.resetFields();
    setMotherVesselData(undefined);
  }

  const veselData = () => {
    formRef.validateFields().then(values => {
      dfFormRef.validateFields().then(dfValues => {
        if (values) {
          const responseData = {
            ...values, businessNo, motherVesselId: motherVesselData?.motherVesselId, finalQty: finalQty, userName: authContext.user.userName, plantCode: authContext.defaultPlant,
            mvDeadFreightQty: {
              ...dfValues,
              stowage_qty: hatchQtyTotal,
              actual_loaded_qty: QtyTotal,
              dead_freight_qty: hatchQtyTotal - QtyTotal,
              businessNo: businessNo,
              userName: authContext.user.userName,
              plantCode: authContext.defaultPlant,
            }
          }
          // if (values.deadFreightIncurred !== totalQuantity) {
          //   console.log(values.deadFreightIncurred, totalQuantity);
          //   AlertMessages.getErrorMessage('Quantity Value Is Not Greater Than To Dead Freight Incurred')
          //   return
          // }
          // return
          businessNoService.upDateVeselData(responseData).then(res => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              motherVesselDataForUpdate(true);
              closeDrawer();
              onClear();
              setMotherVesselForm(false);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          }).catch(err => {
            console.log(err.message)
          })
        }
      })
    })
  }

  // const handleTotal = (_, values: any) => {
  //   if (values?.vesselFinalQtyData) {
  //     const transactions = [...values.vesselFinalQtyData];
  //     let sum = 0;
  //     transactions.forEach(element => {
  //       sum += element?.finalQty ? Number(element.finalQty) : 0;
  //     });
  //     formRef.setFieldValue('vesselFinalQty', sum);
  //     setFinalQty(sum);
  //   }
  //   if (formRef.getFieldValue('deadFreightAccount') === ResponsePersonEnum.SUPPLIER) {
  //     const tr = [...values.deadFreightSupplierTransaction];
  //     let sum = 0;
  //     tr.forEach(element => {
  //       sum += element?.quantity ? Number(element.quantity) : 0;
  //     });
  //     setSupplierTotalQuantity(sum);
  //   }

  //   const tr = [...values.deadFreightSupplierTransaction];
  //   let sum = 0;
  //   tr.forEach(element => {
  //     sum += element?.quantity ? Number(element.quantity) : 0;
  //   });
  //   setBuyerTotalQuantity(sum);

  // }

  const approveVesselDeadFreightAgreement = (status: ContractStatusEnum, column: string) => {
    const req = new MvDeadFreightQtyApprove(businessNo, column, authContext.defaultPlant, status, authContext.user.userName);
    businessNoService.mvDeadFreightQtyApprove(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        motherVesselDataForUpdate(true);
        setMotherVesselForm(false);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message)
    })

  }

  const handleTotal = (_, values) => {
    if (values?.vesselFinalQtyData) {
      const transactions = [...values.vesselFinalQtyData];
      let sum = 0;
      transactions.forEach(element => {
        sum += element?.finalQty ? Number(element.finalQty) : 0;
      });
      formRef.setFieldValue('vesselFinalQty', sum);
      setFinalQty(sum);
    }

    if (formRef.getFieldValue('deadFreightAccount') === ResponsePersonEnum.SUPPLIER) {
      if (Array.isArray(values.deadFreightSupplierTransaction)) {
        const tr = [...values.deadFreightSupplierTransaction];
        let sum = 0;
        tr.forEach(element => {
          sum += element?.quantity ? Number(element.quantity) : 0;
        });
        setSupplierTotalQuantity(sum);
      }
    }

    if (Array.isArray(values.deadFreightSupplierTransaction)) {
      const tr = [...values.deadFreightSupplierTransaction];
      let sum = 0;
      tr.forEach(element => {
        sum += element?.quantity ? Number(element.quantity) : 0;
      });
      setBuyerTotalQuantity(sum);
    }
  };


  const scqDropDown = () => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant)
    businessNoService.scqQualityDropDown(req).then((res) => {
      if (res.status) {
        setQualityDropDown(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const motherFinalQtyColumns: ColumnsType<any> = [
    {
      title: 'Hatch',
      dataIndex: 'hatchNames',
      // align: 'center',
    },
    {
      title: 'Hatch Quantity',
      dataIndex: 'hatchQty',
      // align: 'center',
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
    },
    {
      title: 'Vessel Final Qty',
      dataIndex: 'finalQty',
    },
  ]
  const deadFreightColumns: ColumnsType<any> = [
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      // align: 'center',
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      // align: 'center',
    },
    {
      title: 'Price Pmt',
      dataIndex: 'price',
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: (value, record) => {
        return <StatusTag status={value} />
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      render: (value, rowData) => (
        <span>
          {value === ContractStatusEnum.DRAFT && <Button type="primary" onClick={() => approveVesselDeadFreightAgreement(ContractStatusEnum.PENDING_APPROVAL, rowData.column)}>Send For Approval</Button>}
          {value === ContractStatusEnum.PENDING_APPROVAL && <Button type="primary" onClick={() => approveVesselDeadFreightAgreement(ContractStatusEnum.ACTIVE, rowData.column)}>Approve</Button>}
        </span>)
    },
  ]

  // const deadFreightAccountHandler = (value) => {
  //   let type = null
  //   if (value === ResponsePersonEnum.BUYER) {
  //     type = BusinessPartnerTypeEnum.CUSTOMER
  //   }
  //   if (value === ResponsePersonEnum.SUPPLIER) {
  //     type = BusinessPartnerTypeEnum.VENDOR
  //   }
  //   if (value === ResponsePersonEnum.SELF) {
  //     type = null
  //   }
  //   if (!type) {
  //     return false;
  //   }
  //   const req = new BusinessPartnerCategoryReqDto(businessNo, type, authContext.defaultPlant)
  //   bnAllocationService.allocatedBpNames(req).then(res => {
  //     if (res.status) {
  //       if (value === ResponsePersonEnum.SUPPLIER) {
  //         formRef.setFieldsValue({ deadFreightSupplierTransaction: res.data })
  //         formRef.setFieldsValue({deadFreightBuyerTransaction: null})
  //       } else {
  //         formRef.setFieldsValue({deadFreightSupplierTransaction: null})
  //         formRef.setFieldsValue({ deadFreightBuyerTransaction: res.data })
  //       }
  //     } else {
  //       console.log(res.internalMessage);
  //     }
  //   }).catch(err => console.log(err.message));
  // }
  const deadFreightAccountHandler = (value) => {
    let type = null;

    if (value === ResponsePersonEnum.BUYER) {
      type = BusinessPartnerTypeEnum.CUSTOMER;
    } else if (value === ResponsePersonEnum.SUPPLIER) {
      type = BusinessPartnerTypeEnum.VENDOR;
    }

    // Reset form fields if value is SELF
    if (value === ResponsePersonEnum.SELF) {
      formRef.setFieldsValue({
        deadFreightSupplierTransaction: null,
        deadFreightBuyerTransaction: null,
      });
      return; // Exit the function without making the API call
    }

    if (!type) {
      return false;
    }

    const req = new BusinessPartnerCategoryReqDto(businessNo, type, authContext.defaultPlant);
    bnAllocationService.allocatedBpNames(req).then(res => {
      if (res.status) {
        if (value === ResponsePersonEnum.SUPPLIER) {
          formRef.setFieldsValue({ deadFreightSupplierTransaction: res.data });
          formRef.setFieldsValue({ deadFreightBuyerTransaction: null });
        } else {
          formRef.setFieldsValue({ deadFreightSupplierTransaction: null });
          formRef.setFieldsValue({ deadFreightBuyerTransaction: res.data });
        }
      } else {
        console.log(res.internalMessage);
      }
    })
      .catch(err => console.log(err.message));
  }



  const requiredQuantity = (value) => {
    if (value)
      setRequiredState(true)
    if (!value) {
      setRequiredState(false)
    }
  }

  const deadFreightIncurredOnChangeHandler = (deadFreightIncurred: number) => {
    if (deadFreightIncurred) {
      deadFreightAccountHandler(ResponsePersonEnum.BUYER)
    }
    //deadFreightBuyerTransaction
  }

  const deadFreightDataHandler = () => {

    if (motherVesselData?.mvDeadFreightQtyData)
      return [
        ...(motherVesselData?.mvDeadFreightQtyData?.dfSupplierName ? [
          {
            id: 1,
            vendor: `${motherVesselData?.mvDeadFreightQtyData?.dfSupplierName} (Supplier)`,
            qty: motherVesselData?.mvDeadFreightQtyData?.dfSupplierQty,
            price: motherVesselData?.mvDeadFreightQtyData?.dfSupplierPrice,
            status: motherVesselData?.mvDeadFreightQtyData?.dfSupplierStatus,
            type: 'Supplier',
            column: 'dfSupplierStatus'
          }
        ] : []),
        ...(motherVesselData?.mvDeadFreightQtyData?.dfBuyerName ? [
          {
            id: 2,
            vendor: `${motherVesselData?.mvDeadFreightQtyData?.dfBuyerName} (Buyer)`,
            qty: motherVesselData?.mvDeadFreightQtyData?.dfBuyerQty,
            price: motherVesselData?.mvDeadFreightQtyData?.dfBuyerPrice,
            status: motherVesselData?.mvDeadFreightQtyData?.dfBuyerStatus,
            type: 'Buyer',
            column: 'dfBuyerStatus'

          },
        ] : []),
        ...(motherVesselData?.mvDeadFreightQtyData?.dfVesselName ? [

          {
            id: 3,
            vendor: `${motherVesselData?.mvDeadFreightQtyData?.dfVesselName} (Vessel Owner)`,
            qty: motherVesselData?.mvDeadFreightQtyData?.dfVesselQty,
            price: motherVesselData?.mvDeadFreightQtyData?.dfVesselPrice,
            status: motherVesselData?.mvDeadFreightQtyData?.dfVesselStatus,
            type: 'Vessel',
            column: 'dfVesselStatus'

          }
        ] : []),

      ]

    return []
  }
  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.id);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const renderItems = (record: any, index, indent, expanded) => {
    return <>

      {motherVesselData?.mvDeadFreightQtyData?.workLog?.filter(e => e.actionType === record.column)?.map((rec, index) =>
        <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" bordered>
          <Descriptions.Item label='Approved By'>{rec.createdUser}</Descriptions.Item>
          <Descriptions.Item label='Approved on'>{rec.createdAt ? moment(rec.createdAt).format('DD-MMM-YYYY h:mm A') : ''}</Descriptions.Item>
        </Descriptions>)}

    </>
  };

  return (
    <>
      {!motherVesselForm ?

        <>
          <Descriptions>
            <Item label={'Vessel Arrived'}>{motherVesselData?.vesselArrived ? moment(motherVesselData?.vesselArrived).format('DD-MMM-YYYY HH:mm:ss') : undefined}</Item>
            <Item label={'Nor Acceptance'}>{motherVesselData?.norAcceptance ? moment(motherVesselData?.norAcceptance).format('DD-MMM-YYYY HH:mm:ss') : undefined}</Item>
            <Item label={'Loading Commen Cement'}>{motherVesselData?.norAcceptance ? moment(motherVesselData?.loadingCommenCement).format('DD-MMM-YYYY HH:mm:ss') : undefined}</Item>
            <Item label={'Loading Completion'}>{motherVesselData?.loadingCompletion ? moment(motherVesselData?.loadingCompletion).format('DD-MMM-YYYY HH:mm:ss') : undefined}</Item>
            <Item label={'Vessel Sailing'}>{motherVesselData?.vesselSailing ? moment(motherVesselData?.vesselSailing).format('DD-MMM-YYYY HH:mm:ss') : undefined}</Item>
            {/* <Item label={'Stevedore Additional Day'}>{motherVesselData?.stevedoreAdditionalDay ? motherVesselData?.stevedoreAdditionalDay : undefined}</Item> */}
            <Item label={'Dead Freight Incurred'}>{motherVesselData?.deadFreightIncurred ? motherVesselData?.deadFreightIncurred : undefined}</Item>
            <Item label={'Dead Freight Applicability'}>{motherVesselData?.deadFreightAccount ? motherVesselData?.deadFreightAccount : undefined}</Item>

          </Descriptions>
          <Table
            columns={motherFinalQtyColumns}
            dataSource={motherVesselData?.vesselFinalQtyData}
            pagination={false}
            summary={() => {
              return <>
                <Table.Summary.Row className='tableFooter'>

                  <Table.Summary.Cell index={1} colSpan={1}><b>Total</b></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={1}><b> {hatchQtyTotal ? hatchQtyTotal : undefined}</b></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={1}>{ }</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={1}><b> {QtyTotal ? QtyTotal : undefined}</b></Table.Summary.Cell>

                </Table.Summary.Row>

              </>
            }}
          ></Table>
          <br />

          <Card title='Dead Freight'>
            <Descriptions
              title=""
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label='Stowage Qty'>
                {hatchQtyTotal}
              </Descriptions.Item>
              <Descriptions.Item label='Actual loaded Qty'>
                {QtyTotal}
              </Descriptions.Item>
              <Descriptions.Item label='Dead Freight'>
                {hatchQtyTotal - QtyTotal}
              </Descriptions.Item>
            </Descriptions>

            <Table columns={deadFreightColumns} dataSource={deadFreightDataHandler()} pagination={false} size='small'
              expandable={{
                expandedRowRender: renderItems,
                expandedRowKeys: expandedIndex,
                onExpand: setIndex,
                fixed: 'right'
              }}
              rowKey={record => record?.id}
              expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpCircleFilled
                    onClick={(e) => onExpand(record, e)}
                  >
                    Collapse
                  </UpCircleFilled>
                ) : (
                  <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                )
              } />

          </Card>

        </>

        : <></>}


      <Button style={{ float: "right", display: `${motherVesselForm ? "none" : ''}` }} type='primary' onClick={() => { setMotherVesselForm(true); motherVesselDataForUpdate(); }}>EDIT</Button>
      <br></br>
      <br></br>
      {motherVesselForm ? <Card title="Vessel Data Details">
        <Form
          form={formRef}
          initialValues={motherVesselData}
          layout={'vertical'}
          autoComplete="off"
          onValuesChange={handleTotal}
        >
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.vesseldataForm.vesselArrived', defaultMessage: "Vessel Arrived" })}
                name="vesselArrived"

              >
                <DatePicker style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.vesseldataForm.norAcceptance', defaultMessage: "NOR Acceptance" })}
                name="norAcceptance"
              >
                <DatePicker style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            </Col>



            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.vesseldataForm.loadingcommencement', defaultMessage: "Loading Commencement" })}
                name="loadingCommenCement"
              >
                <DatePicker style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item
                label={fm({ id: 'mdm.components.vesseldataForm.loadingcompletion', defaultMessage: " Loading Completion" })}
                name="loadingCompletion">
                <DatePicker style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item
                label={fm({ id: 'mdm.components.vesseldataForm.vesselsailing', defaultMessage: "Vessel Sailing" })}
                name="vesselSailing"
              >

                <DatePicker onChange={requiredQuantity} style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />
              </Form.Item>
            </Col>

            {/* <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                <Form.Item label={fm({ id: 'mdm.components.vesseldataForm.stevedoreAdditionalDay', defaultMessage: "Stevedore AdditionalDay" })}
                  name="stevedoreAdditionalDay" >
                  <InputNumber placeholder='Stevedore AdditionalDay' style={{ width: "100%" }} />
                </Form.Item>
              </Col> */}
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item
                label={'Dead Freight Incurred'}
                name="deadFreightIncurred">
                <InputNumber placeholder='Dead Freight Incurred' style={{ width: "100%" }} onChange={(deadFreightIncurred: number) => {
                  console.log(deadFreightIncurred)
                  deadFreightIncurredOnChangeHandler(deadFreightIncurred)
                }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
              <Form.Item label={'Dead Freight Applicability'}
                name="deadFreightAccount" >
                <Select
                  onChange={deadFreightAccountHandler}
                  placeholder={'Dead Freight Applicability'}
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear
                  showSearch
                  style={{ width: "100%" }} >
                  <Option value={''}>Please Select</Option>
                  {Object.keys(ResponsePersonEnum).filter(rec => ResponsePersonEnum[rec] !== ResponsePersonEnum.BUYER).map(vessel => {
                    return <Option value={ResponsePersonEnum[vessel]}>{ResponsePersonEnum[vessel]}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.List name={'deadFreightBuyerTransaction'} >
            {(fields, { remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <>
                    <Row>
                      <Col
                        xs={{ span: 22 }}
                        sm={{ span: 22 }}
                        md={{ span: 22 }}
                        lg={{ span: 22 }}
                        xl={{ span: 22 }}>

                        <Row>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item name={[name, "bpName"]} label={'Buyer'}>
                              <Input disabled={true} />
                            </Form.Item >

                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, "quantity"]} label={'Quantity In Mt'} >
                              <InputNumber placeholder='Quantity' style={{ width: '100%' }} />
                            </Form.Item >
                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, 'bpId']} hidden={true} >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, "deadFreightTransactionId"]} hidden={true} >
                              <Input />
                            </Form.Item >
                          </Col>
                        </Row>
                      </Col>
                      {index !== 0 && <Col xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 2 }}
                        xl={{ span: 2 }}>
                        <span style={{
                          position: "absolute",
                          marginTop: "33px",
                          marginLeft: "25px",
                        }}>
                          <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right', display: 'none' }} onClick={() => {
                              remove(name);
                            }} />
                          </Tooltip>
                        </span>
                      </Col>
                      }
                    </Row>
                  </>
                ))}
              </>
            )
            }
          </Form.List>

          <Form.List name={'deadFreightSupplierTransaction'} >
            {(fields, { remove }) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <>
                    <Row>
                      <Col
                        xs={{ span: 22 }}
                        sm={{ span: 22 }}
                        md={{ span: 22 }}
                        lg={{ span: 22 }}
                        xl={{ span: 22 }}>

                        <Row>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item name={[name, "bpName"]} label={'Supplier'}>
                              <Input disabled={true} />
                            </Form.Item >

                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, "quantity"]} label={'Quantity In Mt'} >
                              <InputNumber placeholder='Quantity' style={{ width: '100%' }} />
                            </Form.Item >
                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, 'bpId']} hidden={true} >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item name={[name, "deadFreightTransactionId"]} hidden={true} >
                              <Input />
                            </Form.Item >
                          </Col>
                        </Row>
                      </Col>
                      {index !== 0 && <Col xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 2 }}
                        xl={{ span: 2 }}>
                        <span style={{
                          position: "absolute",
                          marginTop: "33px",
                          marginLeft: "25px",
                        }}>
                          <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right', display: 'none' }} onClick={() => {
                              remove(name);
                            }} />
                          </Tooltip>
                        </span>
                      </Col>
                      }
                    </Row>
                  </>
                ))}
              </>
            )
            }
          </Form.List>



          <Form.List name={'vesselFinalQtyData'} >
            {(fields, { add, remove }) => (
              <Card title={
                <Row justify='space-between'>
                  <Col><>Vessel Final QTy -  {finalQty} </></Col>
                  <Col>
                    <span >
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined
                          type="dashed"
                          onClick={() => add()}
                          style={{ fontSize: 20, display: 'none' }}
                        >
                          Add field
                        </PlusCircleOutlined>
                      </Tooltip>
                    </span>
                  </Col>
                </Row>}>
                {fields.map(({ key, name }, index) => (
                  <>
                    <Row>
                      <Col
                        xs={{ span: 22 }}
                        sm={{ span: 22 }}
                        md={{ span: 22 }}
                        lg={{ span: 22 }}
                        xl={{ span: 22 }}>

                        <Row>
                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item
                              label={`Hatch ${index + 1}`}
                              name={[name, "hatchQty"]}
                            >
                              <Input disabled={true} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item
                              label='Quality'
                              name={[name, "qualityId"]} rules={[{ required: requiredState }]}>
                              <Select
                                placeholder={"Select Quantity"}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {qualityDropDown?.map((el) => {
                                  return <Option value={el.scqId}>{el.quality}</Option>
                                })
                                }
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item name={[name, "finalQty"]} label={'Vessel Final Qty'} >
                              <InputNumber placeholder='Vessel Final Qty' style={{ width: '100%' }} />
                            </Form.Item >
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >

                          </Col>
                        </Row>
                      </Col>
                      {index !== 0 && <Col xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 2 }}
                        xl={{ span: 2 }}>
                        <span style={{
                          position: "absolute",
                          marginTop: "33px",
                          marginLeft: "25px",
                        }}>
                          <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right', display: 'none' }} onClick={() => {
                              remove(name);
                            }} />
                          </Tooltip>
                        </span>
                      </Col>
                      }
                    </Row>
                  </>
                ))}
              </Card>
            )
            }
          </Form.List>
          <br />


          <Form
            form={dfFormRef}
            initialValues={motherVesselData.mvDeadFreightQtyData}
            layout={'vertical'}
            autoComplete="off"
            onValuesChange={handleTotal}
          >


            <Card size='small' title='Dead Freight'>
              <Descriptions
                title=""
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label='Stowage Qty'>
                  {hatchQtyTotal}
                </Descriptions.Item>
                <Descriptions.Item label='Actual loaded Qty'>
                  {QtyTotal}
                </Descriptions.Item>
                <Descriptions.Item label='Dead Freight'>
                  {hatchQtyTotal - QtyTotal}
                </Descriptions.Item>
              </Descriptions>

              <Row>
                <Form.Item name={'mvDfId'} hidden={true} >
                  <Input />
                </Form.Item>
                <Form.Item name={'dfSupplierStatus'} hidden={true} >
                  <Input />
                </Form.Item>
                <Form.Item name={'dfBuyerStatus'} hidden={true} >
                  <Input />
                </Form.Item>
                <Form.Item name={'dfVesselStatus'} hidden={true} >
                  <Input />
                </Form.Item>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 0 }}>
                  <Form.Item
                    label='Supplier'
                    name={"dfSupplierId"} rules={[{ required: requiredState }]}>
                    <Select
                      placeholder={"Select Supplier"}
                      showSearch
                      optionFilterProp="children"
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfSupplierStatus === ContractStatusEnum.ACTIVE}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {businessPartnerDropDown?.map((el) => {
                        return <Option value={el.supplierId} key={el.supplierId}>{el.supplierName}</Option>
                      })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfSupplierQty"} label={'Quantity'}

                  >
                    <InputNumber placeholder=' Enter Quantity' style={{ width: '100%' }}
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfSupplierStatus === ContractStatusEnum.ACTIVE}
                      max={hatchQtyTotal - QtyTotal}
                    />
                  </Form.Item >
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfSupplierPrice"} label={'Price PMT'} >
                    <InputNumber placeholder='Enter Price PMT' style={{ width: '100%' }}
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfSupplierStatus === ContractStatusEnum.ACTIVE}

                    />
                  </Form.Item >
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 0 }}>
                  <Form.Item
                    label='Buyer'
                    name={"dfBuyerId"} rules={[{ required: requiredState }]}>
                    <Select
                      placeholder={"Select Buyer"}
                      showSearch
                      optionFilterProp="children"
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfBuyerStatus === ContractStatusEnum.ACTIVE}

                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {businessPartnerDropDown?.map((el) => {
                        return <Option value={el.buyerId} key={el.buyerId}>{el.buyerName}</Option>
                      })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfBuyerQty"} label={'Quantity'} >
                    <InputNumber placeholder='Enter Quantity' style={{ width: '100%' }}
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfBuyerStatus === ContractStatusEnum.ACTIVE}

                      max={hatchQtyTotal - QtyTotal}
                    />
                  </Form.Item >
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfBuyerPrice"} label={'Price PMT'} >
                    <InputNumber placeholder='Enter Price PMT' style={{ width: '100%' }}
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfBuyerStatus === ContractStatusEnum.ACTIVE}

                    />
                  </Form.Item >
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 0 }}>
                  <Form.Item
                    label='Vessel Owner'
                    name={"dfVesselId"} rules={[{ required: requiredState }]}>
                    <Select
                      placeholder={"Select Vessel Owner"}
                      showSearch
                      disabled={true}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vesselOwnerDropDown?.map((el) => {
                        return <Option value={el.id}>{el.value}</Option>
                      })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfVesselQty"} label={'Quantity'}
                  >
                    <InputNumber placeholder='Enter Quantity' style={{ width: '100%' }}
                      disabled={motherVesselData.mvDeadFreightQtyData?.dfVesselStatus === ContractStatusEnum.ACTIVE}
                      max={hatchQtyTotal - QtyTotal}
                    />
                  </Form.Item >
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                  <Form.Item name={"dfVesselPrice"} label={'Price PMT'} >
                    <InputNumber placeholder='Enter Price PMT' style={{ width: '100%' }} disabled />
                  </Form.Item >
                </Col>
              </Row>

            </Card>
          </Form>

          <Affix offsetBottom={0}>
            <Card bodyStyle={{ padding: '6px 12px' }}>
              <Row justify='space-between'>
                <Col><Button onClick={clearMotherVesselData}>CLEAR</Button></Col>
                <Col><Button type='primary' onClick={veselData}>SUBMIT</Button></Col>
              </Row>
            </Card>
          </Affix>

        </Form >

      </Card >
        : <></>}
    </>

  )
}

export default VesselData