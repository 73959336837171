import { CompanyService, CompanyTdsDto, CompanyTdsService, GetCompanyNames, TaxDropDownDto, TaxesService } from '@exportx/shared-models-and-services'
import { Button, Card, Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertMessages, useAuthState } from '../../../../common'

export const CompanyTdsForm = () => {
    const navigate = useNavigate()
    const { Option } = Select;
    const [companiesDropdown, setCompaniesDropdown] = useState<GetCompanyNames[]>([]);
    const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
    const [formRef] = Form.useForm()
    const { state }: any = useLocation()
    const { record, hidden } = state ? state : { record: null, hidden: null }
    const { authContext } = useAuthState()
    const taxesService = new TaxesService();
    const companyService = new CompanyService();
    const companyTdsService = new CompanyTdsService();


    useEffect(() => {
        getAllActiveCompanyNames();
        getAllActiveTaxDropDown();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getAllActiveCompanyNames = () => {
        companyService.getAllActiveCompanyNames().then(res => {
            if (res.status) {
                setCompaniesDropdown(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        })
    }







    const companyTdsCreation = () => {
        formRef.validateFields().then((values: any) => {
            const req = new CompanyTdsDto(values.company, values.taxId, authContext.user.userName, record?.companyTdsId)
            companyTdsService.companyTdsCreation(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setTimeout(() => {
                        navigate('/company-vat')
                    }, 1000)

                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    const getAllActiveTaxDropDown = () => {
        taxesService.getActiveTaxDropDown().then(res => {
            if (res.status) {
                setTaxDropDown(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    return (
        <>

            <Card title={"Company Tds"}
                className='default-card-class' extra={<span><Button onClick={() => { navigate('/company-vat') }}>View</Button></span>}>
                <Form layout='vertical' form={formRef} initialValues={record}>
                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                            <Form.Item label='Company' name={'company'} rules={[{ required: true, message: "Select Company", }]}>
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder='Select Company'>
                                    {companiesDropdown.map((rec) => {
                                        return <Option value={rec.companyCode} key={rec.companyCode}>{rec.companyName}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item name={"taxId"} label={"Tax Name"} style={{ width: '80%' }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder="PLease Select"   >
                                    {taxDropDown.map((rec) => {
                                        return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <br></br>
                    <Button
                        style={{ float: 'right', display: hidden ? 'none' : 'block' }}
                        onClick={() => { companyTdsCreation() }} type='primary'>{record ? 'Update' : "Save"}</Button>
                </Form>

            </Card>


        </>
    )
}

export default CompanyTdsForm