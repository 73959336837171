
import { ContractTypesEnum, PurchaseContractDto } from '@exportx/shared-models-and-services';
import { Card, Col, Descriptions, List, Row, Tooltip } from 'antd';

interface ICommercialDocumentsViewProps {
    commercialDocuments: {
        coo: string;
        bl: string;
        commercialDocuments: {
            documents: string;
        }[];
    }
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
}

export const CommercialDocumentsView = (props: ICommercialDocumentsViewProps) => {
    const { commercialDocuments, contractType, snapShotData } = props;

    const valiDateWithSnapShotData = (data: any[], index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = data?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    if (valueAtGivenKey !== '-') {
                        return <><span>{valueAtGivenKey}</span>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>{`=>`}&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    return (
        <Row>
            {commercialDocuments?.commercialDocuments && <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}>
                <Card title={<span style={{ color: 'black' }}>{'Commercial Documents'}</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                    <Descriptions
                        title=""
                        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                        {/* <Descriptions.Item label="Coo">
                            {commercialDocuments?.coo}
                        </Descriptions.Item>
                        <Descriptions.Item label="BL">
                            {commercialDocuments?.bl}
                        </Descriptions.Item> */}
                        <Descriptions.Item label="">

                        </Descriptions.Item>
                    </Descriptions>

                    <List bordered>

                        <List
                            dataSource={[{ documents: `Certificate of Origin to be issued  by ${commercialDocuments?.coo}` }, { documents: ` Bill of Lading to be issued by ${commercialDocuments?.bl}` }, ...commercialDocuments?.commercialDocuments]}
                            renderItem={(item, index) => (
                                <List.Item key={index} >
                                    <div><span>{index + 1}</span><span>.</span>&nbsp;{valiDateWithSnapShotData([{ documents: `Certificate of Origin to be issued  by ${snapShotData[0]?.commercialDocuments?.coo}` }, { documents: ` Bill of Lading to be issued by ${snapShotData[0]?.commercialDocuments?.bl}` }, ...snapShotData[0]?.commercialDocuments?.commercialDocuments ? snapShotData[0]?.commercialDocuments?.commercialDocuments : []], index, 'documents', item.documents)}</div>
                                </List.Item>
                            )}
                        />
                    </List>

                </Card>
            </Col>}
        </Row>
    )
}

export default CommercialDocumentsView;