import { BusinessAllocationDropdownDto, BusinessNumberService, ContractIdRequest, ContractModesEnum, ContractTypeAndBnReqDto, ContractTypeAndQualityIdReqDto, PcPurchaseTypeReq, PlantCodeReqDto, PurchaseContractService, PurchaseSupplierReportDto } from '@exportx/shared-models-and-services';
import { Button, Col, Form, FormInstance, Row, Select, Input } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../common';
import { IPropsPurchaseSampling } from '../purchasequality';

const { Option } = Select;
export type IPropsQUality = IPropsPurchaseSampling & {
    formRef: FormInstance<any>,
    filterSubmitHandler: () => void;
    initialValues: PurchaseSupplierReportDto;
}
export const QualityResultsGlobalFilter = (props: IPropsQUality) => {
    const { authContext } = useAuthState();
    const { formRef, contractType, filterSubmitHandler, initialValues } = props;
    const [businessNumbers, setBusinessNumbers] = useState<BusinessAllocationDropdownDto[]>([]);
    const [purchaseContracts, setPurchaseContracts] = useState<any>()
    const [qualitiesData, setQualitiesData] = useState<any>()
    const [purchaseTypesData, setPurchaseTypesData] = useState<any>()
    const businessNumberService = new BusinessNumberService();
    const purchase = new PurchaseContractService();




    useEffect(() => {
        bargeMappedBusinessNumber();
        allocatedContractNumbers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (initialValues) {
            allocatedContractNumbers(initialValues?.businessNumber);
            allocatedPcqQualities(initialValues?.contractId);
            allocatedTypes(initialValues?.qualityId);
            if (initialValues.allocatedId) {
                formRef.setFieldValue('allocatedId', initialValues.allocatedId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    const bargeMappedBusinessNumber = () => {
        const req = new PlantCodeReqDto(authContext.defaultPlant)
        businessNumberService.bargeMappingBusinessNumber(req).then(res => {
            if (res.status) {
                setBusinessNumbers(res.data);
            } else {
                console.log(res.internalMessage);
            };
        }).catch(err => console.log(err.message));
    }

    const allocatedContractNumbers = (value?: string) => {
        const req = new ContractTypeAndBnReqDto(contractType, authContext.defaultPlant, value);
        businessNumberService.allocatedContractNumbers(req).then(res => {
            if (res.status) {
                setPurchaseContracts(res.data);
                if (value) {
                    const Id = businessNumbers?.filter((rec) => rec.businessNoId === value)[0].allocatedId;
                    formRef.setFieldValue('allocatedId', Id)
                } else {
                    formRef.setFieldValue('allocatedId', undefined)
                }
            } else {
                console.log(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }

    const getQualitiesData = (contractId: string) => {
        const req = new ContractIdRequest(contractId, authContext.defaultPlant)
        purchase.getQualitiesDropDown(req).then(res => {
            if (res.status) {
                setQualitiesData(res.data.pcQualities.map((rec) => {
                    const laycan = rec.layCan.split(',');
                    return { qualityId: rec.pcqId, laycan: laycan, quality: rec.pcqQuality }
                }));

            }
        }).catch(err => {
            console.log('error:' + err.message);
        })
    }

    const allocatedPcqQualities = (value: string) => {

        if (contractType === ContractModesEnum.PURCHASE) {
            getQualitiesData(value);
        } else {
            const req = new ContractIdRequest(value, authContext.defaultPlant, contractType)
            businessNumberService.allocatedPcqQualities(req).then(res => {
                if (res.status) {
                    setQualitiesData(res.data);

                } else {
                    console.log(res.internalMessage);
                }
            }).catch(err => console.log(err.message))
        }
    }

    const getPcPurchaseTypesDropDown = (pcQualitiesId: string) => {
        purchase.getPcPurchaseTypesDropDown(new PcPurchaseTypeReq(pcQualitiesId)).then(res => {
            if (res.status) {
                setPurchaseTypesData(res.data.map(rec => { return { typeId: rec.pcTypeId, type: rec.purchaseType } }));
            } else {
                setPurchaseTypesData([]);
            }
        }).catch(err => {
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    }

    const allocatedTypes = (value: string) => {
        if (contractType === ContractModesEnum.PURCHASE) {
            getPcPurchaseTypesDropDown(value);
        } else {
            const req = new ContractTypeAndQualityIdReqDto(value, authContext.defaultPlant, contractType)
            businessNumberService.allocatedTypes(req).then(res => {
                if (res.status) {
                    setPurchaseTypesData(res.data);
                } else {
                    console.log(res.internalMessage);
                }
            }).catch(err => console.log(err.message));
        }
    }



    return (
        <Form form={formRef} layout='vertical' autoComplete='off' initialValues={initialValues}>
            <Row>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                    <Form.Item
                        label="Business Number"
                        name="businessNumber"
                    >
                        <Select
                            onChange={allocatedContractNumbers}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                            <Option value={''}>Please Select</Option>
                            {businessNumbers?.map(item => {
                                return <Option value={item.businessNoId}>{item.businessNo}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        hidden
                        label="Allocation Id"
                        name="allocatedId"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                    <Form.Item
                        label={`${contractType === ContractModesEnum.PURCHASE ? "Purchase" : "Sales"} Contract`}
                        rules={[{ required: true, message: `${contractType === ContractModesEnum.PURCHASE ? "Purchase" : "Sales"} Contract is required` }]}
                        name="contractId"
                    >
                        <Select
                            onChange={allocatedPcqQualities}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                            <Option value={''}>Please Select</Option>
                            {purchaseContracts?.map(item => {
                                return <Option value={item.contractId}>{item.contractNo}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                    <Form.Item
                        label="Laycan (Quality)"
                        name="qualityId"
                        rules={[{ required: true, message: 'Laycan (Quality) is required' }]}
                    >
                        <Select
                            allowClear
                            onChange={allocatedTypes}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                            <Option value={''}>Please Select</Option>
                            {qualitiesData?.map(item => {
                                return <Option value={item.qualityId}>{moment(item.laycan[0]).format('DD MMM YY') + ' - ' + moment(item.laycan[1]).format('DD MMM YY')} - ({`${item.quality}`})</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                    <Form.Item
                        label="Contract Type"
                        name="type"
                        rules={[{ required: true, message: 'Contract Type is required' }]}
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                            <Option value={''}>Please Select</Option>
                            {purchaseTypesData?.map(item => {
                                return <Option value={item?.typeId}>{item?.type}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 1, offset: 1 }}>
                    <Form.Item
                        style={{ marginTop: "24px" }}
                    >
                        <Button type="primary" disabled={false} onClick={filterSubmitHandler}>Submit</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default QualityResultsGlobalFilter