import { ContractTypesEnum, LcTypeEnum, PaymentModesEnum, PaymentTermsDto, PurchaseContractDto } from '@exportx/shared-models-and-services';
import { Card, Col, Descriptions, List, Row, Tooltip } from 'antd';


interface PaymentTermsViewProps {
    paymentTerms: PaymentTermsDto;
    snapShotData?: PurchaseContractDto[];
    contractType?: ContractTypesEnum;
}

export const PaymentTermsView = (props: PaymentTermsViewProps) => {
    const { paymentTerms, snapShotData, contractType } = props;

    const valiDateWithSnapShotData = (data: any[], index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = data?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    if (valueAtGivenKey !== '-') {
                        return <><span>{valueAtGivenKey}</span>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>{`=>`}&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const initialElement = (lcType: string, lcValue: number, lcCurrency: string) => {
        if (lcType === LcTypeEnum.percentage) {
            if (lcValue === 100) {
                return `Letter of Credit(LC) to be issued against 100% of cargo value from Prime Bank`
            } else {
                return `Letter of Credit(LC) to be issued for balance  ${lcValue}% of cargo value from Prime Bank`
            }
        }
        if (lcType === LcTypeEnum.value) {
            return `Letter of Credit LC to be issued for ${lcCurrency} ${lcValue} from prime bank`
        }
    }

    return (
        <Row>
            {(paymentTerms?.paymentMode === PaymentModesEnum.BOTH || paymentTerms?.paymentMode === PaymentModesEnum.TELEGRAPHIC_TRANSFER) && <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}>
                <Card title={<span style={{ color: 'black' }}>{PaymentModesEnum.TELEGRAPHIC_TRANSFER}</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                    <List
                        bordered
                        dataSource={paymentTerms?.telegraphicTransfer}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <div><span>{index + 1}</span><span>.</span>&nbsp;{valiDateWithSnapShotData(snapShotData?.[0]?.paymentTerms?.telegraphicTransfer, index, 'telegraphicTransfer', item.telegraphicTransfer)}</div>
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>}
            {(paymentTerms?.paymentMode === PaymentModesEnum.BOTH || paymentTerms?.paymentMode === PaymentModesEnum.LETTER_OF_CREDIT) && <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}>
                <Card title={<span style={{ color: 'black' }}>{PaymentModesEnum.LETTER_OF_CREDIT}</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                    <Descriptions
                        title=""
                        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                        {/* <Descriptions.Item label="lc Type">
                            {valiDateWithSnapShotData(['paymentTerms', 'lcType'], paymentTerms?.lcType, false)}
                        </Descriptions.Item>
                        <Descriptions.Item label="lc Value">
                            {valiDateWithSnapShotData(['paymentTerms', 'lcValue'], paymentTerms?.lcValue, false)}
                        </Descriptions.Item>
                        <Descriptions.Item label="">

                        </Descriptions.Item> */}
                    </Descriptions>
                    {/* <List
                        bordered={true}
                        dataSource={[{ letterOfCredit: initialElement(paymentTerms?.lcType, paymentTerms.lcValue, paymentTerms.lcCurrency) }, ...paymentTerms?.lc]}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <div><span>{index + 1}</span><span>.</span>&nbsp;{valiDateWithSnapShotData([{ letterOfCredit: initialElement(snapShotData[0]?.paymentTerms.lcType, snapShotData[0]?.paymentTerms.lcValue, snapShotData[0]?.paymentTerms.lcCurrency) }, ...snapShotData[0]?.paymentTerms?.lc ? snapShotData[0]?.paymentTerms?.lc : []], index, 'letterOfCredit', item.letterOfCredit)}</div>
                            </List.Item>
                        )}

                    /> */}
                    <List
                        bordered={true}
                        dataSource={
                            paymentTerms && paymentTerms.lcType
                                ? [
                                    { letterOfCredit: initialElement(paymentTerms.lcType, paymentTerms.lcValue, paymentTerms.lcCurrency) },
                                    ...(paymentTerms.lc ? paymentTerms.lc : [])
                                ]
                                : []
                        }
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <div>
                                    <span>{index + 1}</span>
                                    <span>.</span>&nbsp;
                                    {valiDateWithSnapShotData(
                                        [
                                            {
                                                letterOfCredit: initialElement(
                                                    snapShotData && snapShotData[0]?.paymentTerms?.lcType,
                                                    snapShotData && snapShotData[0]?.paymentTerms?.lcValue,
                                                    snapShotData && snapShotData[0]?.paymentTerms?.lcCurrency
                                                )
                                            },
                                            ...(snapShotData && snapShotData[0]?.paymentTerms?.lc ? snapShotData[0]?.paymentTerms?.lc : [])
                                        ],
                                        index,
                                        'letterOfCredit',
                                        item.letterOfCredit
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />

                </Card>
            </Col>}



            <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}>
                <Card title={<span style={{ color: 'black' }}>Others</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                    <List
                        bordered
                        dataSource={paymentTerms?.others}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <div><span>{index + 1}</span><span>.</span>&nbsp;{valiDateWithSnapShotData(snapShotData?.[0]?.paymentTerms?.others, index, 'others', item.others)}</div>
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>

        </Row>
    )
}

export default PaymentTermsView;