import { BusinessPartnerDto, BusinessPartnerPreviewData, BusinessPartnerService, BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Descriptions, Layout, Row, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm } from '../../../../common';
import queryString from 'query-string';

interface IPropsBusinessPartnerDetailView {
  bpCategory?: BusinessPartnerTypeEnum
}

const BusinessPartnerDetailView = (props: IPropsBusinessPartnerDetailView) => {
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<BusinessPartnerDto>()
  const { formatMessage: fm } = useIntl();
  const { state }: any = useLocation();
  //const { bpId } = state ? state : { bpId: null };
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.bp_id;
  console.log(queryString.parse(location.search));
  let { bpId } = paramBargeId != null ? { bpId: paramBargeId } : state ? state : { bpId: null };
  const service = new BusinessPartnerService()
  const { Item } = Descriptions

  useEffect(() => {
    getPreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bpId]);

  const getPreviewData = () => {
    const bpCatId = new BusinessPartnerPreviewData(bpId);
    service.getBusinessPartnersPreview(bpCatId).then((res: any) => {
      if (res.status) {
        setPreviewData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);

    })
  }

  let upDatePath = '/updateCustomer';
  if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
    upDatePath = '/updateCustomer';
  } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
    upDatePath = '/updateVendor';
  } else {
    upDatePath = '/updateFacilitator';
  }


  const setFormData = () => {
    const bpCatId = new BusinessPartnerPreviewData(bpId);

    service.getAllBusinessPartnerDataToUpdate(bpCatId).then(res => {
      if (res.status) {
        navigate(upDatePath, { state: { businessPartnerDto: res.data, isUpdate: true } });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const kycDetails: ColumnsType<any> = [
    {
      title: 'Account Holder Name',
      dataIndex: 'accountHolderName',


    },

    {
      title: 'Account Number',
      dataIndex: 'accountNumber',


    },
    {
      title: 'Bank Name',
      dataIndex: 'bankName',


    },
    {
      title: 'IBAN Number',
      dataIndex: 'iBanNumber',


    },
    {
      title: 'Swift Code',
      dataIndex: 'swiftCode',


    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',


    },

  ];

  const contractDetail: ColumnsType<any> = [
    {
      title: 'First Name',
      dataIndex: 'firstName',


    },

    {
      title: 'Last Name',
      dataIndex: 'lastName',


    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',


    },
    {
      title: 'LandLineNo',
      dataIndex: 'landLineNo',


    },
    {
      title: 'Email',
      dataIndex: 'email',


    },


  ];


 
  const type = props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer Detail View' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? 'Vendor Detail View' : 'Facilitator Detail View';

  return (
    <>
      <Card title={`${type} - ${previewData?.personalData?.bpNumber}`}>
        <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>

          <Card title="Personal Details" headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}>
            <Descriptions bordered>
              <Item label={'Customer Type'}>{previewData?.personalData?.bpTypeId}</Item>
              <Item label={'Customer Name'}>{previewData?.personalData?.bpName}</Item>
              <Item label={'Business Reg.No'}>{previewData?.personalData?.registrationNo}</Item>
              <Item label={'Directors Name'}>{previewData?.personalData?.directorsName}</Item>
              <Item label={'Tax Id'}>{previewData?.personalData?.taxId}</Item>
              <Item label={'Address Line1'}>{previewData?.personalData?.addressData.addressLine1}</Item>
              <Item label={'Address Line2'}>{previewData?.personalData?.addressData.addressLine2}</Item>
              <Item label={'Country'}>{previewData?.personalData?.addressData?.country}</Item>
              <Item label={'Door No'}>{previewData?.personalData?.addressData?.doorNo}</Item>
              <Item label={'Land Mark'}>{previewData?.personalData?.addressData?.landmark}</Item>
              <Item label={'State'}>{previewData?.personalData?.addressData?.state}</Item>
              <Item label={'Zip Code'}>{previewData?.personalData?.addressData?.zipCode}</Item>
              <Item label={'Business Type'}>{previewData?.personalData?.businessType}</Item>
              <Item label={'Taxable'}>{previewData?.personalData?.taxable}</Item>
              <Item label={'TDS'}>{previewData?.personalData?.tds}</Item>

            </Descriptions>

          </Card>

          <Card
            headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}>

            <Card title="Contact Details">
              <Table dataSource={previewData?.pocFormData ? previewData?.pocFormData : []} columns={contractDetail} pagination={false} bordered />
            </Card><br />
            <Card title="Bank Details">
              <Table dataSource={previewData?.kycFormData ? previewData?.kycFormData : []} columns={kycDetails} pagination={false} bordered />
            </Card>
            <br />
            <Card>
              <FileUploadForm maxCount={3} layoutType={'inline'} formRef={undefined} initialValues={previewData?.filesData ? previewData.filesData : []}>
                <Row justify="end">
                  <Col>
                    {
                      <Space>
                        <Button

                          type="primary">
                          Save And Next
                        </Button>
                      </Space>
                    }</Col>
                </Row>
              </FileUploadForm>
            </Card>
          </Card>

          <Affix offsetBottom={0}>
            <Card bodyStyle={{ padding: '6px 12px' }}>
              <Row justify='space-between'>
                <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                <Col><Button onClick={setFormData}>Edit</Button></Col>
              </Row>
            </Card>
          </Affix>
        </Layout>
      </Card>
    </>
  )
}

export default BusinessPartnerDetailView;