import { DeleteOutlined, EditOutlined, FilePdfTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { BargeCostingsService, BgDemurrageIdReqDto, ContractStatusEnum, GetDataDemurrageDto, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Divider, FormInstance, Input, Popconfirm, Tooltip } from 'antd';
import Table, { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import AddInvoiceDrawer from "../add-invoice-drawer";
import Invoice from "../invoice/invoice";
import { getLocalFormat } from '@exportx/ui-utils';
import { SequenceUtils } from '@exportx/ui-utils';
interface IProps {
    gridData?: GetDataDemurrageDto[];
    getDemurrageData?: () => void;
    setInitialValues?: (value: any) => void;
    demurrageApproval?: boolean;
    formRef?: FormInstance<any>
}


export const DemurrageList = (props: IProps) => {


    const bargeCostingService = new BargeCostingsService();
    const { authContext } = useAuthState()
    const { gridData, getDemurrageData, setInitialValues, demurrageApproval, formRef } = props
    const [drawerOpen, setDrawerOpen] = useState<boolean>()
    const [costingId, setCostingId] = useState<any>()
    const [dummyRefresh, setDummyRefresh] = useState<number>(1);
    const [searchedText, setSearchedText] = useState("");

    const deleteDemurrage = (bgDemurrageId) => {
        const req = new BgDemurrageIdReqDto(bgDemurrageId, authContext.defaultPlant)
        bargeCostingService.deleteDemurrage(req).then(res => {
            if (res.status) {
                getDemurrageData()
                AlertMessages.getSuccessMessage(res.internalMessage);
                window.location.reload();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const tableColumns: ColumnsType<any> = [
        {
            title: 'DemurrageAction',
            width: '100%',
            align: 'center',
            render: (text, rowData) => (
                <span>
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => { formRef.resetFields(); setInitialValues(rowData); }}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                        /></Tooltip>

                    <Divider type="vertical" />
                    <Popconfirm
                        onConfirm={e => {
                            deleteDemurrage(rowData.bgDemurrageId)
                        }}
                        title={'Are you sure to Delete Record ?'}>
                        <Tooltip placement="topRight" title="Delete">
                            <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
                        </Tooltip>
                    </Popconfirm>
                    <Divider type="vertical" />

                    <Tooltip placement="topRight" title="Add Invoice">
                        <PlusSquareTwoTone onClick={() => {
                            setDrawerOpen(true);
                            setCostingId(rowData.bgDemurrageId)
                            setCostingId((prev) => { return { ...prev, costingId: rowData.bgDemurrageId, totalAmount: rowData.totalSumAmount } });

                        }}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                        />
                        <Divider type='vertical'></Divider>
                        <PDFDownloadLink
                            document={<Invoice invoice={''} demurrageName={''} costingName={ReferenceFeatures.MV_DEMURRAGE} />}
                            fileName={`${rowData?.costingNo}.pdf`}
                        >
                            <Tooltip placement="topRight" title="Invoice">
                                <FilePdfTwoTone style={{ color: '#1890ff', fontSize: '20px' }}
                                />
                            </Tooltip>
                        </PDFDownloadLink>
                    </Tooltip>

                </span>
            )

        },
        {
            title: 'Ref',
            dataIndex: 'costingNo',
            // width: '40%', 
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: 'MV Name',
            dataIndex: 'businessNumber',
            // width: '40%', 
            render: (value, record) => {
                const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`
                return <>
                    <a href={href}>{value}</a>
                </>
            }

        },
        {
            title: 'Cost Type',
            dataIndex: 'allowedTimeToLoad',//timeCompletedForLoadMV
            render: (value, record) => {
                let costType: string = ''
                if (Number(value) > Number(record.timeCompletedForLoadMV)) {
                    costType = 'Dispatch'
                } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
                    costType = 'Demurrage'
                } else {
                    costType = ' '
                }
                return <>
                    {costType}
                </>
            }
        },
        {
            title: 'AccountType',
            dataIndex: 'account',
            width: '40%',
        },
        {
            title: 'BusinessPartner',
            dataIndex: 'bpName',
            width: '40%',
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            width: '40%',
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.baseCurrency)}
                </>
            }
        }
    ]

    return (
        <>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right", display: `${demurrageApproval ? "none" : ""}` }}
            />


            <Table
                scroll={{ x: 500 }}
                style={{ display: `${demurrageApproval ? "none" : ""}` }}
                columns={tableColumns}
                dataSource={gridData}
                pagination={false}
                rowClassName={(record, index) => record?.approvalStatus === ContractStatusEnum.CANCELLED ? 'bg-warning' : ''}
            ></Table>
            <AddInvoiceDrawer referenceFeatures={ReferenceFeatures.MV_DEMURRAGE} key={dummyRefresh} setDummyRefresh={setDummyRefresh} openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingId} />

        </>


    )

}
export default DemurrageList

