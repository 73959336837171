import React from "react";
import type { CSSProperties } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { Collapse, theme } from 'antd';
import { MasterRoleSelection, BargeSelection, BusinessPartnerSelection, ContractsSelection, BusinessNoSelection, CostingsSelection, UserSelection, LcTrackerSelection, AccountSelection, CostingDetails, SamplingAnalysisSelection } from "./selection";

export const RoleSelection = ({ mastersRef, businessPartnerRef, contractsRef, businessNoRef, bargeRef, costingsRef, costingDetailsRef, lcTrackersRef, accountsRef, userRef, samplingAnalysisRef, initialValues }) => {

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
      {
        key: '1',
        label: 'Masters',
        forceRender: true,
        children: <MasterRoleSelection formRef={mastersRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '2',
        label: 'Business Partner',
        forceRender: true,
        children: <BusinessPartnerSelection formRef={businessPartnerRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '3',
        label: 'Contracts',
        forceRender: true,
        children: <ContractsSelection formRef={contractsRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '4',
        label: 'Business Number',
        forceRender: true,
        children: <BusinessNoSelection formRef={businessNoRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '5',
        label: 'Barge',
        forceRender: true,
        children: <BargeSelection formRef={bargeRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '6',
        label: 'Costing Creation',
        forceRender: true,
        children: <CostingsSelection formRef={costingsRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '7',
        label: 'Costing Details',
        forceRender: true,
        children: <CostingDetails formRef={costingDetailsRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '8',
        label: 'Sampling Analysis',
        forceRender: true,
        children: <SamplingAnalysisSelection formRef={samplingAnalysisRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '9',
        label: 'Lc Tracker',
        forceRender: true,
        children: <LcTrackerSelection formRef={lcTrackersRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '10',
        label: 'Accounts',
        forceRender: true,
        children: <AccountSelection formRef={accountsRef} initialValues={initialValues} />,
        style: panelStyle,
      },
      {
        key: '11',
        label: 'User',
        forceRender: true,
        children: <UserSelection formRef={userRef} initialValues={initialValues} />,
        style: panelStyle,
      },

    ];
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 10,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <Collapse
      size="large"
      bordered={false}
      defaultActiveKey={['0']}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      // style={{ background: token.colorBgContainer }}
      items={getItems(panelStyle)}
    />
  );
}