import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';

import { CountryDropDownDto, CountryService, DestinationDto, DestinationService } from '@exportx/shared-models-and-services';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from '../../../../../common';
import './destination-form.css';
import { ClassValidator } from '@exportx/ui-utils';

const { Option } = Select;



export interface DestinationFormProps {
  destinatonData: DestinationDto;
  isUpdate: boolean;
  closeForm: () => void;
}

const classValidator = new ClassValidator();

export function DestinationForm(props: DestinationFormProps) {
  const [form] = Form.useForm();
  const service = new DestinationService();
  const countryService = new CountryService();
  const [, setSelectedCountry] = useState<string>(null);
  const [countryData, setCountryData] = useState<CountryDropDownDto[]>([]);
  let navigate = useNavigate();
  const { authContext } = useAuthState();
  const { state }: any = useLocation()
  const { destinationData, hidden } = state ? state : { destinationData: null, hidden: null }

  useEffect(() => {
    getAllActiveCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const save = (destinationDataValues: DestinationDto) => {
    service.createDestination({ ...destinationDataValues, createdUser: authContext.user.userName, destinationId: destinationData?.destinationId, versionFlag: destinationData?.versionFlag }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        onReset();
        navigate("/destination-view");
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }

  const getAllActiveCountries = () => {
    countryService.getAllCountryDropDownDto().then(res => {
      if (res.status) {
        setCountryData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const handleCountry = (value) => {
    setSelectedCountry(value);
  }


  const saveData = (values: DestinationDto) => {
    // console.log(values);
    // if (props.isUpdate) {
    //   props.updateDetails(values);
    // } else {

    // }
    save(values);
  };
  const onReset = () => {
    form.resetFields();
  };
  const onFocus = () => {
    console.log('focus');
  }

  const onSearch = (val) => {
    console.log('search:', val);
  }
  const onBlur = () => {
    console.log('blur');
  }
  return (


    <Card title={<span style={{ color: 'white' }}>Province</span>} extra={props.isUpdate === true ? "" : <Link to='/destination-view' ><span><Button>View </Button> </span></Link>}
      className='default-card-class'>
      <br /><br />
      <Form form={form} onFinish={saveData} initialValues={destinationData} autoComplete='off'>
        <Row gutter={24}>
          <Form.Item
            name="destinationId" style={{ display: "none" }} >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="countryId"
              label="Country Name"
              rules={[
                {
                  required: true,
                  message: 'Country Name is Required'
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: `Enter the Country Name`
                }
              ]}
            >
              <Select
                showSearch
                //style={{ width: 200 }}
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={handleCountry}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {/* <Option key={0} value={null}>Select Country</Option> */}
                {countryData.map((country) => {
                  return <Option key={country.countryId} value={country.countryId}>{country.countryName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="destinationName"
              label="Province Name"
              rules={[
                {
                  required: true,
                  message: 'Province is Required'
                },
                ...classValidator.alphaSpaces()
              ]}>

              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button htmlType="button" style={{ marginRight: '10px', display: hidden ? 'none' : 'block' }} onClick={onReset}  >
              Reset
            </Button>
            <Button type="primary" htmlType="submit" style={{ display: hidden ? 'none' : 'block' }} >
              Submit
            </Button>
            {/* } */}
          </Col>
        </Row>
      </Form>
    </Card>
  );
}





export default DestinationForm;
