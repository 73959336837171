import { BargeIdRequestDto, BargeService, CalculationTableDto, CommercialGetAllDto, ContractModesEnum, ContractTermEnum, MutualAgreed, PurchaseSupplierReportDto, SamplingAndAnalysisService, ScqTypicalRequestDto } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Button, Card, Col, Form, FormInstance, Input, InputNumber, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
interface IProsQualityPriceAdjustments {
  selectedRowData: CalculationTableDto;
  closeQualityModal?: () => void;
  commercialProp?: string;
  setPriceAdjustmentDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
  formRef?: FormInstance<any>;
  contractType: ContractModesEnum;
  formInitialValues?: CommercialGetAllDto
}

const { Option } = Select;
export const QualityPriceAdjustments = (props: IProsQualityPriceAdjustments) => {
  const { contractType, selectedRowData, closeQualityModal, commercialProp, setPriceAdjustmentDrawer, setDummyRefresh, formRef, formInitialValues } = props;
  const [bargeGridData, setBargeGridData] = useState<PurchaseSupplierReportDto[]>([]);
  const [initialValues, setInitialValues] = useState(undefined);
  const { authContext } = useAuthState();
  const qualityService = new SamplingAndAnalysisService();
  const bargeService = new BargeService();


  useEffect(() => {
    if (contractType === ContractModesEnum.SALES) {
      getScqTypicalSpecs();
    } else {
      getPurchaseSamplingSupplier();

    }
  }, []);

// values was not set 
  useEffect(() => {
    if (formInitialValues) {
      formRef.setFieldValue("gcvAdjustment", formInitialValues?.gcvAdjustment)
      formRef.setFieldValue("ashPriceAdjustment", formInitialValues?.ashPriceAdjustment)
      formRef.setFieldValue("tsPriceAdjustment", formInitialValues?.tsPriceAdjustment)
      formRef.setFieldValue("totalPenalty", formInitialValues?.totalPenalty)
    }

  }, [formInitialValues])
 

  useEffect(() => {
    if (bargeGridData) {
      setTotalValuesToFields();
    }
  }, [bargeGridData]);

  const getScqTypicalSpecs = () => {
    const req = new ScqTypicalRequestDto(formRef.getFieldValue('businessNumber'), selectedRowData?.bgUId, formRef.getFieldValue('allocatedId'), authContext.defaultPlant);
    bargeService.getScqTypicalSpecs(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        calculateDefaultBonusOrPenalty(res.data);

      } else {
        console.log(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };

  // useEffect(() => {
  //   if (contractType === ContractModesEnum.SALES) {
  //     getScqTypicalSpecs();
  //   } else {
  //     getPurchaseSamplingSupplier();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [bargeId[0]]);



  const setTotalValuesToFields = () => {
    formRef.validateFields().then(values => {
      const bonusPenaltyTotal: number = Number((Number(values.tsPriceAdjustment) + Number(values.gcvAdjustment) + Number(values.ashPriceAdjustment)).toFixed(2));
      const adjustedPrice: number = Number((Number(selectedRowData?.coalBasePrice) + Number(bonusPenaltyTotal)).toFixed(2));
      formRef.setFieldValue('totalBonusPrice', bonusPenaltyTotal);
      //Contract price - (GCV Adjustment +TS Penalty cal + Ash Penalty cal)
      formRef.setFieldValue('qualityAdjustPrice', Number(adjustedPrice));
    })

  }

  const rejectionHandler = (name: string, rowData: PurchaseSupplierReportDto) => {
    let disabled = true;
    let initialValue = null;
    if (name === 'gcvRejection') {
      if (Number(rowData?.cvActual) < Number(rowData?.cvRejection)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else if (name === 'ashRejection') {
      if (Number(rowData?.ashTypical) < Number(rowData?.ashActual)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else {
      if (Number(rowData?.tsTypical) < Number(rowData?.tsActual)) {
        disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    }

    formRef.setFieldValue(name, initialValue);
    return <Form.Item name={name} initialValue={initialValue}>
      <Select allowClear showSearch disabled={disabled} >
        <Option value={null} >{'No Rejection'}</Option>
        {React.Children.toArray(Object.keys(MutualAgreed).map(key => {
          return <Option value={MutualAgreed[key]}>{MutualAgreed[key]}</Option>
        }))}
      </Select>
    </Form.Item>
  }

  const tableColumns: any = [
    {
      title: 'Typical Analysis',
      dataIndex: 'typicalAnalysis',
      width: 130,
      align: 'center',
    },
    {
      title: 'Units',
      dataIndex: 'units',
      width: 130,
      align: 'center',
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      width: 130,
      align: 'center',
    },
    {
      title: 'Typical',
      dataIndex: 'typical',
      width: 130,
      align: 'center',
    },
    {
      title: 'Rejection',
      dataIndex: 'rejection',
      width: 130,
      align: 'center',
    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap',
      width: 130,
      align: 'center'
    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      width: 130,
      align: 'center'
    },
    {
      title: 'Actual',
      width: 130,
      align: 'center',
      dataIndex: 'actual'
    },
    {
      title: 'Rejection',
      width: 130,
      align: 'center',
      dataIndex: 'rejectionApplied',
      render: (name, row) => {
        return rejectionHandler(name, bargeGridData[0]);
      }
    },
    {
      title: 'Bonus/Penalty',
      width: 130,
      align: 'center',
      dataIndex: 'bonusOrPenaltyCap',
      render: (name, row) => {
        return <Form.Item name={name}>
          <InputNumber onChange={() => {
            const one = formRef.getFieldValue("gcvAdjustment")
            const two = formRef.getFieldValue("ashPriceAdjustment")
            const th = formRef.getFieldValue("tsPriceAdjustment")
            formRef.setFieldValue('totalBonusPrice', Number(one) + Number(two) + Number(th));
            setTotalValuesToFields();
          }} disabled={false} />
        </Form.Item>
      }
    },
  ];


  const tableData: any = [
    {
      typicalAnalysis: 'Gross Calorific Value-1',
      name: 'qualityCalorificValueActual',
      units: 'Kcal/Kg',
      basis: bargeGridData[0]?.cvBasis,
      typical: bargeGridData[0]?.cvTypical,
      rejection: bargeGridData[0]?.cvRejection,
      bonusCap: bargeGridData[0]?.cvBonusCap,
      penalty: bargeGridData[0]?.cvPenality,
      actual: bargeGridData[0]?.['cvActual'],
      rejectionApplied: "gcvRejection",
      bonusOrPenaltyCap: 'gcvAdjustment'
    },
    {
      typicalAnalysis: 'Ash',
      name: 'qualityAshActual',
      units: '%',
      basis: bargeGridData[0]?.ashBasis,
      typical: bargeGridData[0]?.ashTypical,
      rejection: bargeGridData[0]?.ashRejection,
      penalty: bargeGridData[0]?.ashPenality,
      actual: bargeGridData[0]?.['ashActual'],
      rejectionApplied: "ashRejection",
      bonusOrPenaltyCap: 'ashPriceAdjustment'
    },
    {
      typicalAnalysis: 'Total Sulphur',
      name: 'qualityTotalSulphurActual',
      units: '%',
      basis: bargeGridData[0]?.tsBasis,
      typical: bargeGridData[0]?.tsTypical,
      rejection: bargeGridData[0]?.tsRejection,
      penalty: bargeGridData[0]?.tsPenality,
      actual: bargeGridData[0]?.['tsActual'],
      rejectionApplied: "tsRejection",
      bonusOrPenaltyCap: 'tsPriceAdjustment'
    }
  ];


  const calculateDefaultBonusOrPenalty = (data: PurchaseSupplierReportDto[]) => {
    const pSQR = data[0];
    if (selectedRowData.purchasingTerms === ContractTermEnum.SPEC) {

      const bonusCap = Number.isNaN(Number(pSQR.cvBonusCap)) ? 0 : Number(pSQR.cvBonusCap);
      const gcvActual = Number.isNaN(Number(pSQR.cvActual)) ? 1 : Number(pSQR.cvActual);
      const gcvTypical = Number.isNaN(Number(pSQR.cvTypical)) ? 1 : Number(pSQR.cvTypical);
      const minOfActualToBonusCap = bonusCap ? Math.min(...[bonusCap, gcvActual]) : gcvActual;
      //Price * (Actual/Typical)
      const gcvAdjustmentPrice = selectedRowData.coalBasePrice * (Number(minOfActualToBonusCap) / Number(gcvTypical));
      console.log(`${selectedRowData.coalBasePrice} * (${Number(minOfActualToBonusCap)} / ${Number(gcvTypical)})`);
      const gcvAdjustment = (gcvAdjustmentPrice - selectedRowData.coalBasePrice).toFixed(2);
      const tsPenality = Number.isNaN(Number(pSQR.tsPenality)) ? 1 : Number(pSQR.tsPenality);
      const tsActual = Number.isNaN(Number(pSQR.tsActual)) ? 1 : Number(pSQR.tsActual);
      const tsTypical = Number.isNaN(Number(pSQR.tsTypical)) ? 1 : Number(pSQR.tsTypical);
      let tsPriceAdjustment: any = 0;
      if (tsTypical < tsActual) {
        //Penalty *(Actual TS - Typical)/0.1
        tsPriceAdjustment = (-(tsPenality * (tsActual - tsTypical) / 0.1)).toFixed(2);
      }

      const ashPenality = Number.isNaN(Number(pSQR.ashPenality)) ? 1 : Number(pSQR.ashPenality);
      const ashActual = Number.isNaN(Number(pSQR.ashActual)) ? 1 : Number(pSQR.ashActual);
      const ashTypical = Number.isNaN(Number(pSQR.ashTypical)) ? 1 : Number(pSQR.ashTypical);
      let ashPriceAdjustment: any = 0;
      if (ashTypical < ashActual) {
        //Penalty *(Actual ASH - Typical)/0.1
        ashPriceAdjustment = (-(ashPenality * ((ashActual - ashTypical) / 1))).toFixed(2);
      }


      const obj = {
        gcvRejection: null,
        ashRejection: null,
        tsRejection: null,
        gcvAdjustment,
        ashPriceAdjustment,
        tsPriceAdjustment,
      };
      setInitialValues(obj);
      formRef.setFieldsValue(obj);
    } else {
      const obj = {
        gcvRejection: null,
        ashRejection: null,
        tsRejection: null,
        gcvAdjustment: 0,
        ashPriceAdjustment: 0,
        tsPriceAdjustment: 0,
      };
      setInitialValues(obj);
      formRef.setFieldsValue(obj);
    }
  }

  const getPurchaseSamplingSupplier = () => {
    const req = new BargeIdRequestDto(selectedRowData?.bgUId, authContext.defaultPlant);
    qualityService.getPurchaseSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        calculateDefaultBonusOrPenalty(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const submitButtonHandler = () => {
    if (commercialProp === "Commercial") {
      setPriceAdjustmentDrawer(false);
      setDummyRefresh(prev => prev + 1);
    } else {
      closeQualityModal();
    }

  }

  const cancelHandler = () => {
    if (commercialProp === "Commercial") {
      setPriceAdjustmentDrawer(false);
      setDummyRefresh(prev => prev + 1);
    } else {
      closeQualityModal();
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Barge',
      dataIndex: 'bargeId',
      render: (value) => {
        return <>
          {SequenceUtils.formatNumberToSpecificLength(value)}

        </>
      }
    },
    {
      title: 'Coal Supplier',
      dataIndex: 'coalSupplierName'
    },
    {
      title: 'Barge Qty',
      dataIndex: 'bargeQuantityInMt'
    },
    {
      title: 'Coal Price per MT',
      dataIndex: 'coalBasePrice'
    },
    {
      title: 'Currency',
      dataIndex: 'baseCurrency'
    },
  ];


  return (
    <div>
      {
        bargeGridData.length !== 0 &&
        <Card>
          <Form form={formRef} initialValues={initialValues} autoComplete='off'>
            {commercialProp === 'Commercial' &&
              <>
                <Card title={'Price Adjustment'} className='default-card-class'>
                  <Table columns={columns} pagination={false} className='contracts' dataSource={[selectedRowData]}>
                  </Table>
                </Card>
                <br />
              </>
            }


            <Table
              className='contracts'
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
              bordered
            />
            <br></br>

            {commercialProp === 'Commercial' &&
              <>
                <br />
                <Row>
                  <Col span={8}>

                  </Col>
                  <Col span={9}>
                  </Col>
                  <Col span={7}>
                    <Form.Item label={'Total Penalty / Bonus'} name='totalBonusPrice'>
                      <Input disabled={true} >
                      </Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                  </Col>
                  <Col span={10}>
                  </Col>
                  <Col span={6}>
                    <Form.Item label={'Adjusted Price'} name={'qualityAdjustPrice'}>
                      <Input disabled={true}>
                      </Input>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }


            <Row justify='space-around'>
              <Col>
                <Button onClick={() => { cancelHandler(); }}>Cancel</Button>
              </Col>
              <Col>
                <Form.Item >
                  <Button onClick={() => { submitButtonHandler(); }} type="primary">Submit</Button>
                </Form.Item>

              </Col>
            </Row>



          </Form>
        </Card>
      }
    </div>
  )
}

export default QualityPriceAdjustments;