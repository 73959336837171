import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { DebCreIdReq, DebitCreditService, NoteTypeReq } from '@exportx/shared-models-and-services'
import { Divider, Form, Popconfirm, Radio, RadioChangeEvent, Row, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AlertMessages, useAuthState } from '../../../../common'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';
import { internationalFormattedValue } from '@exportx/ui-utils'

export interface DebitCreditRejectedEntriesProps {
    setEditDataToForm: (values) => void;
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
    noteType: string,
}


export default function DebitCreditRejectedEntries(props: DebitCreditRejectedEntriesProps) {
    const { formatMessage: fm } = useIntl();
    const [data, setData] = useState<any[]>([])
    // const [noteType, setNoteype] = useState<any>(props.noteType);
    const [form] = Form.useForm();
    const { authContext } = useAuthState();
    const service = new DebitCreditService();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.reference_id;
    let { reference_id }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };

    // const options = [
    //     { label: 'Debit Note', value: 'Debit Note' },
    //     { label: 'Credit Note', value: 'Credit Note' },
    // ];


    useEffect(() => {
        editHandler(reference_id)
    }, [reference_id])

    useEffect(()=>{
        const req = new NoteTypeReq(props.noteType, authContext.defaultPlant)
        service.getRejectedEntries(req).then((res) => {
            if (res.status) {
                setData(res.data);
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log('err', err)
        });
    },[props.noteType])



    const getData = () => {
        const req = new NoteTypeReq(form.getFieldValue('noteType'), authContext.defaultPlant)
        service.getRejectedEntries(req).then((res) => {
            if (res.status) {
                setData(res.data);
            } else {
                setData([]);
            }
        }).catch((err) => {
            console.log('err', err)
        });
    };


    const deleteDebitCreditEntry = (value) => {
        const req = new DebCreIdReq(value, authContext.defaultPlant, props.noteType)
        service.deleteDebitCreditEntry(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getData()
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }


    const editHandler = (value: string) => {
        const req = new DebCreIdReq(value, authContext.defaultPlant, props.noteType)
        service.getDataForUpdateDebit(req).then(res => {
            if (res.status) {
                props.setEditDataToForm(res.data);
            } else {
                //setBargeCostingData([]);
            }
        }).catch(err => {
            console.log(err)
            // setBargeCostingData([]);
        })
    }

    // const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
    //     setNoteype(value);
    // };



    const columns: any = [
        {
            title: 'Action',
            dataIndex: 'action',
            width: '20%',
            align: 'center',
            render: (text, rowData) => (
                <span>
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => editHandler(rowData.debCreId)}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        /></Tooltip>

                    <Divider type="vertical" />
                    <Popconfirm
                        onConfirm={e => {
                            //activateOrDeactivate(RowData);
                            deleteDebitCreditEntry(rowData.debCreId)
                        }}
                        title={fm({ id: "common.deleteConfirmMsg", defaultMessage: 'Are you sure to Delete Record ?' })}>
                        <Tooltip placement="topRight" title="Delete">
                            <DeleteOutlined type='delete' onClick={() => { }} style={{ color: 'red', fontSize: '18px' }} />
                        </Tooltip>
                    </Popconfirm>

                </span>
            )
        },
        {
            title: 'No#',
            dataIndex: 'refrenceNo'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: (text) => moment(text).format('DD-MM-YYYY')
        },
        {
            title: 'Account',
            dataIndex: 'account'
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            render: (text) => internationalFormattedValue(text)

        }
    ]


    return (

        <Form form={form} autoComplete='off'>
            {/* <Row gutter={24} justify='center'>
                <Col span={12} >
                    <Form.Item name='noteType' label='Note Type:'   >
                        <Radio.Group options={options} onChange={radioOnChange} value={noteType} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button type='primary' onClick={() => { getData() }}>Get Data</Button>
                </Col>
            </Row> */}
            <Row>
                <Table scroll={{ x: true, y: 1000 }} size='small' bordered rowKey={(row) => row.debCreId} columns={columns} dataSource={data} />
            </Row>
        </Form>
    )
}

