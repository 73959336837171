import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { ContractStatusEnum } from '@exportx/shared-models-and-services';
interface IProps {
    status: ContractStatusEnum | string;
}
export const StatusTag = (props: IProps) => {
    const { status } = props;

    const getData = (status: ContractStatusEnum | string) => {
        switch (status) {
            case ContractStatusEnum.DRAFT:
                return <Tag icon={<ExclamationCircleOutlined />} color="warning" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.DRAFT}
                </Tag>
                break;
            case ContractStatusEnum.ACTIVE:
                return <Tag icon={<CheckCircleOutlined />} color="success" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.ACTIVE}
                </Tag>
                break;

            case ContractStatusEnum.CANCELLED:
                return <Tag icon={<CloseCircleOutlined />} color="error" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.CANCELLED}
                </Tag>
                break;


            case ContractStatusEnum.CLOSED:
                return <Tag icon={<ClockCircleOutlined />} color="default" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.CLOSED}
                </Tag>
                break;


            case ContractStatusEnum.PENDING_APPROVAL:
                return <Tag icon={<SyncOutlined spin />} color="processing" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.PENDING_APPROVAL}
                </Tag>
                break;
            case ContractStatusEnum.PENDING_AUDIT_APPROVAL:
                return <Tag icon={<SyncOutlined spin />} color="processing" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.PENDING_AUDIT_APPROVAL}
                </Tag>
                break;

            case ContractStatusEnum.PENDING_MANGEMENT_APPROVAL:
                return <Tag icon={<SyncOutlined spin />} color="processing" style={{ fontSize: '10px' }}>
                    {ContractStatusEnum.PENDING_MANGEMENT_APPROVAL}
                </Tag>
                break;

            default:
                return <Tag icon={<MinusCircleOutlined />} color="default" style={{ fontSize: '10px' }}>
                    stop
                </Tag>
                break;
        }
    }
    return (
        <>{
            getData(status)
        }</>
    )
}

export default StatusTag