import { EditOutlined, MinusSquareOutlined, PlusSquareOutlined, UploadOutlined } from '@ant-design/icons';
import { AccountsService, BNoRequest, BargeService, BpCatCodeEnum, BusinessNoPlantCodeReq, BusinessNumberService, CompanyCodeReq, CompanyTdsService, ContractStatusEnum, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyService, ExpenseEntryAllDto, ExpenseEntryDto, ExpenseEntryIdReq, ExpenseEntryService, ExpenseEntryTransactionsDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PerformaAdvanceDataDto, TaxesAndItemsService, TdsResModel } from "@exportx/shared-models-and-services";
import { SequenceUtils, getBase64, getLocalFormat } from "@exportx/ui-utils";
import { Button, Card, Checkbox, Col, Descriptions, Divider, Drawer, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select, Typography, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Table, { ColumnsType } from "antd/lib/table";
import Link from "antd/lib/typography/Link";
import moment from "moment";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { AlertMessages, useAuthState } from "../../../../common";
import DatePicker from "../../../../common/data-picker/date-picker";
import ExpenseEntryRejectedList from "./expense-entry-rejected-list";
import BargeAdvanceAdjustments from '../barge/barge-advance-adjustment';

export interface PaymentNoticeExpensiveProps {
  type: string,
}
const PaymentNoticeExpensive = (props: PaymentNoticeExpensiveProps) => {
  const { Option } = Select
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([])
  const [bargeDropDown, setBargeDropDown] = useState<any[]>([])
  const businessNumberService = new BusinessNumberService();
  const { authContext } = useAuthState()
  const bargeService = new BargeService()
  const [paymentNotice, setPaymentNotice] = useState([])
  const [formRef] = Form.useForm()
  const [childFormRef] = Form.useForm()
  const [check, setCheck] = useState<any>()
  const [payable, setPayable] = useState<any>()
  const { Text } = Typography;
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const [taxes, setTaxes] = useState<GetTaxesByBpIdDto[]>([]);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [editedRowIndex, setEditedRowIndex] = useState<number>();
  const [expenseLedger, setExpenseLedgers] = useState<any>([]);
  const [vendorLedger, setVendorLedgers] = useState<any>([]);
  const [isBusNoRequired, setIsBusNoReq] = useState<boolean>(true);
  const [isBargeRequired, setIsBargeReq] = useState<boolean>(true);
  const [fileList, setFileList] = useState([]);

  const [currencyData, setCurrencyData] = useState<any>(authContext.defaultPlantCurrency);
  const [currency, setCurrency] = useState<any[]>([]);
  const [isSameCurrency, setIsSameCurrency] = useState<boolean>(true);
  const [exRate, setExRate] = useState<any>(1);
  const [displayExchangeRate, setDisplayExchangeRate] = useState<number>()
  const [dummyRefresh, setDummyRefresh] = useState(1);
  const [initialValues, setInitialValues] = useState<ExpenseEntryAllDto>();
  const companyTdsService = new CompanyTdsService();
  const taxesAndItemsService = new TaxesAndItemsService();
  const expenseEntryService = new ExpenseEntryService()
  const actLedgerService = new AccountsService()
  const currencyService = new CurrencyService();
  const [vatApplicable, setVatApplicable] = useState<boolean>(false)
  const currencyConverterService = new CurrencyConverterService();
  const [expansiveLedger, setExpansiveLedger] = useState<any>();

  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  const [invoiceContracts, setInvoiceContracts] = useState<PerformaAdvanceDataDto[]>([]);

  let location = useLocation();
  let paramsId: any = queryString.parse(location.search)?.costingId;

  let SubTotal = 0
  const [totalAmount, setTotalAmount] = useState<any>({ vat: 0, tds: 0, total: 0, taxPercentage: 0, tdsPercent: 0, subTotal: SubTotal })
  const selectAfter = (
    <Form.Item name="currencyCode" noStyle label="Please Select">
      <Select style={{ width: '170%' }} defaultValue={authContext.defaultPlantCurrency} onChange={(val, option: any) => handleCurrencyChange(option.value)}>
        {currency?.map((res) => {
          return <Option key={res.currencyCode} value={res.currencyCode}>{res.currencyCode}</Option>
        })}
      </Select>
    </Form.Item>
  );


  const closeRejectionDrawer = () => {
    setDrawerVisible(false)
    setDummyRefresh(prev => prev + 1);
  };

  useEffect(() => {
    BusinessNoDropDown();
    getTdsData();
    getTaxesByItemsId();
    getExpenseLedgers();
    getCurrenciesData();
    formRef.setFieldValue("preparedBy", authContext.user.userName)
    childFormRef.setFieldValue("currencyCode", authContext.defaultPlantCurrency);
  }, []);


  useEffect(() => {
    if (paramsId) {
      updateExpensive(paramsId);
    };
  }, [paramsId]);

  useEffect(() => {
    formRef.setFieldsValue(totalAmount)
    formRef.setFieldValue('totalAmount', totalAmount?.total)
    const totalAmountOfAdjustments = adjustedData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0) ?? 0;
    const remaining = (Number(formRef.getFieldValue('totalAmount'))) - Number(totalAmountOfAdjustments)
    formRef.setFieldValue("remainingBalance", Number(remaining?.toFixed(2)).toLocaleString());
    formRef.setFieldValue("totalAdjustedValue", Number(totalAmountOfAdjustments?.toFixed(2)).toLocaleString());

  }, [totalAmount, adjustedData])


  const tableColumns: ColumnsType<any> = [
    {
      title: "Business Number",
      dataIndex: "businessNumber"
    },
    {
      title: "Barge",
      dataIndex: "bargesId",
      render: (value) => { return SequenceUtils.formatNumberToSpecificLength(value); }
    },
    {
      title: "Qty",
      dataIndex: "qty"
    },
    {
      title: "Payable",
      dataIndex: "payable"
    },
    {
      title: "Expense Price ",
      dataIndex: "expensivePrice",
      align: 'right'
    },
    {
      title: "Currency",
      dataIndex: "currencyCode",
      align: 'right'
    },
    {
      title: "Total",
      dataIndex: "total",
      align: 'right',
      render: (value, record) => {
        return <>{getLocalFormat(value, expansiveLedger?.expenseLd === "Other Income" ? paymentNotice[0]?.currencyCode : authContext.defaultPlantCurrency)}</>
      }
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },

    {
      title: "Action",
      render: (value, record, index) => {
        return <span>
          <MinusSquareOutlined type="delete"
            onClick={() => {
              removeOne(index);
            }}
            style={{ color: 'red', fontSize: '16px' }}
          />
          <Divider type="vertical" />
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => editTableData(record, index)}
            style={{ color: '#1890ff', fontSize: '14px' }}
          />
        </span>
      }
    }

  ];


  const getExpenseLedgers = () => {
    expenseEntryService.getExpenseLedgers().then((res) => {
      if (res.status) {
        setExpenseLedgers(res.data);
      } else {
        setExpenseLedgers([]);
      };
    }).catch(err => console.log(err.message, 'err message'));
  };


  const getVendorLedgers = (req: BpCatCodeEnum[], expenseLd?: any) => {
    setExpansiveLedger((prev) => {
      return {
        ...prev, ledger: req?.length ? req[0] : '',
        expenseLd: expenseLd,
      }
    })
    BargeDropDown(formRef.getFieldValue('businessNo'), formRef.getFieldValue('expenseType'), req?.length ? req[0] : undefined, expenseLd);
    actLedgerService.getActLedForBpCatCode(req).then((res) => {
      if (res.status) {
        setVendorLedgers(res.data);
      } else {
        setVendorLedgers([]);
      };
    }).catch((err) => {
      setVendorLedgers([]);
      console.log('error: ' + err.message);
    })
  };


  const BargeDropDown = async (text: any, expenseType?: string, chemicalSpray?: string, expenseLd?: string) => {
    const req = new BNoRequest(text, authContext.defaultPlant, expenseType, chemicalSpray, expenseLd);
    bargeService.getBargeDropForBusinessNo(req).then(res => {
      if (res.status) {
        setBargeDropDown(res.data);
      } else {
        setBargeDropDown([]);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    });

  };

  const getBusinessNoVesselLinkingDetails = (businessNo: string) => {
    const req = new BNoRequest(businessNo, authContext.defaultPlant)
    businessNumberService.getBnVesselLinkingDetails(req).then(res => {
      if (res.status && res.data) {
        childFormRef.setFieldsValue({
          qty: res.data.stowageQty,
          payable: res.data.priceType === 'PMT' ? 'PerMt' : 'lumpsum',
          expensivePrice: res.data.price,
        });
        setPayable(res.data.priceType === 'PMT' ? 'PerMt' : 'lumpsum');
        const findVendorLedger = vendorLedger.find((ven) => ven.bp_id === res.data.vesselVednor)?.actLedId
        formRef.setFieldValue('vendorLedger', findVendorLedger);
        calculateTotalPrice()
      } else {
        childFormRef.setFieldsValue({
          qty: '',
          payable: '',
          expensivePrice: '',
        });
        setPayable('');
        calculateTotalPrice()
        formRef.setFieldValue('vendorLedger', null);

      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }



  async function businessNoOnChange(key, text) {

    if (key == 'businessNumber' && expansiveLedger?.expenseLd == "Freight Costing") {
      getBusinessNoVesselLinkingDetails(text.value)

    }
    if (key == 'bargesId') {
      childFormRef.setFieldsValue({ qty: text?.quantity, expensivePrice: text?.expensivePrice, })
      if (text?.currencyCode) {
        childFormRef.setFieldValue("currencyCode", text?.currencyCode);
        handleCurrencyChange(text?.currencyCode)
      };
    };
    if (text === undefined) {
      childFormRef.setFieldValue("currencyCode", authContext.defaultPlantCurrency);
    }
    formRef.setFieldValue('bargeId', undefined);
    if (Array.isArray(text))
      return setCheck((prev) => {
        const coactedBarge = text.map(err => err.children).join(",");
        return { ...prev, [key]: coactedBarge }
      });
    setCheck((prev) => {
      return {
        ...prev,
        [key]: text?.children
      };
    });

  };

  const handleExpenseType = async (val) => {

    if (val == 'Mother Vessel') {
      setIsBusNoReq(true);
      setIsBargeReq(false);
      await BargeDropDown(formRef.getFieldValue('businessNo'), val);

    }
    else if (val === "Barge") {
      setIsBusNoReq(false);
      setIsBargeReq(true)
      await BargeDropDown(formRef.getFieldValue('businessNo'));
    }
  };


  const BusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    businessNumberService.getBusinessNoDropDown(req).then(res => {
      if (res.status) {
        setBusinessDropDown(res.data)
      };
    }).catch(err => {
      console.log('error: ' + err.message);
    });
  };

  const getCurrenciesData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data);
      };

    }).catch(err => {
      console.log("error");
    });
  };



  function handleCurrencyChange(val) {
    setCurrencyData(val)
    childFormRef.setFieldValue('quoteCurrency', val)
    if (authContext.defaultPlantCurrency == val) {
      setIsSameCurrency(true)
    } else {
      setIsSameCurrency(false)
      calculateSystemCurrency()
    }
  };


  const noticePaymentValues = () => {
    formRef.validateFields(['expenseType', 'expenseLedger', 'vendorLedger']).then((parentValues) => {
      childFormRef.validateFields().then(values => {
        if (values) {
          const result = [...paymentNotice]
          result.push({ ...Object.assign(values, check), currency: currencyData })
          if (editedRowIndex == undefined) {
            setPaymentNotice(result)
            setTotalAmount((prev) => { return { ...prev, subTotal: prev.subTotal + values?.total, total: prev?.total + values?.total } })

          } else {
            const newObj = Object.assign(values, check)
            const result = [...paymentNotice];
            setTotalAmount((prev) => { return { ...prev, subTotal: prev?.subTotal - result[editedRowIndex]?.total + values?.total, total: prev?.total - result[editedRowIndex]?.total + values?.total } })
            setPaymentNotice(prevState => { paymentNotice[editedRowIndex] = newObj; return [...paymentNotice] });
            setEditedRowIndex(undefined)
          }
        }
        childFormRef.setFieldValue("currencyCode", authContext.defaultPlantCurrency);
        childFormRef.resetFields(['businessNo', 'bargeId', 'qty', 'payable', 'expensivePrice', 'remarks', 'total', 'jisdorDate']);
        setDisplayExchangeRate(0);
        handleCurrencyChange(authContext.defaultPlantCurrency);
      })
    })
  };

  const onFileChange = (data) => {
    setFileList([data.file]);
  }


  const updateExpensive = (value) => {
    const req = new ExpenseEntryIdReq()
    req.expenseEntryId = value;
    req.plantCode = authContext.defaultPlant;
    expenseEntryService.updateExpensive(req).then((res) => {
      if (res.status) {
        setInitialValues(res.data);
        if (res.data.date) {
          res.data.date = moment(res.data.date);
        };
        formRef.setFieldsValue(res.data);
        setPaymentNotice(res.data.expenseEntryTransactions);
        getTaxesByItemsId();
        getVendorLedgers(res.data.bpCodes, res.data.expenseLedger === 'be5a9897-bb83-11ee-96af-0a5924ec7c5d' ? "Other Income" : undefined);
        noticePaymentValues();
        handleExpenseType(res.data.expenseType);
        setTotalAmount((prev) => {
          return {
            ...prev,
            vat: Number(res.data.vat),
            tds: Number(res.data.tds),
            total: Number(res.data.total),
            tdsPercent: Number(res.data.tdsPercent),
            subTotal: res.data.expenseEntryTransactions.reduce((a, c) => a + Number(c.total), 0)
          }
        });
        vatOnchange(res.data.vatApplicable);
        setDrawerVisible(false);
        setAdjustedData(res.data.advanceTarnsactionsData)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      console.log(err.message)
    })
  };




  function editTableData(rowData, index) {
    if (rowData?.businessNoId) {
      businessNoOnChange('businessNumber', { key: rowData?.businessNo, value: rowData?.businessNo, children: rowData.businessNumber });
    };
    if (rowData?.bgId) {
      businessNoOnChange('bargesId', { key: rowData?.bargeId, value: rowData?.bargeId, children: rowData.bargesId });
    };
    setEditedRowIndex(index)
    childFormRef.setFieldsValue({
      businessNo: rowData.businessNo,
      bargeId: rowData.bargeId,
      qty: rowData.qty,
      payable: rowData.payable,
      total: rowData.total,
      expensivePrice: rowData.expensivePrice,
      remarks: rowData.remarks,
      expenseEntryTransactionsId: rowData.expenseEntryTransactionsId,
      expenseEntryId: rowData.expenseEntryId,
      jisdorDate: moment(rowData.jisdorDate),
      currencyCode: rowData.currencyCode
    });
    setDisplayExchangeRate(Number(rowData.exchangeRate));
    handleCurrencyChange(rowData.currencyCode ? rowData.currencyCode : authContext.defaultPlantCurrency);
  };


  function removeOne(index) {
    paymentNotice.splice(index, 1)
    setPaymentNotice([...paymentNotice])
  }
  const options = [
    { label: 'PerMt', value: 'PerMt' },
    { label: 'lumpsum', value: 'lumpsum' },
  ];

  const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
    setPayable(value);
    calculateTotalPrice();
  };

  function calculateTotalPrice() {
    const qty = childFormRef.getFieldValue('qty')
    const expensivePrice = childFormRef.getFieldValue('expensivePrice')
    const payable = childFormRef.getFieldValue('payable')
    const expenseLedger = childFormRef.getFieldValue('expenseLedger')
    const exchangeRate = childFormRef.getFieldValue('exchangeRate')
    if (qty && payable && expensivePrice) {
      if (payable == 'PerMt') {
        let total = Number(qty) * Number(expensivePrice) * Number(exchangeRate)
        if (expenseLedger == "Export Tax") {
          total = total * 1.5
        }
        childFormRef.setFieldsValue({ total: Number(total.toFixed(2)) })
      }
      else {
        childFormRef.setFieldsValue({ total: Number((expensivePrice * Number(exchangeRate)).toFixed(2)) })
      }
    }
  };

  const getTaxesByItemsId = () => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.BARGE, moment(formRef.getFieldValue('date')).format("YYYY-MM-DD"));
    taxesAndItemsService.getTaxesByItemsId(req).then(res => {
      if (res.status) {
        setTaxes(res.data);
      } else {
        setTaxes([]);
      }
    }).catch(err => {
      console.log(err);
      setTaxes([]);
    });
  };


  const vatOnchange = (e: boolean) => {
    setVatApplicable(e)
    if (e) {
      const taxId = taxes[0]?.taxId ? taxes[0]?.taxId : null;
      const taxPercentage = taxes[1]?.taxPercentage ? Number(taxes[1]?.taxPercentage) : 0;
      setTotalAmount((prevState) => {
        const vatValue = (taxPercentage / 100) * Number(prevState?.subTotal);
        return {
          ...prevState,
          vat: vatValue,
          taxId: taxId,
          taxPercentage: taxPercentage,
          total: Number(vatValue) + Number(prevState?.subTotal) - Number(prevState?.tds)
          // total: (prevState.total + Number(vatValue))
        }
      }
      )
    }
    else {
      setTotalAmount(prevState => {
        const vatValue = 0;
        return {
          ...prevState,
          vat: vatValue,
          total: prevState.total - prevState.vat
        }
      })
    }

  };


  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = formRef.getFieldValue('date') ? formRef.getFieldValue('date') : moment();
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  };

  function tdsOnchange(tdsId) {
    const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
    const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
    setTotalAmount((prev) => {
      const tdsTotal = (tdsPercentage / 100) * Number(prev.subTotal)
      return {
        ...prev,
        tdsId: tdsId,
        // total: (prev.total + prev.tds - Number(tdsTotal.toFixed(2))),
        tds: Number((tdsTotal).toFixed(2)),
        total: Number(prev?.vat) + Number(prev?.subTotal) - Number(tdsTotal),
        tdsPercent: Number((tdsPercentage).toFixed(2))
      }
    })
  };




  const calculateSystemCurrency = () => {
    let billingCurrency = authContext?.defaultPlantCurrency
    let negotiationCurrency = childFormRef.getFieldValue('quoteCurrency')
    let jisdorDate = childFormRef.getFieldValue('jisdorDate')
    const req = new CurrencyExchangeRequestDto(negotiationCurrency, billingCurrency, moment(jisdorDate).format('YYYY-MM-DD'))
    currencyConverterService.getCurrencyExchangeRate(req).then(res => {
      if (res.status) {
        childFormRef.setFieldValue('exchangeRate', res?.data?.exchangeRate)
        setExRate(res.data?.exchangeRate);
        setDisplayExchangeRate(res.data.displayExchangeRate)
      } else {
        AlertMessages.getInfoMessage("Exchange rate not found for the selected JISDOR date,choose another date")
        childFormRef.resetFields(['jisdorDate', 'total'])
        childFormRef.setFieldValue('exchangeRate', 1)
        setExRate(1);
      }
    }).catch(err => {
      console.log('error:' + err.message);
    }).finally(() => {
      calculateTotalPrice()
    })
    setIsSameCurrency(false)
  }

  const setEditDataToForm = (values) => {
    formRef.setFieldsValue(values)
  }


  const onSubmitForApproval = () => {
    formRef.validateFields().then(async values => {
      if (paymentNotice.length) {
        values = { ...values, ...totalAmount }
        setInitialValues(values.expenseEntryId)
        setInitialValues(values.expenseEntryTransactionsId)
        const formFilesData = formRef.getFieldValue('invoiceFile');
        let filesData = []
        if (formFilesData?.file) {
          const base64 = await getBase64(formFilesData.file.originFileObj);
          filesData = [{
            ...formFilesData.file,
            base64Url: base64,
            fileDescription: 'Invoice file'
          }];
        }
        const expenseEntryTransactionsDto: ExpenseEntryTransactionsDto[] = []
        paymentNotice.forEach((el) => {
          const childDto = new ExpenseEntryTransactionsDto(el.businessNo, el.vendorTypeId, values.expenseLedger, values.vendorLedger ? values.vendorLedger : '', [el.bargeId], el.qty, el.expensivePrice, el.total, el.payable, true, authContext.user.userName, undefined, 1, '', el.remarks, el.currencyCode, el.expenseEntryId, el.expenseEntryTransactionsId, el.jisdorDate, el.exchangeRate, el.currencyCode)
          expenseEntryTransactionsDto.push(childDto);
        });
        const parentDto = { ... new ExpenseEntryDto(values.date, values.totalAmount, values.taxPercentage, values.vat, values.tdsPercent, values.tds, values.total, values.worklog ?? '', values.preparedBy, authContext.defaultPlant, true, authContext.user.userName, undefined, values.taxId, values.tdsId, filesData, expenseEntryTransactionsDto, values.expenseEntryId, values.expenseType, '', '', undefined, '', '', undefined, initialValues?.expenseEntryNo ? initialValues?.expenseEntryNo : null, vatApplicable), subTotal: totalAmount.subTotal, adjustmentTransactions: adjustedData }

        expenseEntryService.createExpenseEntry(parentDto).then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            formRef.setFieldValue('jisdorDate', undefined);
            setPaymentNotice([]);
            setTotalAmount({ vat: 0, tds: 0, total: SubTotal, taxPercentage: 0, tdsPercent: 0 });
            setVatApplicable(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000)
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          };
        })
      } else {
        AlertMessages.getErrorMessage('Please Add Transaction');
      }


    }).catch(err => console.log(err.message));
  }

  console.log(expansiveLedger?.expenseLd, paymentNotice[0]?.currencyCode, paymentNotice)
  const suffixSelector2 = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select disabled>
        <Option>IDR</Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Row justify='end'>
        <Link onClick={() => setDrawerVisible(true)}>View Entries</Link>
      </Row>
      <Card title="Expense Entry" >
        <Form layout='vertical' form={formRef} initialValues={initialValues} >
          <Row gutter={16}>

            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Expense type:" name="expenseType" rules={[{ required: true }]}>
                <Select
                  disabled={paymentNotice.length > 0}
                  onChange={(val) => handleExpenseType(val)}
                  allowClear showSearch style={{ width: '100%' }} placeholder=' Select Expense Ledger' dropdownMatchSelectWidth={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                  <Option value={"Mother Vessel"} key={"Mother Vessel"} >{"Mother Vessel"}</Option>
                  <Option value={"Barge"} key={"Barge"} >{"Barge"}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Expense Ledger:" name="expenseLedger" rules={[{ required: true }]}>
                <Select
                  disabled={paymentNotice.length > 0}
                  allowClear showSearch style={{ width: '100%' }} placeholder=' Select Expense Ledger' dropdownMatchSelectWidth={true}
                  optionFilterProp="children"
                  onChange={(value, opt: any) => { getVendorLedgers(opt?.bpCodes, opt?.el) }}

                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                  {expenseLedger?.filter((led) => props.type === 'Account Payable' ? led.expenseLedger !== 'Other Income' : led.expenseLedger === 'Other Income').map((el) => {
                    return <Option bpCodes={el.categories} el={el.expenseLedger} value={el.actLedId} key={el.actLedId + 2} >{el.expenseLedger}</Option>
                  })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Vendor Ledger:" name="vendorLedger" rules={[{ required: true }]} >
                <Select
                  disabled={paymentNotice.length > 0}
                  allowClear showSearch style={{ width: '100%' }} placeholder='Select Vendor Ledger'
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                  {vendorLedger?.map(el => {
                    return <Option key={el.actLedId + 1} value={el.actLedId}>{el.actLedName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item name={'expenseEntryId'} style={{ display: 'none' }}><Input hidden /></Form.Item>
              <Form.Item label="Date:" name="date" rules={[{ required: true }]} initialValue={moment()} >
                <DatePicker defaultValue={moment()} disabled={paymentNotice.length > 0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider type='horizontal' />
        <Form layout='vertical' form={childFormRef} name="child-form" >
          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={'expenseEntryId'}
                style={{ display: 'none' }}>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Business No:" name="businessNo" rules={[{ required: isBusNoRequired }]}  >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch style={{ width: '100%' }}
                  placeholder='Select Business No'
                  onChange={(key, text) => businessNoOnChange('businessNumber', text)}
                >
                  {/* <Option value={'ALL'}>All</Option> */}
                  {businessDropDown.map(item => {
                    return <Option key={item.bnId} value={item.bnId}>{item.businessName}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Barge" name="bargeId" rules={[{ required: isBargeRequired }]}>
                <Select
                  allowClear showSearch style={{ width: '100%' }}
                  // mode='multiple'
                  placeholder='Select Barge'
                  onChange={(key, text) => businessNoOnChange('bargesId', text)}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                  {/* <Option value={'ALL'}>All</Option> */}
                  {bargeDropDown?.map(item => {
                    return <Option quantity={item.bargeQuantityInMt} value={item.id} expensivePrice={item.chemicalSprayPrice} currencyCode={item.chemicalSprayCurrency} >{SequenceUtils.formatNumberToSpecificLength(item.bargeId.toString()) + " " + `(${item.bargeNomination})`}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Quantity:" name="qty" rules={[{ required: true, message: 'Please Enter Quantity' }]}>
                <Input type="text" onChange={() => calculateTotalPrice()}></Input>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item name='payable' label='Payable by:' rules={[{ required: true, message: '' }]} >
                <Radio.Group buttonStyle="solid" optionType="button" options={options} onChange={radioOnChange} value={payable} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Expense Price:" name="expensivePrice" rules={[{ required: true, message: 'Please Enter ExpensivePrice' }]}>
                <Input type="text" onChange={() => calculateTotalPrice()} addonAfter={selectAfter} ></Input>
              </Form.Item>
            </Col>
            {!isSameCurrency &&
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} offset={1}>
                <Form.Item name="jisdorDate" label='JISDOR Date' rules={[{ required: expansiveLedger?.expenseLd !== "Other Income", message: 'Contract Date' }]} >
                  <DatePicker style={{ width: "200px" }} onChange={calculateSystemCurrency} />
                </Form.Item>
              </Col>
            }
            {!isSameCurrency &&
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} offset={1}>

                <Form.Item label='Exchange Rate :'>
                  <b>{displayExchangeRate}</b>
                </Form.Item>
              </Col>
            }
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Total:" name="total" rules={[{ required: true, message: 'Please Enter Total ' }]}>
                <Input type="text" disabled ></Input>
              </Form.Item>
            </Col>
            <Col hidden >
              <Form.Item name="quoteCurrency"  >
                <Input type="text" hidden></Input>
              </Form.Item>
            </Col>
            <Col hidden >
              <Form.Item name="exchangeRate" initialValue={1}  >
                <Input type="text" hidden></Input>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item label="Remarks:" name="remarks" rules={[{ required: true, message: 'Please Enter Remarks' }]}>
                <Input type="text"></Input>
              </Form.Item>
              <Form.Item label="Remarks:" hidden name="expenseEntryTransactionsId" rules={[{ required: false, message: 'Please Enter Remarks' }]}>
                <Input type="text"></Input>
              </Form.Item>


            </Col>
            <Col span={1} style={{ marginTop: "25px", marginLeft: "5px" }}>
              <Button onClick={noticePaymentValues} type='primary' icon={<PlusSquareOutlined style={{ fontSize: '18px' }} />}></Button>
            </Col>
          </Row>

          {paymentNotice.length ? <Table
            rowKey={(row) => row.businessNo}
            size="small" bordered
            columns={tableColumns}
            dataSource={paymentNotice}
            pagination={false}
            summary={(pageData) => {
              let SubTotal = 0
              pageData.forEach(({ total }) => {
                SubTotal += Number(total);
              });

              return (
                <>
                  <Table.Summary.Row className='tableFooter'>
                    <Table.Summary.Cell index={2} colSpan={3}> </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={2} ><Text style={{ textAlign: 'center' }}><b>Sub Total</b>  </Text></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><Text style={{ textAlign: 'right' }}><b>{SubTotal.toFixed(2)}</b></Text></Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell> */}
                  </Table.Summary.Row>
                </>
              );
            }
            } ></Table> : <></>}
          <br />
        </Form>
        {/* <Divider type='horizontal' /> */}
        {/* <Col span={12}>
            <Form form={formRef}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              // layout="horizontal"
              style={{ maxWidth: 300, justifyContent: 'right' }} autoComplete='off'>
              <Form.Item label="Prepared by:" name="preparedBy" rules={[{ required: true, message: 'Please Enter PreparedBy' }]}>
                <Input disabled type="text"></Input>
              </Form.Item>
              <Form.Item name='worklog' label="Worklog" rules={[{ required: true, message: 'Please Enter WorkLog' }]} >
                <TextArea rows={2} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name='invoiceFile' label="Upload The Invoice" hidden={true}>
                <Upload>
                  <Button icon={<UploadOutlined />}>Choose File</Button>
                </Upload>
              </Form.Item>
            </Form>
          </Col> */}
        <Form form={formRef} autoComplete='off'>
          {/* <Row justify='space-between'>
                <Col style={{ float: 'right' }}>
                  <Form.Item name={'expenseEntryId'} style={{ display: 'none' }}><Input hidden /></Form.Item>
                  <Form.Item name='vat' label='Vat Applicable Y/N' valuePropName={'vatApplicable'}>
                    <Checkbox checked={vatApplicable} onChange={(e) => vatOnchange(e.target.checked)} />
                  </Form.Item>
                </Col>
                <Col>
                  <Text>{': ' + totalAmount?.vat ? getLocalFormat(totalAmount?.vat, expansiveLedger?.expenseLd === "Other Income" ? paymentNotice[0]?.currencyCode : authContext.defaultPlantCurrency) : 0}</Text>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col >
                  <Form.Item name={'tdsId'} label='TDS Applicable' rules={[{ required: false, message: 'Please Enter Tds' }]}>
                    <Select
                      onChange={tdsOnchange}
                      placeholder='Select TDS'
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      style={{ width: '200%' }}
                      filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                      <Option value={null}>Not Applicable</Option>
                      {tdsModel?.map((rec) => {
                        return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Text>{': ' + totalAmount?.tds ? getLocalFormat(totalAmount?.tds, expansiveLedger?.expenseLd === "Other Income" ? paymentNotice[0]?.currencyCode : authContext.defaultPlantCurrency) : 0}</Text></Col>
              </Row>
              <Row justify='space-between'>
                <Col>
                  <Form.Item name='total' label={<b>Total Amount</b>}>
                  </Form.Item>
                </Col>
                <Col>
                  <Text>{totalAmount.total ? getLocalFormat(totalAmount.total, expansiveLedger?.expenseLd === "Other Income" ? paymentNotice[0]?.currencyCode : authContext.defaultPlantCurrency) : 0}</Text>
                </Col>
              </Row> */}


          {/* <Button style={{ marginLeft: '100px' }} type="primary" onClick={onSubmitForApproval}>Submit for Approval</Button> */}
          {/* <Button style={{ marginLeft: '100px' }} type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => onSubmitForApproval()}>Submit for Approval</Button> */}
          {paymentNotice.length ?
            <Row>
              <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}>
                <Card
                  className='default-card-class'
                  title={'Advance Adjustment'}
                >
                  <BargeAdvanceAdjustments
                    bargeSelectionRowKeys={[]}
                    formRef={formRef}
                    bargeVendor={vendorLedger?.find((e) => e.actLedId === formRef.getFieldValue('vendorLedger'))?.bp_id}
                    totalInvoice={formRef.getFieldValue('total')}
                    setAdjustedData={setAdjustedData}
                    adjustedData={adjustedData}
                    setInvoiceContracts={setInvoiceContracts}
                    invoiceContracts={invoiceContracts}
                  />
                </Card>
              </Col>

              <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}>
                <Card
                  className='default-card-class'
                  title={'Price Adjustment'}
                >
                  {/* <Row>
                                <Col span={1}> </Col>
                                <Col span={16}>
                                    <Descriptions >
                                        <Descriptions.Item label={'Coal Supplier'}>{<span  ><b> {initialValues?.coalSupplier}</b></span>}</Descriptions.Item>
                                    </Descriptions>
                                </Col>

                            </Row>
                            <Row>
                                <Col span={1}> </Col>
                                <Col span={16}>
                                    <Descriptions >
                                        <Descriptions.Item label={'Barge Supplier'}>{<span  ><b> {initialValues?.bargeSupplier}</b></span>}</Descriptions.Item>
                                    </Descriptions>
                                </Col>

                            </Row> */}

                  <Row>
                    <Col span={5} offset={1}>
                      <b>Total</b>
                    </Col>
                    <Col span={14} offset={4}>
                      <Text>{getLocalFormat(totalAmount.subTotal, authContext.defaultPlantCurrency)}</Text>
                    </Col>

                  </Row>
                  <br />

                  <Row>
                    <Col span={7} offset={1}>
                      <Form.Item name='vat' label={<b>Vat Applicable(Y/N)&nbsp;&nbsp;</b>}>
                        <Checkbox checked={vatApplicable} onChange={(e) => vatOnchange(e.target.checked)} />
                      </Form.Item>
                    </Col>
                    <Col span={14} offset={2}>
                      <Text>{getLocalFormat(totalAmount?.vat, authContext.defaultPlantCurrency)}</Text>
                    </Col>

                  </Row>
                  <Row>
                    <Col span={7} offset={1}>
                      <Form.Item name={'tdsId'} label={<b>TDS&nbsp;&nbsp;</b>} rules={[{ required: false, message: 'Please Enter Tds' }]}>
                        <Select
                          onChange={tdsOnchange}
                          placeholder='Select TDS'
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} >
                          <Option value={null}>Not Applicable</Option>
                          {tdsModel?.map((rec) => {
                            return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                          })}
                        </Select>
                      </Form.Item>

                    </Col>
                    <Col span={14} offset={2}>
                      <Text>{getLocalFormat(totalAmount?.tds, authContext.defaultPlantCurrency)}</Text>
                    </Col>

                  </Row>
                  <br></br>

                  <Row>
                    <Col span={7} offset={1}>
                      <Form.Item label={'Amount'}></Form.Item>
                    </Col>
                    <Col span={14} offset={2}>
                      <Form.Item name={'totalAmount'}>
                        <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                        </InputNumber>
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row>
                    <Col span={7} offset={1}>
                      <Form.Item label={'Adjusted Against Advances'}></Form.Item>
                    </Col>
                    <Col span={14} offset={2}>
                      <Form.Item name={'totalAdjustedValue'}>
                        <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                        </InputNumber>
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row>
                    <Col span={7} offset={1}>
                      <Form.Item label={'To be paid to Supplier'}></Form.Item>
                    </Col>
                    <Col span={14} offset={2}>
                      <Form.Item name={'remainingBalance'}>
                        <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                        </InputNumber>
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row>

                    <Col span={7} offset={1}>
                      <Form.Item label={'Work Log'}></Form.Item>
                    </Col>
                    <Col span={14} offset={2}>
                      <Form.Item
                        name='worklog'
                        style={{ width: '100%' }}
                        rules={[
                          {
                            required: false,
                            message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                          },
                          // ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                          {
                            pattern: /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/~`-]+$/, // Add the regex pattern to accept special characters.
                            message: 'Invalid characters in Work Log',
                          },
                        ]}
                      >
                        <TextArea rows={2} style={{ width: '150%' }}
                        // onChange={() => workLogOnChange()} 
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='pr-2'>
                    <Col span={15}>
                      <Form.Item name='filesData' label="Upload The Invoice">
                        <Upload
                          multiple={false}
                          fileList={fileList}
                          listType='text'
                          maxCount={1}
                          customRequest={onFileChange}
                          onRemove={() => setFileList([])}
                          showUploadList>
                          <Button icon={<UploadOutlined />}>Choose File</Button>
                        </Upload>
                      </Form.Item>
                    </Col>

                  </Row>

                  <Row className='pt-2 pr-2' justify='space-between'>
                    <Col>
                    </Col>
                    <Col>
                      <Button style={{ marginLeft: '100px' }} type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => onSubmitForApproval()}>Submit for Approval</Button>
                    </Col>
                  </Row>

                </Card>

              </Col>

            </Row>
            : ''
          }
        </Form>

        <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '75%' : '85%'}
          onClose={closeRejectionDrawer}
          open={drawerVisible}
          closable={true}
        >
          <ExpenseEntryRejectedList
            key={dummyRefresh}
            type={props.type}
            setDummyRefresh={setDummyRefresh}
            setEditDataToForm={(values: any) => { setEditDataToForm(values) }}
            updateExpensive={updateExpensive}
          />
        </Drawer>
      </Card>
    </>

  )

}
export default PaymentNoticeExpensive