import { ContractTypesEnum, PurchaseContractDto, SurveyorDataDto, SurveyorEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../common';

interface ISurveyorProps {
    surveyorDetails: SurveyorDataDto[];
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
}
export const SurveyorDetailsView = (props: ISurveyorProps) => {
    const { surveyorDetails, contractType, snapShotData } = props;
    const [surveyorData, setSurveyorData] = useState([]);
    const [snapShotSurveyorData, setSnapShotSurveyorData] = useState([]);
    const { authContext } = useAuthState();


    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = snapShotSurveyorData?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }


    useEffect(() => {
        if (surveyorDetails?.length) {
            setSurveyorData(processSurveyorData(surveyorDetails));
        }
    }, [props.surveyorDetails]);

    useEffect(() => {
        if (props?.snapShotData?.length) {
            let snapQualityToBeSet = props?.snapShotData?.[0]?.surveyorDetails ? props.snapShotData[0].surveyorDetails : []
            setSnapShotSurveyorData(processSurveyorData(snapQualityToBeSet));
        }
    }, [props.snapShotData]);

    const surveyor: ColumnsType<any> = [
        {
            title: 'Surveyor Type',
            dataIndex: 'surveyorType',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => {
                return <span>
                    {
                        valiDateWithSnapShotData(index, 'surveyorType', value)
                    }
                </span>;
            }
        },
        {
            title: 'Surveyor Name',
            dataIndex: 'surveyorName',
            render: (value, row: any, index) => {
                return <span>
                    {
                        valiDateWithSnapShotData(index, 'surveyorName', value)
                    }
                </span>;
            }

        },
        {
            title: 'Surveyor Analysis Type',
            dataIndex: 'analysisType',
            render: (value, row: any, index) => {
                return <span>
                    {
                        valiDateWithSnapShotData(index, 'analysisType', value)
                    }
                </span>;
            }
        }
    ];

    const processSurveyorData = (data: SurveyorDataDto[]) => {
        const surveyorGridData = [];
        data?.forEach((surveyorTypeData, typeIndex) => {
            surveyorTypeData.surveyorAndAnalysis?.forEach((surveyorData, index) => {
                let gridObj: any = {};
                gridObj.surveyorName = surveyorData.surveyorName;
                gridObj.analysisType = surveyorData.analysisType;
                gridObj.surveyorType = surveyorTypeData.surveyorType === SurveyorEnum.INTERNAL ? authContext.defaultPlant : surveyorTypeData.surveyorType;
                gridObj.surveyorId = surveyorData.surveyorId ? surveyorData.surveyorId : `${typeIndex}-${index}`;
                gridObj.rowSpan = 0;
                if (index === 0) {
                    gridObj.rowSpan = surveyorTypeData.surveyorAndAnalysis.length;
                }
                surveyorGridData.push(gridObj);
            });
        })
        return surveyorGridData;
    }
    const rowClassName = (record, index) => {
        if (!snapShotSurveyorData?.[index]) {
            if (contractType === ContractTypesEnum.ADDENDUM) {
                return 'highlighted-row';
            } else {
                return '';
            }
        }
        return '';
    };
    
    return (
        <div><Table columns={surveyor} rowKey={(row) => row.surveyorId} dataSource={surveyorData} pagination={false} bordered rowClassName={rowClassName} /><br></br></div>
    )
}

export default SurveyorDetailsView