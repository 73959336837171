import { BargeCostingsEnum, BargeCostingsService, BgDeadFreightApproval, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerCategoryReqDto, BusinessPartnerTypeEnum, CompanyCodeReq, CompanyTdsService, ContractStatusEnum, ContractStatusReqDto, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, GetDataDeadFreightDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, TaxesAndItemsService, TdsResModel } from "@exportx/shared-models-and-services";
import { getLocalFormat } from "@exportx/ui-utils";
import { Button, Card, Checkbox, Col, Descriptions, Drawer, Form, Input, Radio, RadioChangeEvent, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from 'moment';
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import DatePicker from "../../../common/data-picker/date-picker";
import DeadFreightList from "./dead-freight-entry-list";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import {ApprovalTracking} from "../costing-detail-view";

interface IProps {
    deadFreightApproval?: boolean
    approvalInitialValues?: any;
    deadFreightStatus?: ContractStatusEnum;
    approvalLevel?: ContractStatusEnum;
    setDeadFreightForm?: (value) => void;
    accountType?: BusinessPartnerTypeEnum
}

export const DeadFreight = (props: IProps) => {
    const { authContext } = useAuthState()
    const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
    const [formRef] = Form.useForm()
    const businessNumberService = new BusinessNumberService();
    const currencyConverterService = new CurrencyConverterService();
    const bargeCostingService = new BargeCostingsService();
    const companyTdsService = new CompanyTdsService();
    const taxesAndItemsService = new TaxesAndItemsService();
    const [deadFreight, setDeadFreight] = useState<any>()
    const [gridData, setGridData] = useState<GetDataDeadFreightDto[]>();
    const [initialValues, setInitialValues] = useState<any>({ baseCurrency: authContext.defaultPlantCurrency });
    const [filter, setFilter] = useState<any>();
    const { Option } = Select;
    const { deadFreightApproval, approvalInitialValues, deadFreightStatus, setDeadFreightForm, accountType, approvalLevel } = props;
    const [supplier, setSupplier] = useState<any[]>()
    const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
    const defaultCurrency = authContext.defaultPlantCurrency;
    const [taxes, setTaxes] = useState<GetTaxesByBpIdDto[]>([]);
    const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
    const [dummyRefresh, setDummyRefresh] = useState<number>(1)
    const [account, setAccount] = useState<any>();
    const [currency, setCurrency] = useState<CurrencyDto[]>([]);
    const currencyService = new CurrencyService();
    const [exChangeDate, setExChangeDate] = useState<boolean>(false)
    const [gridDrawer, setGridDrawer] = useState<boolean>(false);

    let location = useLocation();
    let paramsId = queryString.parse(location.search).costingId;

    useEffect(() => {
        getBusinessNoDropDown();
        getDeadFreightData();
        getTdsData();
        getTaxesByItemsId();
        getCurrency();
    }, []);


    useEffect(() => {
        if (paramsId && gridData) {
            setInitialValues(gridData?.find((rec) => rec?.bgDeadFreightId === paramsId));
        };
    }, [gridData]);

    useEffect(() => {
        formRef.setFieldsValue(approvalInitialValues);
        pricePMT(approvalInitialValues?.pricePerMT);
        businessNoOnChange(approvalInitialValues?.bnId, undefined)
        tdsOnchange(approvalInitialValues?.companyTdsId)
        getTdsData();
        getTaxesByItemsId();
        supplierDropDown(approvalInitialValues?.bnId, approvalInitialValues?.account);
        exChangeDateHandler(approvalInitialValues?.exchangeDate);

        setFilter(approvalInitialValues);
        if (approvalInitialValues) {
            formRef.setFieldValue('pricePerMT', approvalInitialValues?.pricePerMT);
            formRef.setFieldValue('supplierId', approvalInitialValues?.supplierId);
            formRef.setFieldValue('baseCurrency', approvalInitialValues?.quoteCurrency);
            formRef.setFieldValue('deadFreightQuantity', approvalInitialValues?.deadFreight);
            formRef.setFieldValue('exchangeRate', approvalInitialValues?.exchangeRate)
            if (approvalInitialValues?.quoteCurrency) {
                currencyOnChangeHandler(approvalInitialValues?.quoteCurrency);
            }

        }
    }, []);

    useEffect(() => {
        formRef.setFieldsValue(initialValues);
        pricePMT(initialValues?.pricePerMT,);
        businessNoOnChange(initialValues?.bnId, undefined);
        tdsOnchange(initialValues?.companyTdsId);
        supplierDropDown(initialValues?.bnId, initialValues?.account);
        getDeadFreight(initialValues?.supplierId);
        exChangeDateHandler(initialValues?.exchangeDate);
        currencyOnChangeHandler(initialValues?.quoteCurrency);
        setFilter(initialValues);
        if (initialValues) {
            formRef.setFieldValue('pricePerMT', initialValues?.pricePerMT);
            formRef.setFieldValue('supplierId', initialValues?.supplierId);
            formRef.setFieldValue('deadFreightQuantity', initialValues?.deadFreight);
            formRef.setFieldValue('baseCurrency', initialValues?.baseCurrency);
            if (initialValues?.quoteCurrency) {
                formRef.setFieldValue('baseCurrency', initialValues?.quoteCurrency);
            }
            if (initialValues?.baseCurrency) {
                formRef.setFieldValue('quoteCurrency', initialValues?.baseCurrency);
            }

        }
    }, []);

    const getCurrency = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrency(res.data)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message))
    }


    if (initialValues?.deadFreightDate) {
        initialValues.deadFreightDate = moment(initialValues.deadFreightDate);
    };

    if (initialValues?.exchangeDate) {
        initialValues.exchangeDate = moment(initialValues.exchangeDate);
    }

    if (approvalInitialValues?.deadFreightDate) {
        approvalInitialValues.deadFreightDate = moment(approvalInitialValues?.deadFreightDate);
    }
    if (approvalInitialValues?.exchangeDate) {
        approvalInitialValues.exchangeDate = moment(approvalInitialValues.exchangeDate);
    }

    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessNumberService.dropDownForDeadFreight(req).then(res => {
            if (res.status) {
                setBusinessDropDown(res.data)
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const pricePMT = (value) => {
        setFilter((prev) => {
            const deadFreightBasePrice = Number(value) * Number(prev?.deadFreight)
            return {
                ...prev, deadFreightBasePrice
            }
        })
    };

    const deadFreightOnchangeHandler = (value: number) => {
        const pricePmt = formRef.getFieldValue('pricePerMT');
        setFilter((prev) => {
            const cal = Number(pricePmt) * Number(value) * Number(prev.exchangeRate ? prev.exchangeRate : 1)
            return {
                ...prev, deadFreight: value, deadFreightBasePrice: cal
            }
        });

    }

    const businessNoOnChange = (value, text) => {
        const filteredValue = businessDropDown?.filter((rec) => {
            return rec.bnId === value;
        })
        setFilter(filteredValue[0])
        formRef.setFieldValue('stowagePlanInMt', filteredValue[0]?.stowagePlanInMt);
        formRef.setFieldValue('vesselSailing', filteredValue[0]?.vesselSailing);
        supplierDropDown(value, account);
        formRef.setFieldValue('pricePerMT', undefined);
        formRef.setFieldValue('supplierId', undefined);
        if (!value) {
            formRef.setFieldValue('pricePerMT', undefined);
            formRef.setFieldValue('supplierId', undefined);
        }

    }

    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setAccount(value);
        supplierDropDown(formRef.getFieldValue('businessNo'), value);

        const resetFields = businessDropDown.filter((rec) => {
            return rec.bnId === formRef.getFieldValue('businessNo')
        })
        if (resetFields !== formRef.getFieldValue('businessNo')) {
            setFilter(undefined);
            setDeadFreight(undefined);
            formRef.setFieldValue('pricePerMT', undefined);
            formRef.setFieldValue('businessNo', undefined);
            formRef.setFieldValue('supplierId', undefined);
            formRef.setFieldValue('stowagePlanInMt', undefined);
            formRef.setFieldValue('vesselSailing', undefined);
            formRef.setFieldValue('deadFreightQuantity', undefined);
            formRef.setFieldValue('exchangeDate', undefined);
            setFilter(undefined);

        }
    };

    const supplierDropDown = (businessNo: string, type: BusinessPartnerTypeEnum) => {
        const req = new BusinessPartnerCategoryReqDto(businessNo, type, authContext.defaultPlant);
        businessNumberService.mvBpNames(req).then(res => {
            if (res.status) {
                setSupplier(res.data);
            } else {
                setSupplier([]);
            }
        }).catch(err => {
            console.log(err.message)
        })
    }





    const getTdsData = () => {
        const req = new CompanyCodeReq();
        req.company = authContext.defaultPlant;
        req.date = approvalInitialValues?.deadFreightDate ? approvalInitialValues?.deadFreightDate : formRef.getFieldValue('deadFreightDate');
        companyTdsService.getTdsByCompanyCode(req).then(res => {
            if (res.status) {
                setTdsModel(res.data);
            } else {
                setTdsModel([]);
            }
        }).catch(err => {
            console.log(err);
            setTdsModel([]);
        });
    }



    const getTaxesByItemsId = () => {
        const req = new ItemsIdRequestDto(ItemsCodesEnum.BARGE, approvalInitialValues?.deadFreightDate)
        taxesAndItemsService.getVatByItemsId(req).then(res => {
            if (res.status) {
                setVat(res.data);
            } else {
                setVat([]);
            }
        }).catch(err => {
            console.log(err);
            setVat([]);
        });
    }

    const vatOnchange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const taxId = vat[0]?.taxId ? vat[0]?.taxId : null;
            const taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
            setFilter(prevState => {
                const vatValue = (taxPercentage / 100) * prevState.deadFreightBasePrice;
                const tds = prevState.tds;
                return {
                    ...prevState,
                    taxId,
                    taxPercentage,
                    vatValue,
                    totalAmount: (prevState.deadFreightBasePrice + vatValue) - tds,
                    vat: e.target.checked
                }
            })
        } else {
            const taxId = null;
            const taxPercentage = 0;
            setFilter(prevState => {
                const totalAmount = (prevState.totalAmount - prevState.vatValue);
                return {
                    ...prevState,
                    taxId,
                    taxPercentage,
                    vatValue: 0,
                    totalAmount: totalAmount,
                    //totalAmount: prevState.totalAmount - prevState.vatValue,
                    vat: e.target.checked
                }
            })
        }

    }
    const tdsOnchange = (tdsId) => {
        const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
        if (tdsId) {
            setFilter(prevState => {
                const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
                const tds = (tdsPercentage / 100) * prevState.deadFreightBasePrice;
                const vatValue = prevState.vatValue;
                return {
                    ...prevState,
                    tdsId,
                    tdsPercentage,
                    tds,
                    totalAmount: (prevState?.deadFreightBasePrice + vatValue) - tds
                }
            })
        } else {
            setFilter(prevState => {
                const tdsPercentage = 0;
                const totalAmount = (prevState?.totalAmount + prevState?.tds);
                return {
                    ...prevState,
                    tdsId,
                    tdsPercentage,
                    tds: 0,
                    totalAmount: totalAmount
                }
            })
        }
    }
    const submit = () => {
        formRef.validateFields().then(values => {
            const req = { ...filter, pricePerMT: Number(formRef.getFieldValue('pricePerMT')), userName: authContext.user.userName, deadFreightDate: formRef.getFieldValue('deadFreightDate'), plantCode: authContext.defaultPlant, bgDeadFreightId: initialValues?.bgDeadFreightId, versionFlag: initialValues?.versionFlag, supplierId: formRef.getFieldValue('supplierId'), costingType: BargeCostingsEnum.DEAD_FREIGHT, stowagePlanInMt: formRef.getFieldValue('stowagePlanInMt'), vesselSailing: formRef.getFieldValue('vesselSailing'), account: formRef.getFieldValue('account'), baseCurrency: authContext.defaultPlantCurrency, exchangeDate: formRef.getFieldValue('exchangeDate'), quoteCurrency: formRef.getFieldValue('baseCurrency'), exchangeRate: filter.exchangeRate }
            bargeCostingService.createBgDeadFreight(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setDummyRefresh(prev => prev + 1);
                    getDeadFreightData();
                    formRef.resetFields();
                    setFilter(undefined);
                    setDeadFreight(undefined);
                    formRef.setFieldValue('pricePerMT', undefined);
                    formRef.setFieldValue('deadFreightDate', undefined);
                    formRef.setFieldValue('businessNo', undefined);
                    formRef.setFieldValue('supplierId', undefined);
                    formRef.setFieldValue('vesselSailing', undefined);
                    initialValues.deadFreightBasePrice = undefined;
                    formRef.setFieldsValue(undefined);
                    initialValues.bgDeadFreightId = undefined;
                    pricePMT(Number(formRef.getFieldValue('pricePerMT')));
                    businessNoOnChange(undefined, undefined);
                    tdsOnchange(undefined);
                    setFilter(undefined);
                    setExChangeDate(false);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        })

    }

    const getDeadFreightData = () => {
        const req = new ContractStatusReqDto(deadFreightStatus, authContext.defaultPlant)
        bargeCostingService.getDeadFreightData(req).then(res => {
            if (res.status) {
                setGridData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }


    let status = ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    if (approvalLevel === ContractStatusEnum?.BUSINESS_APPROVAL) {
        status = ContractStatusEnum.PENDING_AUDIT_APPROVAL
    }
    else if (approvalLevel === ContractStatusEnum?.AUDIT_APPROVAL) {
        status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL
    } else status = ContractStatusEnum.ACTIVE
    const approve = () => {
        formRef.validateFields().then(values => {
            const req = new BgDeadFreightApproval(approvalInitialValues?.bgDeadFreightId, authContext.user.userName, authContext.defaultPlant, values.account, values.vat, values.companyTdsId, filter.totalAmount, filter.tds, filter.vatValue, filter.taxId, approvalInitialValues?.bnId, approvalInitialValues?.supplierId, status, approvalLevel)
            // if (deadFreightStatus === ContractStatusEnum.ACTIVE) {
            bargeCostingService.deadFreightApproval2(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    getDeadFreightData();
                    setDeadFreightForm(false);
                    setExChangeDate(false);
                    window.location.reload();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);

                }
            }).catch(err => {
                console.log(err.message)
            })
            // }
            //  else {
            //     bargeCostingService.deadFreightApproval(req).then(res => {
            //         if (res.status) {
            //             AlertMessages.getSuccessMessage(res.internalMessage);
            //             getDeadFreightData();
            //             setDeadFreightForm(false);
            //             window.location.reload();
            //         } else {
            //             AlertMessages.getErrorMessage(res.internalMessage);
            //         }
            //     }).catch(err => {
            //         console.log(err.message)
            //     })
            // }

        })

    }

    const reject = () => {
        formRef.validateFields().then(values => {
            const req = new BgDeadFreightApproval(approvalInitialValues?.bgDeadFreightId, authContext.user.userName, authContext.defaultPlant, undefined, undefined, undefined, undefined, undefined, undefined, undefined, approvalInitialValues?.bnId, approvalInitialValues?.supplierId)
            // if (deadFreightStatus === ContractStatusEnum.ACTIVE) {
            bargeCostingService.deadFreightRejected2(req)
                .then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getDeadFreightData();
                        setDeadFreightForm(false)

                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
            // } else {
            //     bargeCostingService.deadFreightRejected(req)
            //         .then(res => {
            //             if (res.status) {
            //                 AlertMessages.getSuccessMessage(res.internalMessage);
            //                 getDeadFreightData();
            //                 setDeadFreightForm(false)
            //             } else {
            //                 AlertMessages.getErrorMessage(res.internalMessage)
            //             }
            //         }).catch(err => {
            //             console.log(err.message)
            //         })
            // }

        }).catch(err => {
            console.log(err.message)
        })

    }


    const getDeadFreight = (value) => {
        const quantity = supplier?.filter((rec) => {
            return rec.supplierId === value
        })[0];
        formRef.setFieldValue('deadFreightQuantity', quantity?.quantity);
        setFilter((prev) => { return { ...prev, deadFreight: quantity?.quantity } })
    }


    const getCurrencyExchangeRate = (fromCurrency, toCurrency, date) => {
        const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'))
        if(moment(req.exchangeDate).format('YYYY-MM-DD') ==='Invalid date'){
            return;
        }
        currencyConverterService.getCurrencyExchangeRate(req).then(res => {
            if (res.status) {
                setFilter((prev) => {
                    const deadFreightBasePrice = Number(formRef.getFieldValue('pricePerMT')) * Number(prev?.deadFreight) * res.data.exchangeRate
                    return {
                        ...prev, deadFreightBasePrice, exchangeRate: res.data.exchangeRate, displayExchangeRate: res.data.displayExchangeRate
                    }
                })
            } else {
                setFilter((prev) => {
                    const deadFreightBasePrice = Number(formRef.getFieldValue('pricePerMT')) * Number(prev?.deadFreight);
                    delete prev?.displayExchangeRate;
                    return {
                        ...prev, deadFreightBasePrice
                    };
                });

            }
        }).catch(err => { console.log(err.message) });
    }


    const currencyOnChangeHandler = (value) => {
        if (value) {
            setExChangeDate(value !== authContext.defaultPlantCurrency);
            getCurrencyExchangeRate(value, authContext.defaultPlantCurrency, formRef.getFieldValue('exchangeDate'))
        } else {
            setExChangeDate(false);
        }
    };

    const exChangeDateHandler = (value) => {
        getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, value)
    }


    const suffixSelector = (
        <Form.Item
            name="baseCurrency"
            noStyle
        >
            <Select
                onChange={currencyOnChangeHandler}
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                style={{ width: 80 }}

            >
                {currency?.map((rec) => {
                    return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );

    return (
        <Card title={<span style={{ color: 'white' }}>Dead Freight Entry for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
            extra={<Button
                onClick={() => { setGridDrawer(true); setDummyRefresh(dummyRefresh + 1); }}
                className='panel_button'
            >View</Button>

            }>
            <Row>
                <Col span={11}>
                    <Form
                        key={dummyRefresh}
                        form={formRef}
                        initialValues={initialValues}
                        autoComplete={"off"}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        style={{ maxWidth: 600 }}
                    >
                        <Card>
                            <Row>
                                <Col span={8}></Col>
                                <Col span={8}></Col>
                                {!deadFreightStatus &&
                                    <Col span={8}>

                                        <Form.Item
                                            name={'deadFreightDate'}
                                            label="Date"
                                            initialValue={moment()}
                                        >
                                            <DatePicker defaultValue={moment()} />
                                        </Form.Item>
                                    </Col>

                                }

                            </Row>
                            <Card className="default-card-class" title={<span style={{ color: 'black' }}>MV Dead Freight / Dispatch</span>} style={{ textAlign: 'center' }} >



                                <Form.Item name='account' initialValue={initialValues?.account} rules={[{ required: deadFreightStatus ? false : true, message: "Account Type is Required" }]} >
                                    {/* <Radio.Group onChange={radioOnChange}> */}
                                    <Radio.Group disabled={approvalInitialValues?.account ? true : false} onChange={radioOnChange} >
                                        <Radio value={`${BusinessPartnerTypeEnum.VENDOR}`}>Against Seller</Radio>
                                        <Radio value={`${BusinessPartnerTypeEnum.CUSTOMER}`}>Against Buyer</Radio>
                                    </Radio.Group>

                                </Form.Item>

                            </Card>
                            <br></br>
                            <Form.Item
                                label={`${deadFreightStatus ? "Business Number:" : "Select MV:"}`}
                                name="businessNo"
                                rules={[{ required: deadFreightStatus ? false : true, message: "Business Number is Required" }]}
                            >
                                {deadFreightStatus ? <span > <b>{approvalInitialValues?.businessNo}</b></span> :
                                    <Select
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        allowClear
                                        // style={{ width: '100%', paddingLeft: 30 }}
                                        placeholder='Select Business No'
                                        onChange={(value, text) => businessNoOnChange(value, text)}
                                        disabled={formRef.getFieldValue('account') ? false : true}
                                        showSearch
                                    >
                                        {/* <Option value={'ALL'}>All</Option> */}
                                        {businessDropDown.map(item => {
                                            return <Option value={item.bnId}>{item.motherVesselName + " " + " " + `(${item.businessNo})`}</Option>
                                        })}
                                    </Select>

                                }
                            </Form.Item>

                            <Form.Item
                                label={`${deadFreightStatus ? "Supplier:" : `Select ${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''}:`}`}
                                name="supplierId"
                                rules={[{ required: deadFreightStatus ? false : true, message: `${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''} is Required` }]}
                            >
                                {deadFreightStatus ? <span > <b>{approvalInitialValues?.supplierName}</b></span> :
                                    <Select
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        allowClear
                                        style={{ width: '100%', }}
                                        placeholder='Select Supplier No'
                                        onChange={getDeadFreight}
                                        disabled={formRef.getFieldValue('businessNo') ? false : true}
                                    >
                                        {/* <Option value={'ALL'}>All</Option> */}
                                        {supplier?.map(item => {
                                            return <Option value={item.supplierId}>{item.supplierName}</Option>
                                        })}
                                    </Select>

                                }
                            </Form.Item>


                            <Form.Item
                                name={"stowagePlanInMt"}
                                label={' MV Stowage plan'}
                            >
                                <Input
                                    placeholder="MV Capacity"
                                    disabled={true}
                                    type={'number'}
                                ></Input>
                            </Form.Item>
                            <Form.Item
                                name={'deadFreightQuantity'}
                                label={<b>Dead Freight</b>}
                            >
                                <Input
                                    addonAfter={"MT"}
                                    type="number"
                                    onChange={(e) => {
                                        deadFreightOnchangeHandler(Number(e.target.value))
                                    }}
                                ></Input>
                            </Form.Item>
                            {deadFreightStatus ?
                                <Form.Item
                                    label="Price per MT">
                                    <span >
                                        <b>{getLocalFormat(approvalInitialValues?.pricePerMT, defaultCurrency)}</b>
                                    </span>
                                </Form.Item>
                                : <Form.Item
                                    label={<b>Price per MT</b>}
                                    name="pricePerMT"
                                >
                                    <Input addonAfter={suffixSelector} type="number" onChange={(e) => pricePMT(e.target.value)}></Input>
                                </Form.Item>}

                            {exChangeDate ?
                                <>
                                    <Form.Item name={'exchangeDate'} label={'Exchange Date'}>
                                        <DatePicker onChange={exChangeDateHandler} />
                                    </Form.Item>
                                    <Form.Item name={'exchangeRate'} label={'Exchange Rate'}>
                                        <span>
                                            {filter?.displayExchangeRate}
                                        </span>
                                    </Form.Item>

                                </>
                                :
                                <></>}

                            <Form.Item
                                label={<b>Dead Freight Base Price</b>}
                            > <span  >
                                    <b>{getLocalFormat(filter?.deadFreightBasePrice ?
                                        filter?.deadFreightBasePrice :
                                        approvalInitialValues?.deadFreightBasePrice ?
                                            approvalInitialValues?.deadFreightBasePrice :
                                            initialValues?.deadFreightBasePrice, defaultCurrency)}
                                    </b>
                                </span>
                            </Form.Item>

                            {deadFreightStatus === ContractStatusEnum.ACTIVE ? <>


                                <Form.Item name={"vat"} valuePropName="checked" label={"VAT"}>
                                    <Checkbox
                                        onChange={vatOnchange}
                                    >Vat Applicable(Y/N)</Checkbox>
                                </Form.Item >

                                <Form.Item label={'VAT Value'}>
                                    <b>{getLocalFormat(filter?.vatValue, defaultCurrency)}</b>
                                </Form.Item>

                                <Form.Item name='companyTdsId' label={<b>TDS</b>}>
                                    <Select onChange={tdsOnchange}
                                        placeholder='Select TDS'
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    >
                                        <Option value={null}>Not Applicable</Option>
                                        {tdsModel?.map((rec) => {
                                            return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item label={' '} >
                                    <b>{getLocalFormat(filter?.tds, defaultCurrency)}</b>
                                </Form.Item>

                                {/* <Form.Item label={<b>Total Amount</b>}>{<span  ><b>{getLocalFormat(filter?.totalAmount, defaultCurrency)}</b></span>}</Form.Item> */}
                                <Form.Item label={<b>Total Amount</b>}>
                                    <span>
                                        <b>
                                            {getLocalFormat(filter?.vat ? filter?.totalAmount : filter?.deadFreightBasePrice, defaultCurrency)}
                                        </b>
                                    </span>
                                </Form.Item>
                            </>
                                : <></>
                            }

                            <b style={{ display: `${deadFreightApproval ? "none" : ""}` }}>{initialValues?.deadFreightEntryDate}</b>
                            <span style={{ paddingLeft: 300, display: `${deadFreightApproval ? "none" : ""}` }}>
                                {/* <Button type="primary" onClick={submit}>Submit</Button> */}
                                <Button type='primary'
                                    disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false}
                                    onClick={submit}
                                >
                                    Submit
                                </Button>
                            </span>

                            {deadFreightApproval &&
                                <ApprovalTracking history={approvalInitialValues?.workLog} />

                            }

                            <span style={{ paddingLeft: 250, display: `${deadFreightApproval ? "" : "none"}` }}>
                                <Button type="primary"
                                    onClick={approve}
                                >Approve
                                </Button>
                            </span>
                            <span style={{ paddingLeft: 10, display: `${deadFreightApproval ? "" : "none"}` }}>
                                <Button
                                    type="primary"
                                    disabled={approvalInitialValues?.approvalStatus2 === ContractStatusEnum.CANCELLED ? true : false}
                                    danger onClick={reject}>
                                    Reject
                                </Button>
                            </span>

                        </Card>
                    </Form>
                </Col>
            </Row>
            <Drawer
                open={gridDrawer}
                onClose={() => setGridDrawer(false)}
                key={dummyRefresh}
                width={'50%'}

            >
                <DeadFreightList
                    key={dummyRefresh}
                    setDummyRefresh={setDummyRefresh}
                    gridData={gridData}
                    getDeadFreightData={getDeadFreightData}
                    setInitialValues={setInitialValues}
                    deadFreightApproval={deadFreightApproval}
                />
            </Drawer>
        </Card >
    )

}
export default DeadFreight