import { CurrencyCreateDto, CurrencyService, CurrencyUpdateDto } from "@exportx/shared-models-and-services";
import { ClassValidator } from "@exportx/ui-utils";
import { Button, Card, Col, Form, Row, Space } from "antd";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, GenericFormWithOutPopUP, InputTypes, useAuthState } from "../../../../../common"

interface ICurrencyFormProps {

}
export const CurrencyForm = (props: ICurrencyFormProps) => {
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const { state }: any = useLocation();
  const { isUpdate, currencyData, isNewRecord } = state ? state : { isUpdate: false, currencyData: null, isNewRecord: true };
  const [formRef] = Form.useForm();
  const classValidator = new ClassValidator();
  const service = new CurrencyService();
  let pathToreDirect = '/currency';

  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }
  const clearData = () => {
    formRef.resetFields();
  }


  const formItems = [
    {
      name: 'currencyName',
      label: fm({ id: "mdm.components.currencyForm.currencyName", defaultMessage: 'Currency Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.currencyForm.currencyNameReq", defaultMessage: 'Enter Currency Name' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.currencyForm.currencyNameReq`, defaultMessage: "Enter Currency Name" }) },

        ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(40, fm({ id: "mdm.components.currencyForm.currency Name", defaultMessage: 'Currency Name' }))
      ]
    },

    {
      name: 'currencyCode',
      label: fm({ id: "mdm.components.currencyForm.currencyCode", defaultMessage: 'Currency Code' }),
      fieldType: {
        type: InputTypes.Input,
        placeHolder: fm({ id: "mdm.components.currencyForm.currencyCodeReq", defaultMessage: 'Enter Currency Code' }),
        otherProps: {
          disabled: currencyData ? true : false
        }
      },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.currencyForm.currencyCodeReq`, defaultMessage: "Enter Currency Code" }) },

        ...classValidator.alphaNumericWithOutSpecialChars(),
        ...classValidator.lengthValidation(3, fm({ id: "mdm.components.currencyForm.currency Code", defaultMessage: 'Currency Code' }))
      ]
    },
    {
      name: 'currencySymbol',
      label: fm({ id: "mdm.components.currencyForm.currencysymbol", defaultMessage: 'Currency Symbol' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.currencyForm.currencysymbolReq", defaultMessage: 'Enter Currency Symbol' }) },
      validationRules: [
        {
          required: true, message: fm({ id: `mdm.components.currencyForm.currencySymbolReq`, defaultMessage: "Enter Currency Symbol" })
        },
        ...classValidator.lengthValidation(4, fm({ id: "mdm.components.currencyForm.currency Symbol", defaultMessage: 'Currency Symbol' }))
      ]
    }


  ]

  console.log(currencyData)

  const update = (values: any) => {
    const updateDto: CurrencyUpdateDto = new CurrencyUpdateDto(currencyData.currencyId, values.currencyName, values.currencyCode, values.currencySymbol, authContext.user.userName, currencyData.versionFlag);
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Currency Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const submitForm = (values: any) => {
    if (isUpdate && currencyData?.currencyId) {
      update(values);
      return;
    }
    const createDto: CurrencyCreateDto = new CurrencyCreateDto(values.currencyName, values.currencyCode, values.currencySymbol, authContext.user.userName);
    service.create(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Currency Created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (

    <Card bodyStyle={{ overflow: 'auto' }} title={fm({ id: 'mdm.components.currencyForm.header', defaultMessage: 'Currency Details' })}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect)}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >


      <GenericFormWithOutPopUP
        title={fm({ id: "mdm.components.currencyForm.title", defaultMessage: 'CURRENCY' })}
        formItems={formItems}
        formRef={formRef}
        initialValues={currencyData}
        isUpdate={isUpdate}
        isNewRecord={isNewRecord}
        closeForm={() => { }}
        submitForm={() => { }}
        clearData={clearData} >
        <Row justify="end">
          <Col>
            {
              (isNewRecord || isUpdate) && <Space>
                <Button
                  onClick={() => { clearData(); }}
                >
                  {fm({ id: "common.clear", defaultMessage: "Clear" })}
                </Button>
                <Button onClick={submitFormHandler} type="primary">
                  Submit
                </Button>
              </Space>
            }</Col>
        </Row>

      </GenericFormWithOutPopUP>   </Card>





  )
}

export default CurrencyForm;