import { Card, Col, Form, FormInstance, Input, Row, Select, Tooltip } from 'antd';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { FormLayout } from 'antd/lib/form/Form';
import { VesselTypesEnum } from '@exportx/shared-models-and-services';
import { useIntl } from 'react-intl';
import { useState } from 'react';

const { Option } = Select;


interface IShippingTermsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}
export const ShippingTerms = (props: IShippingTermsProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { formatMessage: fm } = useIntl();
  const [vesselType, setVesselType] = useState(initialValues?.vesselType);

  const vesselTypeHandler = () => {
    const vessel = formRef.getFieldValue('vesselType');
    setVesselType(vessel);
  }
  return (
    <Form
      layout={layoutType}
      form={formRef}
      initialValues={initialValues}
      autoComplete="off"
    >

      <Card title="Loading Terms" >
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Vessel Type " name="vesselType"
              rules={[{ required: true, message: 'Please input your Vessel Type' }]}
            >
              <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: "100%" }} onSelect={vesselTypeHandler}>
                <Option value={''}>Please Select</Option>
                {Object.keys(VesselTypesEnum).map(vessel => {
                  return <Option value={VesselTypesEnum[vessel]}>{VesselTypesEnum[vessel]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col >
          {/* <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Port of Loading" name="portOfLoading">
              <Input placeholder='PORT OF LOADING' />
            </Form.Item>
          </Col> */}
          {(VesselTypesEnum.GEARED_AND_GRABBED === vesselType || VesselTypesEnum.NA === vesselType) && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Loading Rate for Geared" name="loadingRateForGeared" style={{ width: "100%" }}
              rules={[{ required: true, message: 'Please Enter LoadingRateForGeared' }]}>
              <Input placeholder="LOADING RATE FOR GEARED" /></Form.Item>
          </Col>}
          {(VesselTypesEnum.GEARLESS === vesselType || VesselTypesEnum.NA === vesselType) && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Loading Rate for Gearless " name="loadingRateForGearless" style={{ width: "100%" }}
              rules={[{ required: true, message: 'Please Enter LoadingRateForGearless' }]}>
              <Input placeholder='LOADING RATE FOR GEARLESS' />
            </Form.Item>
          </Col>}
        </Row>
      </Card>
      <Card title="Shipping Terms">

        <Form.List name="shippingTerms">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}>
                    <Form.Item
                      {...restField}
                      name={[name, 'terms']}
                    // label={fm({ id: 'mdm.components.paymentTermForm.terms', defaultMessage: "Terms" })}

                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item></Col>
                  <Col xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}>
                    {index !== 0 &&
                      <span style={{
                        position: "absolute",
                        marginTop: "33px",
                        marginLeft: "35px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                        </Tooltip>
                      </span>
                    }
                    {(fields.length - 1 === index) && <span style={{
                      position: "absolute",
                      marginTop: "33px",
                      marginLeft: "15px"
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined onClick={() => add()} />
                      </Tooltip>
                    </span>}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </Card>
      {props?.children}
    </Form>
  )
}

export default ShippingTerms;