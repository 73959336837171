import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { BGCostingParentReqDto, BargeCostingsEnum, ContractStatusEnum, CostingTypesEnum, CostingWorkLogsEnum, PaymentNoticeGetDataDto, PaymentNoticeService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { SequenceUtils, getLocalFormat } from '@exportx/ui-utils';
import { Card, Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../common';
import PaymentNoticeChild from './payment-notice-child';

interface IPaymentNoticePage {
    type: PaymentNoticePageEnum;
    paymentType?: PaymentNoticePageEnum;
}

export enum PaymentNoticePageEnum {
    APPROVAL = 'approval',
    CREATE = 'create',
    RELEASE = 'Release',
    RECEIVABLES = 'Receivables',
    CLOSED = 'Closed'
}

export const PaymentNoticePage = (props: IPaymentNoticePage) => {
    const { type, paymentType } = props;
    const [expandedIndex, setExpandedIndex] = useState([]);
    const paymentNoticeService = new PaymentNoticeService();
    const { authContext } = useAuthState();
    const [paymentNoticeData, setPaymentNoticeData] = useState<PaymentNoticeGetDataDto[]>([]);
    const [searchedText, setSearchedText] = useState("");

    useEffect(() => {
        getParentData();
    }, [])



    const getParentData = () => {
        const req = new BGCostingParentReqDto();
        req.plantCode = authContext.defaultPlant;
        let status = ContractStatusEnum.ACTIVE;
        if (type === PaymentNoticePageEnum.CREATE) {
            status = ContractStatusEnum.DRAFT;
        } else if (type === PaymentNoticePageEnum.APPROVAL) {
            status = ContractStatusEnum.PENDING_APPROVAL;
        } else if (type === 'Closed') {
            status = ContractStatusEnum.CLOSED;

        }
        req.status = status
        if (paymentType) {
            req.paymentType = paymentType;
        };
        paymentNoticeService.getPaymentNotice(req).then(res => {
            if (res.status) {
                setPaymentNoticeData(res.data ? res.data : []);
                setExpandedIndex([]);
            }
        }).catch(err => {
            console.log(err.message);
        })
    };

    const routes = (key) => {
        let object = {
            [ReferenceFeatures.PERFORMA_PC_COSTING]: `performaCoalCosting`,
            [ReferenceFeatures.PERFORMA_SC_COSTING]: `performaCostingSale`,
            [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
            [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
            [CostingTypesEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
            [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
            [BargeCostingsEnum.DEMURRAGE]: `demurrage`,
            [BargeCostingsEnum.DEAD_FREIGHT]: `dead-freight`,
            [ReferenceFeatures.ADVANCE_INVOICE]: `advanceDetailView`,
            [ReferenceFeatures.EXPENSE_ENTRY]: 'expense-entry',
            [ReferenceFeatures.ADVANCE_RECEIVABLES]: 'advance-receivables-detailed-view',
            [ReferenceFeatures.BARGE_COSTINGS]: `barge-costings/barge`,
            [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costings/barge`,
        };

        let id = "?costingId=";

        if (key === ReferenceFeatures.ADVANCE_INVOICE) {
            id = '?id=';
        } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
            id = '?id=';
        }

        return object[key] + id;


    };



    const tableColumns: ColumnsType<any> = [
        {
            title: 'Exp Ref',
            dataIndex: 'costingNo',
            render: (value, record) => {
                return <>
                    < a href={`/#/${routes(record.expenseType) + record.costingId}`} className="link-primary" >
                        {value}
                    </a >
                </>
            }

        },
        {
            title: 'Expense Type',
            dataIndex: 'expenseType',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },

        },
        {
            title: 'Vendor',
            dataIndex: 'bpName',
        },
        {
            title: 'Business Number',
            dataIndex: 'businessNo',
            render: (value, row: PaymentNoticeGetDataDto, index) => {
                const bnValues = [...new Map(row.paymentNoticeTransactions.map((rec) => [rec.businessNo, rec])).values()]
                return (
                    <>
                        {new Set(row.paymentNoticeTransactions.filter(rec => (rec.businessNo !== null || rec.businessNo != undefined)
                        )).size ? bnValues.map((rec, index) => {
                            const comma = index !== bnValues.length - 1 ? ' , ' : '';
                            const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`
                            return <>
                                {<a href={link}>{rec.businessNo + ` (${rec.businessNoName})` + comma}</a>}
                            </>
                        }) : 'NA'}

                    </>
                );
            }
        },
        {
            title: 'Barge',
            dataIndex: 'bargeNo',
            render: (value, row: PaymentNoticeGetDataDto, index) => {
                const bg = [];
                row.paymentNoticeTransactions?.forEach((rec) => {
                    if (rec.bargeNo)
                        bg.push({ bargeNo: rec.bargeNo, bargeId: rec.bargeId, bargeNomination: rec.bargeNomination })
                });
                return (
                    <>
                        {new Set(row.paymentNoticeTransactions.filter(rec => (rec.bargeNo !== null || rec.bargeNo !== undefined)
                        )).size ? bg.map((rec, index) => {
                            const comma = index !== bg.length - 1 ? ' , ' : '';
                            const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
                            return <a href={`${link}`}>{SequenceUtils.formatNumberToSpecificLength(rec?.bargeNo) + ` (${rec.bargeNomination})` + comma}</a>
                        }) : 'NA'}
                    </>
                );
            }
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            align: 'right',
            render: (value, record) => {
                return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
            }
        }
    ];

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.costingId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    };
    const renderItems = (record: PaymentNoticeGetDataDto, index, indent, expanded) => {
        return <>
            <PaymentNoticeChild
                paymentType={paymentType}
                record={record}
                type={type}
                getParentData={getParentData}
            />
        </>
    };

    return (
        <Card title={<span style={{ color: 'white' }}>Payment Notice {props.type} </span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />
            <Table
                expandIconColumnIndex={0}
                className='contracts'
                rowKey={record => record.costingId}
                columns={tableColumns}
                dataSource={paymentNoticeData}
                expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                }}
                expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpCircleFilled
                            onClick={(e) => onExpand(record, e)}
                        >
                            Collapse
                        </UpCircleFilled>
                    ) : (
                        <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                    )
                }
                scroll={{ x: 500 }}
                size="large"
                bordered
            />

        </Card>
    )
}

export default PaymentNoticePage