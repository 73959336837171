import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const CostingDetails = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    costing_details: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          costing_details: features?.filter((cou) => 766 <= +cou && +cou <= 771)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])
  return (
    <Form
      name="validate_other"
      layout='vertical'
      initialValues={values}
      form={formRef}
    >
      <Card size="small" title="Budget & Costing">
        <Form.Item name="costing_details" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              <Col span={6}>
                <Checkbox
                  value='1,2,3,4'
                  disabled

                >
                  Select All
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={766} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={767} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={768} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={769} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={776} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={777} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}