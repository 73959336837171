import { ContractIdRequest, ContractModesEnum, ContractTermEnum, ContractTypesEnum, GetTaxesByBpIdDto, IndexedDataDto, PurchaseContractDto, PurchaseContractService, PurchaseDetailsDto, PurchaseTermDataDto, QualityPriceDataDto, SalesContractService, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Drawer, Empty, Modal, Table, Tabs, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, isPermissionExist } from '../../../common';
import { CoalIndexView } from '../../masters/components/coal-index-prices';
import { SpecsViewPage } from './specs/specs-view-page';

interface IQualityDetailedView {
    qualityData?: QualityPriceDataDto[];
    pcId?: string;
    classes?: string;
    type: ContractModesEnum;
    plantCode: string;
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
    vat: GetTaxesByBpIdDto[]
}
class IQualityGrid {
    typeId: string;
    quality: string;
    quantity: string;
    tolerance: number;
    mineName: string;
    specStandard: SpecTypesEnum;
    noPriceAdjustment: boolean;
    layCan: string;
    purchasingTerm: string;
    rowSpan: number;
    anchorageName: string;
    fasAnchorage: string;
    jettyName: string;
    isIndexLinked: boolean;
    fcCharges: number;
    freightPricePMT: number;
    vatAmount: number;
    coalPricePMT: number;
    dischargePort: string;
    purchaseType: string;
    purchaseData: PurchaseDetailsDto;
    exportDocumentation: string;
    businessNumber: string;
    vat: number;
    purchaseTermData: PurchaseTermDataDto;
    qualityAdjustments: string
}
const { TabPane } = Tabs;
export const QualityDetailView = (props: IQualityDetailedView) => {
    const { snapShotData, contractType, vat } = props;
    const { formatMessage: fm } = useIntl();
    const [qualityData, setQualityData] = useState([]);
    const [snapQuality, setSnapQuality] = useState([]);
    const [qualities, setQualities] = useState([]);
    const [enableSpecData, setEnableSpecData] = useState<boolean>(false);
    const [activeQualityData, setActiveQualityData] = useState<IQualityGrid>();
    const [activeSnapQualityData, setActiveSnapQualityData] = useState<IQualityGrid>();
    const [enableQualityAdjustments, setEnableQualityAdjustments] = useState<boolean>(false);
    const [activePurchaseId, setActivePurchaseId] = useState(null);
    const [activeIndexedFormData, setActiveIndexedFormData] = useState<IndexedDataDto>(null);
    const [activeSpecType, setActiveSpecType] = useState(SpecTypesEnum.ASTM);
    const service = props.type === ContractModesEnum.SALES ? new SalesContractService() : new PurchaseContractService();

    useEffect(() => {
        
        const snap_qualities = JSON.parse(JSON.stringify(snapQuality));
        const addendum_qualites = JSON.parse(JSON.stringify(qualityData));
        const new_qualities = []
        if (contractType === ContractTypesEnum.ADDENDUM) {

        const removed_records = snap_qualities?.filter(({ qualitySeq: id1 }) => !addendum_qualites.some(({ qualitySeq: id2 }) => id2 === id1));
        console.log('removed_records', removed_records)
        for (const obj of removed_records) {
            obj.color = "#f65c8d1f";
            new_qualities.push(obj);
        }
    }

        // const common_records = snap_qualities.filter(item => addendum_qualites.filter(e => JSON.stringify(item.qualitySeq) === JSON.stringify(e.qualitySeq)));
        // console.log('common_records', common_records)
        // for (const el1 of common_records) {
        //     new_qualities.push(el1);
        // }

        // const new_records = addendum_qualites.filter(({ qualitySeq: id1 }) => !snap_qualities.some(({ qualitySeq: id2 }) => id2 === id1));
        // console.log('new_records', new_records)

        // for (const el of new_records) {
        //     el.color = '#41c1971f';
        //     new_qualities.push(el);
        // }
        setQualities([...new_qualities, ...addendum_qualites])

    }, [snapQuality, qualityData])
    const valiDateWithSnapShotData = (qualitySeq: string, key: string, value: string): any => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey === value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    const CheckTypesDiffHandler = (qualitySeq, typeSeq, key: string, value: string) => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)?.purchaseTypeData?.find((type) => type.typeSeq === typeSeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey === value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'

    }


    useEffect(() => {
        if (props?.pcId)
            getQualityDetailsByPcId(props.pcId, props.plantCode);
    }, []);


    const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD MMM YYYY')} - ${moment(value[1]).format('DD MMM YYYY')}` : `${moment(value?.split(',')[0]).format('DD MMM YYYY')} - ${moment(value?.split(',')[1]).format('DD MMM YYYY')}` || ''

    useEffect(() => {
        if (props?.snapShotData?.length) {
            let snapQualityToBeSet = props.snapShotData?.[0].qualityDetails?.qualityData ? props.snapShotData[0].qualityDetails.qualityData : []
            setSnapQuality(snapQualityToBeSet?.map((item) => ({ ...item, layCan: laycanDateFormate(item?.layCan) })));
        };
    }, [props.snapShotData]);


    useEffect(() => {
        if (props?.qualityData)
            setQualityData(props.qualityData?.map((item) => ({ ...item, layCan: laycanDateFormate(item.layCan) })));
    }, [props.qualityData]);

    const getQualityDetailsByPcId = (contractId: string, plantCode: string) => {
        const req = { ...new ContractIdRequest(contractId), plantCode };
        service.getQualityDetailsByPcId(req).then(res => {
            if (res.status) {
                setQualityData(res.data?.map((item) => ({ ...item, layCan: laycanDateFormate(item.layCan) })))
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    // hide model
    const hideModel = () => {
        setEnableSpecData(false);
        setActiveQualityData(undefined);
        setActiveSnapQualityData(undefined);
    }

    const openPurchaseTermData = (quality: IQualityGrid, index: number) => {
        setEnableSpecData(true);
        setActiveQualityData(quality);
        setActiveSnapQualityData(snapQuality?.[index]);
    }

    const openQualityAdjustments = (quality: IQualityGrid, index: number) => {
        setEnableQualityAdjustments(true);
        setActiveQualityData(quality);
        setActiveSnapQualityData(snapQuality?.[index]);
    }

    const hideQualityAdjustments = () => {
        setEnableQualityAdjustments(false);
        setActiveQualityData(undefined);
        setActiveSnapQualityData(undefined);
    }

    const showIndexFormData = (purchaseData: PurchaseDetailsDto) => {
        setActivePurchaseId(purchaseData.typeId);
        setActiveIndexedFormData(purchaseData.indexedData);
    }

    const columns = ['Laycan', 'Quality', 'Quantity', 'Tolerance %', 'Mine Name', 'Inco Term', 'Freight PricePMT', 'Jetty / Loading Port', 'Index Linked', 'Price PMT', 'Fc Charges', 'VAT', 'Export Documentation', 'Business No', 'Sales Term', 'Specification Standard']

    const cancelHandler = () => {
        setActivePurchaseId(null);
        setActiveIndexedFormData(null);
    }

    const onChange = (key: SpecTypesEnum) => {
        setActiveSpecType(key);
    };


    const rowClassName = (record, qualitySeq) => {
        if (!snapQuality.some((item) => item?.qualitySeq === qualitySeq)) {
            if (contractType === ContractTypesEnum.ADDENDUM) {
                return 'highlighted-row';
            } else {
                return '';
            }
        }
        return '';
    };
    return (
        <div style={{ overflowX: 'auto' }}>
            <div className='table-responsive'>
                <table className='table table-bordered table-sm'>
                    <thead className='table-header' style={{ background: '#fafafa' }}>
                        {columns?.map((item) => (
                            <th className='text-center'>{item}</th>
                        ))}</thead>
                    <tbody>
                        {qualities?.map((e, index) => e.purchaseTypeData.map((item, idx) => {
                            let taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
                            let vatAmount = item.vat ? item.initialPricePMT ? (item.initialPricePMT - item.coalPricePMT != 0 ? item.initialPricePMT - item.coalPricePMT : item.coalPricePMT - item.coalPricePMT / (1 + (taxPercentage / 100))) : item.coalPricePMT - item.coalPricePMT / (1 + (taxPercentage / 100)) : 0;
                            return (
                                <tr key={item.id} style={{ backgroundColor: e.color ? e.color : "" }} className={rowClassName(e, e.qualitySeq)}>
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'layCan', e?.layCan)}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'quality', e?.quality)}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'quantity', e?.quantity)}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center font-bold' style={{verticalAlign:'middle'}}rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'tolerance', e?.tolerance)}</td>
                                    }
                                    {idx === 0 &&
                                        <td className='text-uppercase text-center'  style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'mineNameDesc', e?.mineNameDesc)}</td>
                                    }
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'purchaseType', item.purchaseType)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'freightPricePMT', item.freightPricePMT)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'jettyName', item.jettyName)}</td>
                                    <td className='text-uppercase text-center'> {(item.isIndexLinked === true || item.isIndexLinked === 'Yes') ?
                                        <a className="link-primary" onClick={() => showIndexFormData(item)}>Yes</a>
                                        : 'No'
                                    }</td>
                                    <td className='text-uppercase text-center'>{isPermissionExist([99, 114]) && CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'coalPricePMT', item.coalPricePMT)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'fcCharges', item.fcCharges)}</td>
                                    <td className='text-uppercase text-center'>{vatAmount}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'exportDocumentation', item.exportDocumentation)}</td>
                                    <td className='text-uppercase text-center'>{CheckTypesDiffHandler(e.qualitySeq, item.typeSeq, 'businessNumber', item.businessNumber)}</td>

                                    {idx === 0 &&
                                        <td className='text-uppercase text-center'style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}>{valiDateWithSnapShotData(e.qualitySeq, 'purchasingTerm', e?.purchasingTerm)}</td>
                                    }
                                    {idx === 0 &&

                                        <td className='text-uppercase text-center'style={{verticalAlign:'middle'}} rowSpan={e.purchaseTypeData.length}

                                        >
                                            <><a className="link-primary" onClick={() => openPurchaseTermData(e, index)}>{valiDateWithSnapShotData(e.qualitySeq, 'specStandard', e.specStandard)}</a><span>||</span><a className="link-primary" onClick={() => openQualityAdjustments(e, index)}>Q-A</a>
                                                ||{e.purchasingTerm === ContractTermEnum.SPEC && e.noPriceAdjustment ? <span className='text-warning'>No Price Adjustment</span> : ''}
                                            </>
                                        </td>
                                    }
                                </tr>
                            )
                        }
                        ))}
                    </tbody>
                </table>
            </div>
            {/* <Table
                columns={qualityColumns}
                dataSource={qualityData}
                pagination={false}
                bordered
                rowClassName={rowClassName}
                rowKey={(record) => record.typeId}
            /> */}
            <Modal
                open={enableSpecData}
                className='dashborad-popup'
                width='70%'
                title=' '
                style={{ top: 0 }}
                bodyStyle={{ height: '90vh', overflowY: 'auto' }}
                onCancel={hideModel}
                footer={<></>}
            >
                {qualityData[0]?.specStandard == SpecTypesEnum.BOTH ? <Tabs onChange={onChange} activeKey={activeSpecType} centered>
                    <TabPane tab={SpecTypesEnum.ASTM} key={SpecTypesEnum.ASTM}>
                        <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ASTM} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ASTM} />
                    </TabPane>
                    <TabPane tab={SpecTypesEnum.ISO} key={SpecTypesEnum.ISO}>
                        <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={SpecTypesEnum.ISO} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={SpecTypesEnum.ISO} />
                    </TabPane>
                </Tabs> : <SpecsViewPage purchaseTermData={activeQualityData?.purchaseTermData} specType={activeQualityData?.specStandard} contractType={contractType} snapShotPurchaseTermData={activeSnapQualityData?.purchaseTermData} snapSpecType={activeSnapQualityData?.specStandard} />}

            </Modal>
            <Modal
                open={enableQualityAdjustments}
                className='dashborad-popup'
                width='70%'
                title=' '
                style={{ top: 0 }}
                bodyStyle={{ height: '90vh', overflowY: 'auto' }}
                onCancel={hideQualityAdjustments}
                footer={<></>}
            >
                {activeQualityData?.qualityAdjustments ? <pre>
                    {activeQualityData.qualityAdjustments}
                </pre> : <Empty />}
            </Modal>
            <Drawer
                width={800}
                open={activePurchaseId ? true : false}
                key={activePurchaseId}
                closable={false}
                footer={undefined}
                bodyStyle={{ padding: 0 }}
            >
                <CoalIndexView indexedData={activeIndexedFormData} cancelHandler={cancelHandler} />
            </Drawer>
        </div>
    )
}

export default QualityDetailView;