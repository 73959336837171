import { BusinessNumberService, CPSPreviewDto, ContractModesEnum, ContractStatusEnum, ContractTypesEnum, ProcurementContractService, ProcurementContractTypeDto, ProcurementContractTypeEnum, PurchaseContractNoReqDto, VendorAdvService } from '@exportx/shared-models-and-services'
import { Button, Card, Divider, Dropdown, Form, MenuProps, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Link from 'antd/lib/typography/Link'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AlertMessages, StatusTag, useAuthState } from '../../../common'
import StatusApprovalsButton from '../../../common/status-tag/status-approvals-button-component'
import VendorAdvance from '../../masters/components/advance/vendoradvance-form'
import { ProcurementServiceContract } from '../procurement-service'
import AssociatedBusinessNumbers from './pc-associated-business-numbers'

interface IAllPurchaseContractDetailedViewProps {
    contractId: any;
    plantCode: string;
    bpId?: any;
    pcQuantityTotal?: number;
    contractType: ContractTypesEnum;
}

const AllPurchaseContractDetailedView = (props: IAllPurchaseContractDetailedViewProps) => {
    const navigate = useNavigate();
    const { authContext } = useAuthState();
    const [procurementformRef] = Form.useForm();
    const [procurement, setProcurement] = useState<ProcurementContractTypeDto[]>();
    const [vendor, setVendor] = useState<any>();
    const [vendorAdvanceData, setVendorAdvanceData] = useState<any>({"purchaseContractNo": props.contractId, "vendorId": props.bpId});
    const [vendorAdvanceFormRef] = Form.useForm();
    const { formatMessage: fm } = useIntl();
    const [open, setOpen] = useState(false);
    const [procurementModal, setProcurementModal] = useState(false);
    const [procurementProps, setProcurementProps] = useState<any>();
    const [procurementInitialValues, setProcurementIntialValues] = useState({ quality: [''], filesData: [], lc: [''], others: [''], telegraphicTransfer: [''], });
    const businessService = new BusinessNumberService();
    const procurementService = new ProcurementContractService();
    const vendorService = new VendorAdvService();
    let pathToreDirect = '/po-detail-view';

    const [dummyState, setDummyState] = useState<number>(1);
    const [dummy, setDummy] = useState<number>(1)

    const { contractId, plantCode, bpId, pcQuantityTotal, contractType } = props

    useEffect(() => {
        if (contractId) {
            getProcurementServiceFees();
            getAllAdvanceData();
        }
    }, [contractId]);

    const procurementCreate = () => {
        procurementformRef.validateFields().then(values => {
            procurementService.createProcurementContract({ ...values, plantCode: authContext.defaultPlant }).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setProcurementModal(false);
                    getProcurementServiceFees();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message)
            })
        })
    }

    const procurementStatusApproval = (status: ContractStatusEnum, id: string) => {
        const req = new CPSPreviewDto(id, authContext.defaultPlant, undefined, status, authContext.user.userName)
        procurementService.procurementStatusApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getProcurementServiceFees();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    const getProcurementServiceFees = () => {
        const req = new PurchaseContractNoReqDto(contractId, authContext.defaultPlant, null);
        procurementService.getProcurementServiceFees(req).then(res => {
            if (res.status) {
                setProcurement(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const getAllAdvanceData = () => {
        const req = new PurchaseContractNoReqDto(contractId, authContext.defaultPlant)
        vendorService.getAllAdvanceData(req).then(res => {
            if (res.status) {
                setVendor(res.data);
            }

        }).catch(err => {

            AlertMessages.getErrorMessage(err.message);
        })
    }

    const vendorAdvanceSaveData = () => {
        vendorAdvanceFormRef.validateFields().then(values => {
            vendorService.create({ ...values, plantCode: authContext.defaultPlant }).then(res => {

                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    // redirectToFullView(contractId,authContext.defaultPlant)
                    getAllAdvanceData()
                    vendorAdvanceFormRef.resetFields();
                    setDummy((prev) => prev + 1)
                    setTimeout(() => {
                        setOpen(false);
                    }, 1000)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            }
            )
        })
    }



    const handleCancel = () => {
        setOpen(false);
        setDummy((prev) => prev + 1)
        vendorAdvanceFormRef.resetFields()
    }




    const procurementColumns: ColumnsType<any> = [

        {
            title: 'Contract No',
            dataIndex: 'contractNo',
            sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                const pathToreDirect = record.contractType === ProcurementContractTypeEnum.Procurement_Fees
                    ? '/procurement-contract-detailedView'
                    : record.contractType === ProcurementContractTypeEnum.Coal_Service_Fees
                        ? '/coalServiceFee-detailedView'
                        : undefined;
                const link = `/#${pathToreDirect}?cpcId=${record.pcID}`;
                return <a href={link} target="_blank" rel="noopener noreferrer">
                    {record.contractNo}
                </a>;
            }
        },



        {
            title: 'Vendor Name',
            dataIndex: 'vendor',
            sorter: (a, b) => a.vendorName != null ? a.vendorName.localeCompare(b.vendorName) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{value} </span>;
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantityInMt',
            sorter: (a, b) => a.contractDate != null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",

        },

        {
            title: 'Deduction Bases',
            dataIndex: 'priceType',
            sorter: (a, b) => a.priceType != null ? a.priceType.localeCompare(b.priceType) : "Have Null Values",

        },
        {
            title: 'Price PMT',
            dataIndex: 'pricePmt',
            sorter: (a, b) => a.pricePMT != null ? a.pricePMT.localeCompare(b.pricePmt) : "Have Null Values",

        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            sorter: (a, b) => a.pricePMT != null ? a.pricePMT.localeCompare(b.pricePmt) : "Have Null Values",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: (a, b) => a.pricePMT != null ? a.pricePMT.localeCompare(b.pricePmt) : "Have Null Values",
            render: (value, record) => {
                return <>
                    <StatusTag status={value} />
                </>
            }
        },

        {
            title: 'Action',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {

                return <>
                    <StatusApprovalsButton status={record.status} statusApproval={procurementStatusApproval} id={record.pcID} />
                </>

                // return <span><Button type='primary' disabled={record.status === ContractStatusEnum.DRAFT ? false : true} onClick={() => procurementStatusApproval(record.pcID)}>Approve</Button></span>;
            }
        }

    ]
    const vendorAdvanceColumns: ColumnsType<any> = [
        {
            title: 'Vendor Name',
            dataIndex: 'vendorName',
            sorter: (a, b) => a.vendorName != null ? a.vendorName.localeCompare(b.vendorName) : "Have Null Values",

        },
        {
            title: 'Contract No',
            dataIndex: 'contractNo',
            sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",

            render: (value, record) => {
                const link = `/#/advanceDetailView?id=${record.id}`
                return <> {
                    <Link href={link} className="link-primary" >{record.contractNo}</Link>
                }
                </>
            }

        },
        {
            title: 'Contract Date',
            dataIndex: 'contractDate',
            render: (text, record) => { return moment(text).format("DD-MM-YYYY") },
            sorter: (a, b) => a.contractDate != null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",

        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a, b) => a.advanceAmount != null ? a.amount.localeCompare(b.amount) : "Have Null Values",

        },
        {
            title: 'Currency',
            dataIndex: 'negotiationCurrency',
            sorter: (a, b) => a.negotiationCurrency != null ? a.amount.localeCompare(b.negotiationCurrency) : "Have Null Values",

        }

    ]

    const procurementTableDataProcessing = (values: any[]) => {
        const procurementData = [];
        values?.forEach((rec) => {
            rec.quality.forEach((qualityRecord, index) => {
                procurementData.push(
                    {
                        quantity: qualityRecord.quantity,
                        quantityInMt: qualityRecord.quantityInMt,
                        priceType: qualityRecord.priceType,
                        pricePmt: qualityRecord.pricePmt,
                        layCan: qualityRecord.layCan,
                        totalPrice: qualityRecord.totalPrice,
                        rowSpan: index === 0 ? rec.quality.length : 0,
                        pcID: rec.pcID,
                        contractNo: rec.contractNo,
                        vendor: rec.vendor,
                        contractType: rec.contractType,
                        status: rec.status
                    }
                )
            })
        })
        return procurementData
    }


    const items = Object.values(ProcurementContractTypeEnum).filter(value => { return value !== ProcurementContractTypeEnum.Marketing_Fees }).map(bpCat => {
        return {
            label: `${bpCat}`,
            key: `${bpCat}`,
        }
    })
    const menuOnClickHandler: MenuProps['onClick'] = ({ key }) => {
        setProcurementProps(key); setProcurementModal(true)
    };
    return (
        <>

            <AssociatedBusinessNumbers contractId={contractId} pcQuantityTotal={pcQuantityTotal} contractType={ContractModesEnum.PURCHASE} />

            <Card title={<span style={{ float: "left" }}>Procurement Service Summary</span>}
                extra={<Dropdown.Button type='primary' onClick={() => setDummyState((prev) => prev + 1)}
                    menu={{ items, onClick: menuOnClickHandler }}
                >Procurement Contract Service</Dropdown.Button>}>
                <Table
                    columns={procurementColumns}
                    bordered dataSource={procurementTableDataProcessing(procurement)}
                    pagination={false}
                >

                </Table>
            </Card>

            <Card
                extra={<Button type='primary' onClick={() => { setOpen(true); }}>Add New Vendor Advance</Button>}
            >
                <Table columns={vendorAdvanceColumns} dataSource={vendor} pagination={false}></Table>
            </Card>

            <Modal
                style={{ top: 10 }}
                open={procurementModal}
                onCancel={() => { setProcurementModal(false); procurementformRef.resetFields(); setDummyState((prev) => prev + 1) }}
                okText="Submit"
                key={dummyState + 1}
                cancelButtonProps={{ style: { display: "none" } }}
                closable={null}
                onOk={() => {
                    procurementCreate()
                }}
                width={1300}
                footer={null}
            >
                <ProcurementServiceContract setDummyState={setDummyState} key={dummyState} closeDrawer={"PurchaseContract"} getProcurementServiceFees={getProcurementServiceFees} setProcurementModal={setProcurementModal} contractType={procurementProps} contractId={contractId} />
            </Modal>

            <Modal
                open={open}
                okText='Submit'
                onCancel={handleCancel}
                width={1000}

                onOk={() => { vendorAdvanceSaveData() }}
                key={dummy}
            >
                <VendorAdvance bpId={bpId} vendor={contractId} key={dummy} formRef={vendorAdvanceFormRef} initialValues={vendorAdvanceData} layoutType={'vertical'} />
            </Modal>

        </>
    )
}

export default AllPurchaseContractDetailedView