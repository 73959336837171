

import { useState, useEffect } from 'react';
import './taxes-grid.css';
import { Card, Button, } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { TaxesDto, TaxesService } from '@exportx/shared-models-and-services';
import { AlertMessages, FilterableGrid } from '../../../../../common';
import { useIntl } from 'react-intl';
export interface TaxesGridProps {
  // StatesData: StatesDto[];
  //  viewState: (State: StatesDto, viewOnly: boolean) => void;
  // deleteState: (State: StatesDto) => void;
}

export const TaxesGrid = (props: TaxesGridProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();//Common for any Component that might be Page,Grid,Form.....
  const [taxesData, setTaxesData] = useState<TaxesDto[]>([]);


  const service = new TaxesService();

  useEffect(() => {
    getAllTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getAllTaxes = () => {
    service.getAllTaxes().then(res => {
      if (res.status) {
        setTaxesData(res.data);
      } else {
        setTaxesData([]);
      }
    }).catch(err => {
      setTaxesData([]);
    })
  }
  /**
     * 
     * @param TaxesData 
     */
  const deleteTax = (taxesDto: TaxesDto) => {
    taxesDto.isActive = taxesDto.isActive ? false : true;
    service.ActivateOrDeactivateTax(taxesDto).then(res => {
      if (res.status) {
        getAllTaxes();
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Taxes ${taxesDto.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  const sampleTypeColumns: ColumnProps<any>[] = [
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxName',
        defaultMessage: 'Tax Name'
      }),
      dataIndex: 'taxName',
      align: 'left',
      render: (value: string) => (<div style={{ textAlign: 'left' }}>{value}</div>)
    },
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxPercentage',
        defaultMessage: 'Tax Percentage'
      }),
      dataIndex: 'taxPercentage',
      align: 'right',
      render: (value: string) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      title: fm({
        id: 'pms.components.taxesGrid.taxCategory',
        defaultMessage: 'Tax Category'
      }),
      dataIndex: 'taxType',
      align: 'left',
      render: (value: string) => (<div style={{ textAlign: 'left' }}>{value}</div>)
    }
  ];



  const view = (taxesData: TaxesDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (taxesData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/taxes-form';
    navigate(pathToreDirect, { state: { taxesData, isNewRecord, isUpdate } });
  }
  return (
    <>
      <Card title={<span >Taxes</span>}
        className='default-card-class'
        extra={
          <Button
            onClick={() => view(undefined, false)}
          >
            {fm({ id: 'common.create', defaultMessage: 'Create' })}
          </Button>
        } >
        <FilterableGrid
          rowKey='taxId'
          mainColumns={sampleTypeColumns}
          data={taxesData}
          filterColumnsData={[
            {
              dataIndex: 'taxName',
              placeHolder: fm({
                id: 'pms.components.taxesGrid.taxName',
                defaultMessage: 'Tax Name'
              }),
            },
            {
              dataIndex: 'taxPercentage',
              placeHolder: fm({
                id: 'pms.components.taxesGrid.taxPercentage',
                defaultMessage: 'Company Name'
              })
            }
          ]}
          otherTableProps={{ bordered: true }} activateOrDeactivate={deleteTax} view={view} />
      </Card>
    </>
  );

};


export default TaxesGrid;

