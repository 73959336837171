import { UploadOutlined } from '@ant-design/icons';
import { AdvanceEnum, CostingInvoiceService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { getBase64 } from '@exportx/ui-utils';
import { Button, Col, Form, FormInstance, Input, Row, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../common';
import DatePicker from '../../common/data-picker/date-picker';
import ViewFileHandling from '../../common/file-upload-form/view-files-handling';


interface IProps {
    initialValues: any;
    dummyRefresh: number;
    formRef: FormInstance<any>;
    getAllCostingInvoice: () => void;
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
    setInvoiceFormDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    costingData: any;
    invoiceTotal: number;
    vendorAdvanceType?: any;
    vendorId?: string;
    contractNo?: string;
    advanceType?: AdvanceEnum
}

const AddCostingInvoicesForm = (props: IProps) => {
    const { advanceType, initialValues, dummyRefresh, formRef, getAllCostingInvoice, setDummyRefresh, setInvoiceFormDrawer, costingData, invoiceTotal, vendorAdvanceType, vendorId, contractNo } = props;
    const [fileList, setFileList] = useState<any>([]);
    const [saveButton, setSaveButton] = useState<boolean>(true);
    const { authContext } = useAuthState();
    const invoiceService = new CostingInvoiceService();
    const totalAmount = vendorAdvanceType ? Math.abs(costingData?.amount) : Math.abs(costingData?.totalAmount);



    const onFileChange = (data) => {
        setFileList([data.file]);
    };

    useEffect(() => {
        if (initialValues) {
            validateAmount(initialValues.invoiceAmount)
        }
    }, [initialValues]);

    useEffect(() => {
        formRef.setFieldValue('invoiceAmount', Math.abs(totalAmount - invoiceTotal));
        setSaveButton(false);
    }, [])

    const validateAmount = (e) => {
        const extraAmount: number = authContext.defaultPlantCurrency === "IDR" ? 250000 : 50;
        const totalOfInvoice = invoiceTotal + Number(e);
        console.log(Number(totalAmount) < totalOfInvoice, Number(totalAmount), totalOfInvoice)
        if ((Number(totalAmount) + Number(extraAmount)) < totalOfInvoice) {
            formRef.setFieldValue('invoiceAmount', undefined);
            setSaveButton(true)
            return AlertMessages.getErrorMessage(`Do Not Exceed Invoice Amount More Than To TotalAmount`);
            // ${Number(totalAmount) - (invoiceTotal + 50)}
        } else {
            setSaveButton(false);
        };
    };


    const createCostingInvoice = () => {
        formRef.validateFields().then(async values => {
            const formFilesDataFile = formRef.getFieldValue('filesData');
            const formFilesData = formFilesDataFile?.file;
            let filesData = [];
            if (formFilesData) {
                const base64 = await getBase64(formFilesData.originFileObj);
                filesData.push(
                    {
                        ...formFilesData,
                        base64Url: base64,
                        fileDescription: 'Invoice file',
                        fileUploadId: initialValues?.filesData[0]?.fileUploadId
                    }
                )
            };


            const costingId = vendorAdvanceType ? costingData?.id : costingData?.costingId;
            const referenceFeatures = costingData?.referenceFeatures
            const req = { ...values, filesData: filesData, plantCode: authContext.defaultPlant, userName: authContext.user.userName, referenceFeature: referenceFeatures, costingId: costingId, vendorId: vendorId, contractNo: contractNo, advanceType: advanceType }
            invoiceService.createCostingInvoice(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setDummyRefresh(prev => prev + 1);
                    getAllCostingInvoice();
                    setInvoiceFormDrawer(false);
                    formRef.resetFields();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => console.log(err.message))
        })
    };

    const invoicePercentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) > 100 || Number(e.target.value) === 0) {
            formRef.setFieldValue('invoicePercent', undefined);
            formRef.setFieldValue('invoiceAmount', undefined);
            setSaveButton(true);
        } else {
            const percentageValue = Number(totalAmount) * Number(e.target.value);
            formRef.setFieldValue('invoiceAmount', Number(Number(percentageValue / 100).toFixed(2)));
            validateAmount(formRef.getFieldValue('invoiceAmount'));
        };

    }


    return <>

        <Form autoComplete='off' layout="vertical" initialValues={initialValues} key={dummyRefresh + 1} form={formRef}>
            <Row>

                <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
                    <Form.Item
                        label=" Invoice Date"
                        name="invoiceDate"
                        rules={[{ required: true, },]}>
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item></Col>


                <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
                    <Form.Item label="Invoice No" name="invoiceNo" rules={[{ required: true, },]} >
                        <Input placeholder="INVOICE NO" type="text" style={{ width: "90%" }} />
                    </Form.Item></Col>

            </Row>
            <Row>
                <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
                    <Form.Item label="Invoice % " name="invoicePercent" >
                        <Input placeholder="PERCENT" onChange={invoicePercentHandler} type="number" style={{ width: "90%" }} />
                    </Form.Item></Col>



                <Col xs={{ span: 24, }} lg={{ span: 10, offset: 2 }}>
                    <Form.Item label="Invoice Amount" name="invoiceAmount" rules={[{ required: true, },]} >
                        <Input
                            placeholder="INVOICE AMOUNT"
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateAmount(e.target.value)}
                            defaultValue={totalAmount}
                            style={{ width: "90%" }}
                        />
                    </Form.Item></Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Form.Item
                        label="Remarks"
                        name="remarks"
                        rules={[{
                            required: true,

                        }]}
                    >
                        <Input placeholder="REMARKS" type="text" style={{ width: "90%" }} />
                    </Form.Item></Col>
                <Col  >
                    <Form.Item name='filesData' label="Upload The Invoice"  >
                        <Upload
                            multiple={false}
                            fileList={fileList}
                            customRequest={onFileChange}
                            onRemove={() => setFileList([])}
                            showUploadList={true}
                            listType='text'
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Choose File</Button>
                        </Upload>

                    </Form.Item>
                    <ViewFileHandling filesData={initialValues?.filesData} required={false} />
                </Col>
            </Row>

            <Row>
                <Col offset={18}>
                    <Button type='primary' disabled={saveButton} onClick={createCostingInvoice}>Save</Button>

                </Col>
            </Row>
            <Form.Item name={'invoiceId'} hidden={true}>
                <Input></Input>
            </Form.Item>
        </Form>

    </>

}

export default AddCostingInvoicesForm