import { PlusSquareOutlined } from '@ant-design/icons';
import { PcQualitiesDropDownDto, PcTypesDropDownDto, PriceTypeEnum, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from '../../../common/data-picker/date-picker';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IQualitiesFormProps {
    contractCurrency: string;
    purchaseContractNo: string;
    initialValues: any;
    subFormHandler: (values: any) => void;
    subFormRef: FormInstance<any>;
    qualities: PcQualitiesDropDownDto[];
    pcTypes: PcTypesDropDownDto[];
    formValuesChangeHandler: (changedValues: any, values: any) => void;
    contractType?: ProcurementContractTypeEnum;
}
export const QualitiesForm = (props: IQualitiesFormProps) => {
    const { contractCurrency, purchaseContractNo, initialValues, subFormHandler, subFormRef, qualities, pcTypes, formValuesChangeHandler, contractType } = props;
    const { formatMessage: fm } = useIntl();

    const addQualityData = () => {
        subFormRef.validateFields().then(values => {
            subFormHandler(values);
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        subFormRef.setFieldsValue(initialValues);
    }, [initialValues]);

    return (
        <div>
            <Form
                form={subFormRef}
                layout='vertical'
                autoComplete='off'
                initialValues={initialValues}
                onValuesChange={formValuesChangeHandler}
            >
                <Row>
                    <Col
                        xs={{ span: 22 }}
                        sm={{ span: 22 }}
                        md={{ span: 22 }}
                        lg={{ span: 22 }}
                        xl={{ span: 22 }}>
                        <div className='contractTypeBlock p-1 pl-3'>
                            <Row gutter={16}>
                                {purchaseContractNo && <>
                                    <Col>
                                        <Form.Item
                                            name={"pcQualitiesId"}
                                            label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Quantity" : fm({ id: "mdm.components.procurmentservice.PurchaseQuality ", defaultMessage: `Purchase Quality ` })}
                                            rules={[{ required: true, message: 'PurchaseQuality Is ReQuired' }]}
                                        >
                                            <Select
                                                style={{ width: "212px" }}
                                                showSearch
                                                placeholder={fm({ id: `common.procurmentservice.reqPurchaseQuality `, defaultMessage: `Please Select` })}
                                                onChange={() => { }}
                                            >
                                                {qualities?.map((rec) => {
                                                    const laycanDate = rec.layCan.split(',');
                                                    return <Option value={rec.pcqId} key={rec.pcqId}>{`${moment(laycanDate[0]).format('DD MMM YY') + ' - ' + moment(laycanDate[1]).format('DD MMM YY')} - (${rec.pcqQuality})`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name={"purchaseQuality"} style={{ display: 'none' }}>
                                            <Input hidden />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item label='Laycan' name={'layCan'}>
                                            <RangePicker disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name={"pcPurchaseTypeId"}
                                            label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Type" : fm({ id: "mdm.components.procurmentservice.purchaseType", defaultMessage: 'Purchase Type' })}
                                            rules={[{ required: true, message: 'purchase Type' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={fm({ id: `common.procurmentservice.reqPurchaseType`, defaultMessage: `Please Select` })}
                                                style={{ width: "212px" }}
                                                onChange={() => { }}
                                            >
                                                {pcTypes?.map((rec) => {
                                                    return <Option value={rec.pcTypeId}  key={rec.pcTypeId}>{`${rec.purchaseType} ${rec.jettyName ? `- (${rec.jettyName})` : ''}`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item name={"pcPurchaseType"} style={{ display: 'none' }}>
                                            <Input hidden />
                                        </Form.Item>
                                    </Col></>}
                                <Col>
                                    <Form.Item name={"priceType"}
                                        label="Price Type"
                                        rules={[{ required: true, message: "Please Select An Option" }]} >
                                        <Radio.Group>
                                            {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                                return <Radio key={priceTypeKey} value={PriceTypeEnum[priceTypeKey]}>{PriceTypeEnum[priceTypeKey]}</Radio>
                                            })}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name={"quantityInMT"}
                                        label={fm({ id: "mdm.components.procurmentservice.quantity ", defaultMessage: 'Quantity' })}
                                        rules={[{ required: true, message: 'Quantity' }]}>
                                        <InputNumber placeholder={fm({ id: `common.procurmentservice.reqQuantit`, defaultMessage: `Quantity` })} min={0} />
                                    </Form.Item>
                                </Col>

                                {initialValues?.priceType === PriceTypeEnum.PER_MT && <Col>
                                    <Form.Item name={"pricePMT"}
                                        label={fm({ id: "mdm.components.procurmentservice.pricePMT", defaultMessage: 'Price PMT' })}
                                        rules={[{ required: true, message: '"Price PMT' }]}>

                                        <InputNumber
                                            placeholder={fm({ id: `mdm.components.procurmentservice.reqPricePMT`, defaultMessage: `Enter Value` })} autoComplete='off'
                                            addonAfter={contractCurrency}
                                        />
                                    </Form.Item >
                                </Col>}

                                <Col>
                                    <Form.Item name={"totalPrice"}
                                        label={fm({ id: "mdm.components.procurmentservice.totalPrice", defaultMessage: 'TotalPrice' })}
                                        rules={[{ required: true, message: 'Total Price' }]}
                                    >
                                        <InputNumber
                                            placeholder={fm({ id: `common.procurmentservice.reqTotalPrice`, defaultMessage: `Enter Value` })} autoComplete='off'
                                            addonAfter={contractCurrency}
                                            disabled={initialValues.priceType === PriceTypeEnum.PER_MT}
                                        />
                                    </Form.Item >
                                </Col>
                                <Col>
                                    <Form.Item name={"qualityAdjustment"}
                                        label={fm({ id: "mdm.components.procurmentservice.qualityAdjustment", defaultMessage: 'QualityAdjustment' })}
                                        rules={[{ required: false, message: 'QualityAdjustment' }]}
                                    >
                                        <Radio.Group >
                                            <Radio value={'Yes'}>Yes</Radio>
                                            <Radio value={'No'}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item >
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                        lg={{ span: 2 }}
                        xl={{ span: 2 }}>
                        <Button onClick={() => addQualityData()} type='primary' icon={<PlusSquareOutlined style={{ fontSize: '18px' }} />}></Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default QualitiesForm;