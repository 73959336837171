import { Affix, Button, Card, Col, Form, FormInstance, Row } from "antd"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AllocatedTypeEnum, BargeMappingRequestDto, BargeService, BnBargeMappingParentCreateDto, BNoRequest, GetAllBargeMappingDataDto } from "@exportx/shared-models-and-services";
import { BusinessNumberService } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../../../common";
import BargeMapAllocForm from "./barge-map-alloc-form";
import { SequenceUtils } from "@exportx/ui-utils";


interface mapBargesIProps {
    closeDrawer1?: () => void;
    getAllBargeBusinessMapping?: (value: any) => void;
    bargeMappingForm?: FormInstance<any>,
    setDummyState?: React.Dispatch<React.SetStateAction<number>>
}

export const MapBarges = (props: mapBargesIProps) => {
    const { closeDrawer1, getAllBargeBusinessMapping, bargeMappingForm, setDummyState } = props;
    const { authContext } = useAuthState();
    const { state }: any = useLocation();
    let { businessNo } = state ? state : { businessNo: '' };

    const [allocationIds, setAllocationIds] = useState<{ id: string, displayAllocationId: string }[]>([]);
    const [allocationDataMap, setAllocationDataMap] = useState<Map<string, GetAllBargeMappingDataDto[]>>(new Map());
    const [selectedAllocations, setSelectedAllocations] = useState<Map<number, string>>(new Map());
    const [selectedRowKeysData, setSelectedRowKeysData] = useState<Map<string, any>>(new Map());
    const [formValuesData, setFormValuesData] = useState<Map<string, any>>(new Map());



    const service = new BusinessNumberService();
    const bargeService = new BargeService();

    useEffect(() => {
        getAllocationIdsDropDown();
        getBargeMappingData();
    }, [businessNo]);

    const getBargeMappingData = () => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        service.getBargeMappingData(req).then(res => {
            if (res.status) {
                setDefaultSavedData(res.data);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const setDefaultSavedData = (data: BnBargeMappingParentCreateDto) => {
        let updatedFormValuesData = new Map(formValuesData);
        let selectedRowKeysDataUpdate = new Map(selectedRowKeysData);
        const allocationsSet = new Set<string>();
        data.mappingData.forEach(element => {
            let key = element.bargeUid + '$@$' + element.allocationId
            updatedFormValuesData.set(key, element);
            allocationsSet.add(element.allocationId)
            if (!selectedRowKeysDataUpdate.has(element.allocationId)) {
                selectedRowKeysDataUpdate.set(element.allocationId, [key])
            } else {
                const keys = new Set(selectedRowKeysDataUpdate.get(element.allocationId));
                keys.add(key)
                selectedRowKeysDataUpdate.set(element.allocationId, Array.from(keys));
            }
        });
        getBargesAllocatedData(Array.from(allocationsSet));
        setFormValuesData(updatedFormValuesData);
        let index = 0;
        const selectedAllocationsUpdate = new Map(selectedAllocations);
        allocationsSet.forEach((rec: string) => {
            bargeMappingForm.setFieldValue(['allocation', index, 'allocationId'], rec);
            selectedAllocationsUpdate.set(index, rec);
            index += 1;
        })
        setSelectedAllocations(selectedAllocationsUpdate);
        setSelectedRowKeysData(selectedRowKeysDataUpdate);
    }

    const getAllocationIdsDropDown = () => {
        const req = new BNoRequest(businessNo, authContext.defaultPlant)
        service.getAllocationIdsDropDown(req).then(res => {
            if (res.status) {
                setAllocationIds(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
                setAllocationIds([]);
            }
        }).catch(err => {
            setAllocationIds([]);
            AlertMessages.getErrorMessage(err.message);
        })
    };

    const bargeMappingFormSubmit = () => {
        try {
            const req = new BnBargeMappingParentCreateDto(businessNo, [], authContext.user.userName, authContext.defaultPlant);
            const validateCheck = new Map();
            for (const [, values] of formValuesData.entries()) {
                values.allocationType = (values.allocationType === true || values.allocationType === AllocatedTypeEnum.FULL) ? AllocatedTypeEnum.FULL : AllocatedTypeEnum.PARTIAL;
                req.mappingData.push(values);
                if (!validateCheck.has(values.bargeUid)) {
                    const alloCationsSet = new Set();
                    alloCationsSet.add(values.allocationType)
                    validateCheck.set(values.bargeUid, alloCationsSet);
                } else {
                    const alreadyAddedAllocationType = validateCheck.get(values.bargeUid);
                    if (alreadyAddedAllocationType.has(AllocatedTypeEnum.FULL)) {
                        if (values.allocationType == AllocatedTypeEnum.FULL) {
                            throw new Error(`You cannot assign same barge ${SequenceUtils.formatNumberToSpecificLength(values.bargeId)} with both Full`)
                        } else {
                            throw new Error(`You cannot assign same barge ${SequenceUtils.formatNumberToSpecificLength(values.bargeId)} with both Full and Partial`)
                        }
                    } else {
                        if (values.allocationType == AllocatedTypeEnum.FULL) {
                            throw new Error(`You cannot assign same barge ${SequenceUtils.formatNumberToSpecificLength(values.bargeId)} with both Full and Partial`)
                        }
                    }
                    alreadyAddedAllocationType.add(values.allocationType);
                }
            }
            service.saveBusinessNoBargeMappings(req).then(res => {
                if (res.status) {
                    closeHandler();
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })
        } catch (error: any) {
            AlertMessages.getErrorMessage(error.message);
        }
    }

    const closeHandler = () => {
        closeDrawer1();
        bargeMappingForm.resetFields();
        setDummyState(prev => prev + 1);
        getAllBargeBusinessMapping(businessNo)
    }


    const getBargesAllocatedData = (allocationId: string[]) => {
        const req = new BargeMappingRequestDto(authContext.defaultPlant, allocationId);
        bargeService.getBargeAllocatedData(req).then(res => {
            if (res.status) {
                const updatedMap = new Map(allocationDataMap);
                res.data.forEach(rec => {
                    updatedMap.set(rec.allocationId, rec.mappedData);
                })
                setAllocationDataMap(updatedMap);
            } else {
                //AlertMessages.getErrorMessage(res.internalMessage);
                // setNoDataAllocationIds(Array.from(new Set([...noDataAllocationIds, allocationId])))
            };
        }).catch((err) => {
            console.log(err.message);
            // setNoDataAllocationIds(Array.from(new Set([...noDataAllocationIds, allocationId])))
        });
    };
    return (<>
        <Form form={bargeMappingForm} layout={'vertical'} autoComplete='off'>
            <Form.List name={'allocation'}>
                {(fields, { add, remove }) => (
                    <>
                        <BargeMapAllocForm allocationDataMap={allocationDataMap} fields={fields} add={add} remove={remove} bargeAllocatedData={allocationIds} selectedAllocations={selectedAllocations} setSelectedAllocations={setSelectedAllocations} setSelectedRowKeysData={setSelectedRowKeysData} selectedRowKeysData={selectedRowKeysData} setFormValuesData={setFormValuesData} formValuesData={formValuesData} businessNo={businessNo} getBargesAllocatedData={getBargesAllocatedData} />
                    </>
                )}
            </Form.List>
            <Affix offsetBottom={0}>
                <Card bodyStyle={{ padding: '6px 12px' }}>
                    <Row justify='space-between'>
                        <Col><Button onClick={closeHandler}>Cancel</Button></Col>
                        <Col><Button type="primary" onClick={bargeMappingFormSubmit}>Submit</Button></Col>
                    </Row>
                </Card>
            </Affix>
        </Form>
    </>)
}

export default MapBarges;