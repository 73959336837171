import React, { useEffect, useState } from "react";
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { SequenceUtils, getLocalFormat } from '@exportx/ui-utils';
import { Button, Card, Col, Form, Input, List, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AccountPayableCostings, InvoiceDetails } from "../costing-detail-view";
import { BargeCostingsEnum, BGCostingParentReqDto, CostingTypesEnum, CostingWorkLogsEnum, ContractStatusEnum, PaymentNoticeGetDataDto, PaymentNoticeService, ReferenceFeatures, CmApprovalsReqDto, BargeCostingsService, PerformaCoalCostingService, CommercialCoalCostingService, BPFCostingRequestIdDto, ContractModesEnum, ExpenseEntryApprovalDto, ExpenseEntryService, AdvancePreview, VendorAdvService, AdvanceReceiversService } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../common";

interface IProps {
  type: AuditApprovalScreen
}

export enum AuditApprovalScreen {
  PAYABLE = 'Payable',
  RECEIVABLES = 'Receivables'
}

export const AuditApprovals = (props: IProps) => {
  const { type } = props
  const [expandedIndex, setExpandedIndex] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [paymentNoticeData, setPaymentNoticeData] = useState<PaymentNoticeGetDataDto[]>([]);
  const { authContext } = useAuthState();
  const paymentNoticeService = new PaymentNoticeService();
  const [formRef] = Form.useForm();
  const service = new BargeCostingsService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const expenseEntryService = new ExpenseEntryService()
  const advService = new VendorAdvService()
  const advReceivableService = new AdvanceReceiversService()

  useEffect(() => {
    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.status = ContractStatusEnum.DRAFT
    req.paymentType = type
    paymentNoticeService.getPaymentNoticeAuditApprovals(req).then(res => {
      if (res.status) {
        setPaymentNoticeData(res.data ? res.data : []);
        setExpandedIndex([]);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }, [])

  const routes = (key) => {
    let object = {
      [ReferenceFeatures.PERFORMA_PC_COSTING]: `performaCoalCosting`,
      [ReferenceFeatures.PERFORMA_SC_COSTING]: `performaCostingSale`,
      [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
      [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
      [CostingTypesEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
      [ReferenceFeatures.BARGE_COSTINGS]: `barge-costings/barge`,
      [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costings/barge`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
      [BargeCostingsEnum.DEMURRAGE]: `demurrage`,
      [BargeCostingsEnum.DEAD_FREIGHT]: `dead-freight`,
      [ReferenceFeatures.ADVANCE_INVOICE]: `advanceDetailView`,
      [ReferenceFeatures.EXPENSE_ENTRY]: 'expense-entry',
      [ReferenceFeatures.ADVANCE_RECEIVABLES]: 'advance-receivables-detailed-view'
    };

    let id = "?costingId=";

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;


  };

  const rejectedHandler = (costingType, costingId) => {
    let contractType;

    if (costingType == CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType == CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
      contractType = ContractModesEnum.PURCHASE
    }
    if (costingType == CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType == CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
      contractType = ContractModesEnum.SALES
    }
    if (costingType == CostingWorkLogsEnum.PROCUREMENT_FEES) {
      contractType = CostingWorkLogsEnum.PROCUREMENT_FEES
    }
    if (costingType == CostingWorkLogsEnum.MARKETING_FEES) {
      contractType = CostingWorkLogsEnum.MARKETING_FEES
    }

    if (costingType === CostingWorkLogsEnum.BARGE_COSTING || costingType === ReferenceFeatures.BARGE_COSTINGS) {
      bpfStatusRejected(costingId);
    } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING) {
      perFormaReject(costingId, costingType, contractType);
    } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.MARKETING_FEES) {
      commercialReject(costingId, costingType, contractType);
    } else if(costingType === ReferenceFeatures.EXPENSE_ENTRY || costingType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
      approveExpenses(costingId, ContractStatusEnum.CANCELLED)
    } else if (costingType === ReferenceFeatures.ADVANCE_INVOICE) {
      advanceApproveOrReject(costingId, ContractStatusEnum.CANCELLED)
    }
    else if(costingType === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      advanceReceivableApproveOrReject(costingId, ContractStatusEnum.CANCELLED)
    }
  };

  const advanceReceivableApproveOrReject = (costingId: string, status: string) => {
    const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);

    advReceivableService.advanceReceivableApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
  }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
  })

  }

  const advanceApproveOrReject = (costingId: string, status: string) => {
    const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);
    advService.advanceApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        window.location.reload();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }

  const bpfStatusRejected = (costingId) => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingId, values['remarks'], ContractStatusEnum.CANCELLED, authContext.user.userName, authContext.defaultPlant)
      service.bpfStatusRejected(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
          // getBargeCostingParentData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err)
      })
    })

  }
  const perFormaReject = (costingId, costingType, contractType) => {
    formRef.validateFields().then(values => {
      console.log('perFormaReject',values)
      const req = new CmApprovalsReqDto(costingId, authContext.defaultPlant, authContext.user.userName, ContractStatusEnum.CANCELLED, costingType, contractType, values.remarks);
      perFormaService.performaReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      })
    })

  }

  const commercialReject = (costingId, costingType, contractType) => {
    formRef.validateFields().then(values => {
      const req = new CmApprovalsReqDto(costingId, authContext.defaultPlant, authContext.user.userName, ContractStatusEnum.CANCELLED, costingType, contractType, values.remarks);
      commercialService.commercialReject(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }).catch(err => {
        console.log(err.message);
      });
    })

  }
  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req = new ExpenseEntryApprovalDto()
      req.expenseEntryId = expenseId
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = ContractStatusEnum.AUDIT_APPROVAL;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks']
      expenseEntryService.approveExpense(req).then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage)
          window.location.reload();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)

        }
      }).catch((err) => {
        console.log('err : ', err)
      })
    })
  }



  const tableColumns: ColumnsType<any> = [
    {
      title: 'Exp Ref',
      dataIndex: 'costingNo',
      render: (value, record) => {
        return <>
          < a href={`/#/${routes(record.expenseType) + record.costingId}`} className="link-primary" >
            {value}
          </a >
        </>
      }

    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },

    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (value, row: PaymentNoticeGetDataDto, index) => {
        const bnValues = [...new Map(row.paymentNoticeTransactions.map((rec) => [rec.businessNo, rec])).values()]
        return (
          <>
            {new Set(row.paymentNoticeTransactions.filter(rec => (rec.businessNo !== null || rec.businessNo != undefined)
            )).size ? bnValues.map((rec, index) => {
              const comma = index !== bnValues.length - 1 ? ' , ' : '';
              const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`
              return <>
                {<a href={link}>{rec.businessNo + ` (${rec.businessNoName})` + comma}</a>}
              </>
            }) : 'NA'}

          </>
        );
      }
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',

      render: (value, row: PaymentNoticeGetDataDto, index) => {
        const bg = [];
        row.paymentNoticeTransactions?.forEach((rec) => {
          if (rec.bargeNo)
            bg.push({ bargeNo: rec.bargeNo, bargeId: rec.bargeId, bargeNomination: rec.bargeNomination })
        });
        return (
          <>
            {new Set(row.paymentNoticeTransactions.filter(rec => (rec.bargeNo !== null || rec.bargeNo !== undefined)
            )).size ? bg.map((rec, index) => {
              const comma = index !== bg.length - 1 ? ' , ' : '';
              const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
              return <a href={`${link}`}>{SequenceUtils.formatNumberToSpecificLength(rec?.bargeNo) + ` (${rec.bargeNomination})` + comma}</a>
            }) : 'NA'}
          </>
        );
      }
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
      }
    }
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const renderItems = (record: any, index, indent, expanded) => {
    return <>
      <Card>
        <AccountPayableCostings
          costingId={record.costingId}
          costingType={record.expenseType}
          remainingAmount={0}
        />

        <Form
          form={formRef}
          // initialValues={initialValues}
          layout='horizontal'
          autoComplete='off'
        >
          <Row className='pt-2'>
            <Col span={16}>
              <Form.Item style={{ width: '100%' }} name='remarks' label="Work Log"
                rules={[
                  {
                    required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                  }
                ]}>
                <Input style={{ width: '95%' }} />
              </Form.Item>
            </Col>
            <Col span={6} />
            <Col>
              <Button onClick={() => { rejectedHandler(record.expenseType, record.costingId) }}>Reject</Button>
            </Col>
          </Row>
        </Form>
        <br />

        <InvoiceDetails
          costingId={record.costingId}
          status={ContractStatusEnum.ACTIVE}
          level={ContractStatusEnum.AUDIT_APPROVAL}
          invoiceStatus={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
        />

      </Card>

    </>
  };

  return (
    <Card title={<span style={{ color: 'white' }}>Audit {props.type} Approvals </span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
      <Input.Search
        placeholder="Search"
        allowClear
        onChange={(e) => { setSearchedText(e.target.value) }}
        onSearch={(value) => { setSearchedText(value) }}
        style={{ width: 200, float: "right" }}
      />
      <Table
        expandIconColumnIndex={0}
        className='contracts'
        rowKey={record => record.costingId}
        columns={tableColumns}
        dataSource={paymentNoticeData}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right'
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <UpCircleFilled
              onClick={(e) => onExpand(record, e)}
            >
              Collapse
            </UpCircleFilled>
          ) : (
            <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
          )
        }
        scroll={{ x: 500 }}
        size="large"
        bordered
      />

    </Card>
  )
}