import { ContractTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { AshModel } from './ash.model';

export interface IASHData {
    ash: AshModel;
    addendumAsh: AshModel;
    contractType: ContractTypesEnum;
}
const ASHDataView = (props: IASHData) => {
    const { contractType } = props;
    const [ashDataSource, setASHDataSource] = useState([]);
    const [addendumAshDataSource, setAddendumASHDataSource] = useState([]);

    useEffect(() => {
        if (props.ash)
            setASHDataSource(constructDataSource(props.ash));
        if (props.addendumAsh)
            setAddendumASHDataSource(constructDataSource(props.addendumAsh));
    }, [props.ash]);

    const sharedOnCell = (_: any, index: number) => {
        if ([0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20].includes(index)) {
            return { colSpan: 0 };
        }
        return {};
    };
    const constructDataSource = (ash: AshModel) => {
        let dataSource = [];
        dataSource.push({
            basis: 'SiO2 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashSio2Basis ? ash.ashSio2Basis : '-',
            typical: ash.ashSio2Typical ? ash.ashSio2Typical : '-',
            rejection: ash.ashSio2Rejection ? ash.ashSio2Rejection : '-',
        });
        dataSource.push({
            basis: 'AI203 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashAi203Basis ? ash.ashAi203Basis : '-',
            typical: ash.ashAi203Typical ? ash.ashAi203Typical : '-',
            rejection: ash.ashAi203Rejection ? ash.ashAi203Rejection : '-',
        });

        dataSource.push({
            basis: 'Fe203 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashFe203Basis ? ash.ashFe203Basis : '-',
            typical: ash.ashFe203Typical ? ash.ashFe203Typical : '-',
            rejection: ash.ashFe203Rejection ? ash.ashFe203Rejection : '-',
        });

        dataSource.push({
            basis: 'CaO (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashCaoBasis ? ash.ashCaoBasis : '-',
            typical: ash.ashCaoTypical ? ash.ashCaoTypical : '-',
            rejection: ash.ashCaoRejection ? ash.ashCaoRejection : '-',
        });

        dataSource.push({
            basis: 'MgO (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashMgoBasis ? ash.ashMgoBasis : '-',
            typical: ash.ashMgoTypical ? ash.ashMgoTypical : '-',
            rejection: ash.ashMgoRejection ? ash.ashMgoRejection : '-',
        });

        dataSource.push({
            basis: 'Na2O (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashNa2oBasis ? ash.ashNa2oBasis : '-',
            typical: ash.ashNa2oTypical ? ash.ashNa2oTypical : '-',
            rejection: ash.ashNa2oRejection ? ash.ashNa2oRejection : '-',
        });

        dataSource.push({
            basis: 'K2O (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashK2oBasis ? ash.ashK2oBasis : '-',
            typical: ash.ashK2oTypical ? ash.ashK2oTypical : '-',
            rejection: ash.ashK2oRejection ? ash.ashK2oRejection : '-',
        });

        dataSource.push({
            basis: 'TiO2 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashTio2Basis ? ash.ashTio2Basis : '-',
            typical: ash.ashTio2Typical ? ash.ashTio2Typical : '-',
            rejection: ash.ashTio2Rejection ? ash.ashTio2Rejection : '-',
        });

        dataSource.push({
            basis: 'Mn3O4 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashMn3o4Basis ? ash.ashMn3o4Basis : '-',
            typical: ash.ashMn3o4Typical ? ash.ashMn3o4Typical : '-',
            rejection: ash.ashMn3o4Rejection ? ash.ashMn3o4Rejection : '-',
        });

        dataSource.push({
            basis: 'SO3 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashSo3Basis ? ash.ashSo3Basis : '-',
            typical: ash.ashSo3Typical ? ash.ashSo3Typical : '-',
            rejection: ash.ashSo3Rejection ? ash.ashSo3Rejection : '-',
        });

        dataSource.push({
            basis: 'P2O5 (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ash.ashP2o5Basis ? ash.ashP2o5Basis : '-',
            typical: ash.ashP2o5Typical ? ash.ashP2o5Typical : '-',
            rejection: ash.ashP2o5Rejection ? ash.ashP2o5Rejection : '-',
        });
        return dataSource;
    };

    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumAshDataSource?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const ashColumns: ColumnsType<any> = [
        {
            title: 'Basis',
            key: 'basis',
            dataIndex: 'basis',
            onCell: (_, index) => ({
                colSpan: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20].includes((index as number)) ? 3 : 1,
            }),
            render: (value, row: any, index) => (
                <a>{valiDateWithSnapShotData(index, 'basis', value)}</a>
            ),
        },
        {
            title: 'Typical',
            key: 'typical',
            dataIndex: 'typical',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'typical', value)}</span>
            ),
        },
        {
            title: 'Rejection',
            key: 'rejection',
            dataIndex: 'rejection',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'rejection', value)}</span>
            ),
        }
    ];

    return (
        <Table columns={ashColumns} dataSource={ashDataSource} bordered pagination={false} />
    )
}

export default ASHDataView;