import { BargeCostingsEnum, BargeCostingsService, BgDemurrageApproval, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerCategoryReqDto, BusinessPartnerTypeEnum, CompanyCodeReq, CompanyTdsService, ContractIdRequest, ContractModesEnum, ContractStatusEnum, ContractStatusReqDto, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, GetDataDemurrageDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, TaxesAndItemsService, TdsResModel } from "@exportx/shared-models-and-services";
import { getLocalFormat } from "@exportx/ui-utils";
import { Button, Card, Checkbox, Col, Drawer, Form, Input, Radio, RadioChangeEvent, Row, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../common";
import DatePicker from "../../../common/data-picker/date-picker";
import { DemurrageList } from "./demurrage-entry-list";
import {ApprovalTracking} from "../costing-detail-view";
interface IProps {
    demurrageApproval?: boolean
    approvalInitialValues?: any;
    demurrageStatus?: ContractStatusEnum;
    approvalLevel?: ContractStatusEnum;
    setDemurrageForm?: (value) => void;
}

export const Demurrage = (props: IProps) => {
    const { authContext } = useAuthState()
    const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
    const [formRef] = Form.useForm()
    const businessNumberService = new BusinessNumberService();
    const bargeCostingService = new BargeCostingsService();
    const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
    const [initialValues, setInitialValues] = useState<any>({ baseCurrency: authContext.defaultPlantCurrency });
    const [filter, setFilter] = useState<any>({});
    const { Option } = Select;
    const { demurrageApproval, approvalInitialValues, demurrageStatus, setDemurrageForm, approvalLevel } = props;
    const [supplier, setSupplier] = useState<any[]>();
    const companyTdsService = new CompanyTdsService();
    const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
    const [taxes, setTaxes] = useState<GetTaxesByBpIdDto[]>([]);
    const taxesAndItemsService = new TaxesAndItemsService();
    const [account, setAccount] = useState<any>();
    const defaultCurrency = authContext.defaultPlantCurrency;
    const [contractsDropDown, setContractsDropDown] = useState<any>()
    const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
    const currencyService = new CurrencyService();
    const currencyConverterService = new CurrencyConverterService();
    const [exChangeDate, setExChangeDate] = useState<boolean>(false)
    const [costLabelName, setCostLabelName] = useState<string>('');
    let location = useLocation();
    let paramsId = queryString.parse(location.search).costingId;
    const [currency, setCurrency] = useState<string>(authContext.defaultPlantCurrency)
    const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
    const [gridDrawer, setGridDrawer] = useState<boolean>(false);
    const [dummyRefresh, setDummyRefresh] = useState<number>(1)



    useEffect(() => {
        getBusinessNoDropDown();
        getDemurrageData();
        getTdsData();
        getTaxesByItemsId();
        getCurrency();
    }, [])

    useEffect(() => {
        formRef.resetFields()
        formRef.setFieldsValue(approvalInitialValues);
        tdsOnchange(approvalInitialValues?.companyTdsId)
        getTdsData();
        getTaxesByItemsId();
        businessNoOnChange(approvalInitialValues?.businessNo);
        setAccount(approvalInitialValues?.account);
        accountOnChange(approvalInitialValues?.supplierName);
        demurrageRateOnchange(approvalInitialValues?.demurrgeRatePerDay);
        exChangeDateHandler(approvalInitialValues?.exchangeDate);
        currencyOnChangeHandler(approvalInitialValues?.baseCurrency);
        if (approvalInitialValues) {
            formRef.setFieldValue('baseCurrency', approvalInitialValues?.baseCurrency);
            formRef.setFieldValue('supplierId', approvalInitialValues?.supplierId);
            formRef.setFieldValue('stowagePlanInMt', approvalInitialValues?.stowagePlanInMt);
            formRef.setFieldValue('supplierName', approvalInitialValues?.supplierName);
            formRef.setFieldValue('baseCurrency', approvalInitialValues?.baseCurrency);
            formRef.setFieldValue('contractNo', approvalInitialValues?.contractNo);
            if (approvalInitialValues?.quoteCurrency) {
                currencyOnChangeHandler(approvalInitialValues?.quoteCurrency);
            }
            if (approvalInitialValues?.exchangeDate) {
                setExChangeDate(true)
            }
        }
    }, []);
    console.log(approvalInitialValues?.exchangeDate, "(approvalInitialValues?.exchangeDate")
    useEffect(() => {
        formRef.setFieldsValue(initialValues);
        tdsOnchange(initialValues?.companyTdsId);
        businessNoOnChange(initialValues?.businessNo)
        setAccount(initialValues?.account);
        demurrageRateOnchange(initialValues?.demurrgeRatePerDay);
        //currencyOnChangeHandler(initialValues?.baseCurrency,);
        currencyOnChangeHandler(initialValues?.quoteCurrency);
        exChangeDateHandler(initialValues?.exchangeDate);
        if (initialValues) {
            formRef.setFieldValue('baseCurrency', initialValues?.baseCurrency);
            //formRef.setFieldValue('quoteCurrency',initialValues?.quoteCurrency)
            formRef.setFieldValue('supplierId', initialValues?.supplierId);
            formRef.setFieldValue('stowagePlanInMt', initialValues?.stowagePlanInMt);
            formRef.setFieldValue('supplierName', initialValues?.supplierName);

            if (initialValues?.quoteCurrency) {
                formRef.setFieldValue('baseCurrency', initialValues?.quoteCurrency);
            }
            if (initialValues?.baseCurrency) {
                formRef.setFieldValue('quoteCurrency', initialValues?.baseCurrency);
            }
        }
    }, []);



    useEffect(() => {
        if (paramsId && gridData) {
            setInitialValues(gridData?.find((rec) => rec?.bgDemurrageId === paramsId));
        };
    }, [gridData]);


    useEffect(() => {
        accountOnChange(initialValues?.supplierName);
    }, [initialValues?.supplierName])

    if (initialValues?.demurrageDate) {
        initialValues.demurrageDate = moment(initialValues?.demurrageDate);
    };

    if (approvalInitialValues?.demurrageDate) {
        approvalInitialValues.demurrageDate = moment(approvalInitialValues?.demurrageDate);
    };

    if (initialValues?.exchangeDate) {
        initialValues.exchangeDate = moment(initialValues?.exchangeDate)
    };

    if (approvalInitialValues?.exchangeDate) {
        approvalInitialValues.exchangeDate = moment(approvalInitialValues?.exchangeDate)
    };

    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessNumberService.dropDownForDemurrage(req).then(res => {
            if (res.status) {
                setBusinessDropDown(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }


    const currencyOnChangeHandler = (value) => {
        if (value) {
            setExChangeDate(value !== authContext.defaultPlantCurrency);
            getCurrencyExchangeRate(value, authContext.defaultPlantCurrency, formRef.getFieldValue('exchangeDate'))
        } else {
            setExChangeDate(false);
        }
    };
    const suffixSelector = (
        <Form.Item name="baseCurrency" noStyle label="Please Select">
            <Select onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 80 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );




    const getCurrencyExchangeRate = (fromCurrency, toCurrency, date) => {
        const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'));
        if(moment(req.exchangeDate).format('YYYY-MM-DD') ==='Invalid date'){
            return;
        }
        currencyConverterService.getCurrencyExchangeRate(req).then(res => {
            if (res.status) {
                setFilter((prev) => {
                    return {
                        ...prev, exchangeRate: Number(res.data.exchangeRate), displayExchangeRate: res.data.displayExchangeRate
                    };
                });
            }
            else {
                delete filter?.exchangeRate;
            };
            daysDiff();
        }).catch(err => { console.log(err.message) });
    };



    const daysDiff = () => {
        const timeCompletedForLoadMV = formRef.getFieldValue('timeCompletedForLoadMV');
        const allowedTimeToLoad = formRef.getFieldValue('allowedTimeToLoad');
        const validation = Number(timeCompletedForLoadMV) - Number(allowedTimeToLoad);
        const noOfDaysDifference = Number(validation.toFixed(10));
        if (noOfDaysDifference < 0) {
            setCostLabelName('Dispatch')
        } else if (noOfDaysDifference > 0) {
            setCostLabelName('Demurrage')
        } else {
            setCostLabelName(' ')
        }
        setFilter((prev) => {
            let multiplier = noOfDaysDifference < 0 ? Number(prev?.dispatchRatePerDay) : Number(prev?.demurrgeRatePerDay);
            let totalAmount: number;
            let cost: number
            if (prev.exchangeRate > 0) {

                cost = Math.abs(Number(noOfDaysDifference)) * Number(multiplier) * (prev.exchangeRate ? prev.exchangeRate : 1);
                totalAmount = Math.abs(Number(noOfDaysDifference)) * Number(multiplier);
            } else {
                totalAmount = Math.abs(Number(noOfDaysDifference)) * Number(multiplier);
                cost = Math.abs(Number(noOfDaysDifference)) * Number(multiplier)
            };
            return {
                ...prev, timeCompletedForLoadMV, noofDaysDifference: Math.abs(Number(noOfDaysDifference.toFixed(10))), cost, totalAmount
            }

        });
    }

    const exChangeDateHandler = (value) => {
        getCurrencyExchangeRate(formRef.getFieldValue('baseCurrency'), authContext.defaultPlantCurrency, value)
    }


    const businessNoOnChange = (value) => {
        formRef.setFieldValue('supplierId', undefined);
        formRef.setFieldValue('stowagePlanInMt', undefined);
        formRef.setFieldValue('supplierName', undefined);
        formRef.setFieldValue('contractNo', undefined);

        if (value) {
            supplierDropDown(value, formRef.getFieldValue('account'))
            const filteredValue = businessDropDown?.filter((rec) => {
                return rec.bnId === value;
            })
            const accountType = formRef.getFieldValue('account');
            formRef.setFieldValue('stowagePlanInMt', filteredValue[0]?.stowagePlanInMt);
            setFilter(prev => { return { ...prev, account: accountType, bnLoadRatePerDay: filteredValue[0]?.loadRatePerDay } })
        }
    }


    const getTdsData = () => {
        const req = new CompanyCodeReq();
        req.company = authContext.defaultPlant;
        req.date = approvalInitialValues?.demurrageDate ? approvalInitialValues?.demurrageDate : formRef.getFieldValue('demurrageDate');
        companyTdsService.getTdsByCompanyCode(req).then(res => {
            if (res.status) {
                setTdsModel(res.data);
            } else {
                setTdsModel([]);
            }
        }).catch(err => {
            console.log(err);
            setTdsModel([]);
        });
    }


    const getTaxesByItemsId = () => {
        const req = new ItemsIdRequestDto(ItemsCodesEnum.BARGE, approvalInitialValues?.demurrageDate)
        taxesAndItemsService.getVatByItemsId(req).then(res => {
            if (res.status) {
                setVat(res.data);
            } else {
                setVat([]);
            }
        }).catch(err => {
            console.log(err);
            setVat([]);
        });
    }

    const submit = () => {
        formRef.validateFields().then(values => {
            const allowedTimeToLoad = values.allowedTimeToLoad
            const req = { ...values, ...filter, userName: authContext.user.userName, plantCode: authContext.defaultPlant, bgDemurrageId: initialValues?.bgDemurrageId, versionFlag: initialValues?.versionFlag, baseCurrency: authContext.defaultPlantCurrency, exchangeRate: filter.exchangeRate, quoteCurrency: formRef.getFieldValue('baseCurrency'), costingType: BargeCostingsEnum.DEMURRAGE, allowedTimeToLoad, exchangeDate: formRef.getFieldValue('exchangeDate') }
            bargeCostingService.createBgDemurrage(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    getDemurrageData();
                    formRef.resetFields();
                    setFilter(undefined);
                    tdsOnchange(undefined);
                    setExChangeDate(false);
                    window.location.reload();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        })

    }

    const getDemurrageData = () => {
        const req = new ContractStatusReqDto(demurrageStatus, authContext.defaultPlant)
        bargeCostingService.getDemurrageData(req).then(res => {
            if (res.status) {
                setGridData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }
    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        supplierDropDown(formRef.getFieldValue('businessNo'), value);
        setAccount(value);
        const resetFields = businessDropDown.filter((rec) => {
            return rec.bnId === formRef.getFieldValue('businessNo');
        })
        if (resetFields !== formRef.getFieldValue('businessNo')) {
            setFilter(undefined);
            setContractsDropDown(undefined);
            formRef.setFieldValue('pricePerMT', undefined);
            formRef.setFieldValue('businessNo', undefined);
            formRef.setFieldValue('supplierId', undefined);
            formRef.setFieldValue('timeCompletedForLoadMV', undefined);
            formRef.setFieldValue('allowedTimeToLoad', undefined);
            formRef.setFieldValue('demurrgeRatePerDay', undefined);
            formRef.setFieldValue('supplyTonnage', undefined);
            formRef.setFieldValue('stowagePlanInMt', undefined);
            formRef.setFieldValue('contractNo', undefined);
            formRef.setFieldValue('supplierName', undefined);

        }
    }

    const supplierDropDown = (businessNo, type) => {
        const req = new BusinessPartnerCategoryReqDto(businessNo, type, authContext.defaultPlant)
        businessNumberService.allocatedBpNames(req).then(res => {
            if (res.status) {
                setSupplier(res.data);
            } else {
                setSupplier([]);
            }
        }).catch(err => {
            console.log(err.message)
        })
    }



    const accountOnChange = (value) => {
        getContractsDropDownThroughBpId(value);
    }




    const contractOnChange = (value) => {
        const type = account === BusinessPartnerTypeEnum.CUSTOMER ? ContractModesEnum.SALES : account === BusinessPartnerTypeEnum.VENDOR ? ContractModesEnum.PURCHASE : undefined
        const req = new ContractIdRequest(value, authContext.defaultPlant, type)
        bargeCostingService.getContractsLoadingRate(req).then(res => {
            if (res.status) {
                setFilter((prev) => { return { ...prev, loadRatePerDay: res.data.loadRatePerDay } });
            } else {
                delete filter?.loadRatePerDay;

            }
        }).catch(err => console.log(err.message));
    }

    const getContractsDropDownThroughBpId = (value) => {
        const req = new BusinessPartnerCategoryReqDto(formRef.getFieldValue('businessNo'), account ? account : initialValues?.account ? initialValues?.account : approvalInitialValues?.account, authContext.defaultPlant, value)
        bargeCostingService.getContractsDropDownThroughBpId(req).then(res => {
            if (res.status) {
                setContractsDropDown(res.data);
            } else {
                setContractsDropDown([]);
                formRef.resetFields(['contractNo']);
                delete filter?.loadRatePerDay;

            }
        }).catch(err => console.log(err.message));
    }



    const getCurrency = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrencies(res.data)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message))
    }



    const supplyTunnageOnchange = (value) => {
        setFilter((prev) => {
            const allowedTime = Number(value) / Number(prev.loadRatePerDay);
            formRef.setFieldValue('allowedTimeToLoad', allowedTime.toFixed(10));
            return { ...prev, allowedTimeToLoad: allowedTime.toFixed(10) };
        });
    }

    const demurrageRateOnchange = (value) => {
        setFilter((prev) => {
            return { ...prev, demurrgeRatePerDay: Number(value), dispatchRatePerDay: value * 0.5 }
        });
    }
    let status = ContractStatusEnum.PENDING_AUDIT_APPROVAL;
    if (approvalLevel === ContractStatusEnum?.BUSINESS_APPROVAL) {
        status = ContractStatusEnum.PENDING_AUDIT_APPROVAL
    }
    else if (approvalLevel === ContractStatusEnum?.AUDIT_APPROVAL) {
        status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL
    } else status = ContractStatusEnum.ACTIVE
    const approve = () => {
        formRef.validateFields().then(values => {
            const req = new BgDemurrageApproval(approvalInitialValues?.bgDemurrageId, authContext.user.userName, authContext.defaultPlant, approvalInitialValues.account, approvalInitialValues.supplyTonnage, approvalInitialValues.loadRatePerDay, approvalInitialValues.demurrageRatePerDay, approvalInitialValues.dispatchratePerDay, approvalInitialValues.allowedTimeToLoad, approvalInitialValues.timeCompletedForLoadMV, approvalInitialValues.noofDaysDifference, approvalInitialValues.cost, approvalInitialValues.totalAmount, approvalInitialValues?.demurrageAccountId, approvalInitialValues?.bnId, filter?.vat, values?.companyTdsId, filter?.tds, filter?.vatValue, filter?.taxId, status, approvalLevel)
            // if (demurrageStatus === ContractStatusEnum.ACTIVE) {
            bargeCostingService.demurrageApproval2(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    getDemurrageData();
                    setDemurrageForm(false);
                    setExChangeDate(false)
                    window.location.reload();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message)
            })
            // } else {
            //     bargeCostingService.demurrageApproval(req).then(res => {
            //         if (res.status) {
            //             AlertMessages.getSuccessMessage(res.internalMessage);
            //             getDemurrageData();
            //             setDemurrageForm(false)
            //             window.location.reload();
            //         } else {
            //             AlertMessages.getErrorMessage(res.internalMessage);
            //         }
            //     }).catch(err => {
            //         console.log(err.message)
            //     })
            // }

        })
    }

    const reject = () => {
        formRef.validateFields().then(values => {
            const req = new BgDemurrageApproval(approvalInitialValues?.bgDemurrageId, authContext.user.userName, authContext.defaultPlant, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, approvalInitialValues?.demurrageAccountId, approvalInitialValues?.bnId, undefined, undefined, undefined, undefined, undefined)
            // if (demurrageStatus === ContractStatusEnum.ACTIVE) {
            bargeCostingService.demurrageRejected2(req)
                .then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getDemurrageData();
                        setDemurrageForm(false)
                        window.location.reload();
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
            // } else {
            //     bargeCostingService.demurrageRejected(req)
            //         .then(res => {
            //             if (res.status) {
            //                 AlertMessages.getSuccessMessage(res.internalMessage);
            //                 getDemurrageData();
            //                 setDemurrageForm(false)
            //                 window.location.reload();
            //             } else {
            //                 AlertMessages.getErrorMessage(res.internalMessage)
            //             }
            //         }).catch(err => {
            //             console.log(err.message)
            //         })
            // }

        }).catch(err => {
            console.log(err.message)
        })

    }


    const tdsOnchange = (tdsId) => {
        const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
        if (tdsId) {
            setFilter(prevState => {
                const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
                const tds = (tdsPercentage / 100) * prevState.cost;
                const vatValue = prevState.vatValue;
                return {
                    ...prevState,
                    tdsId,
                    tdsPercentage,
                    tds,
                    totalAmount: (prevState?.cost + vatValue) - tds
                }
            })
        } else {
            setFilter(prevState => {
                const tdsPercentage = 0;
                const totalAmount = (prevState?.cost + prevState?.tds);
                return {
                    ...prevState,
                    tdsId,
                    tdsPercentage,
                    tds: 0,
                    totalAmount
                }
            })
        }
    }
    const vatOnchange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const taxId = vat[0]?.taxId ? vat[0]?.taxId : null;
            const taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
            setFilter(prevState => {
                const vatValue = (taxPercentage / 100) * prevState.cost;
                const tds = prevState.tds;
                return {
                    ...prevState,
                    taxId,
                    taxPercentage,
                    vatValue,
                    totalAmount: (prevState.cost + vatValue) - tds,
                    vat: e.target.checked
                }
            })
        } else {
            const taxId = null;
            const taxPercentage = 0;
            setFilter(prevState => {
                const totalAmount = (prevState.cost - prevState.vatValue);
                return {
                    ...prevState,
                    taxId,
                    taxPercentage,
                    vatValue: 0,
                    totalAmount: totalAmount,
                    vat: e.target.checked
                }
            })
        }

    }
    const defaultValuesSetter = (values) => {
        setInitialValues(values);
    };

    return (
        <Card title={<span style={{ color: 'white' }}>MV Demurrage</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
            extra={<Button
                onClick={() => { setGridDrawer(true); setDummyRefresh(dummyRefresh + 1); }}
                className='panel_button'
            >View</Button>

            }>
            <Row>
                <Col span={11}>
                    <Form
                        form={formRef}
                        initialValues={initialValues}
                        autoComplete={"off"}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        style={{ maxWidth: 600 }}
                    >


                        <Card
                            title={

                                <Row className="mt-2">
                                    <Col span={8}>
                                        {demurrageStatus ?

                                            <Form.Item
                                                label={"Date"}>
                                                {
                                                    <Col  >
                                                        <span >
                                                            <b>
                                                                {approvalInitialValues?.demurrageDate ? moment(approvalInitialValues?.demurrageDate).format("YYYY-MM-DD") : ''}
                                                            </b>
                                                        </span>
                                                    </Col>
                                                }
                                            </Form.Item>

                                            :
                                            <Form.Item
                                                name={'demurrageDate'}
                                                label="Date"
                                                initialValue={moment()}
                                            >
                                                <DatePicker defaultValue={moment()} />
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col span={5}>

                                    </Col>

                                    <Col span={8}>
                                        <Form.Item name='account' initialValue={initialValues?.account}>
                                            <Radio.Group disabled={approvalInitialValues?.account ? true : false} onChange={radioOnChange} >
                                                <Radio value={`${BusinessPartnerTypeEnum.VENDOR}`}>Against Seller</Radio>
                                                <Radio value={`${BusinessPartnerTypeEnum.CUSTOMER}`}>Against Buyer</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            }
                        >

                            <Form.Item
                                label={`${demurrageStatus ? "MV:" : "Select MV:"}`}
                                initialValue={initialValues?.businessNo}
                                name="businessNo"
                                rules={[{ required: demurrageStatus ? false : true, message: "Business Number is Required" }]}
                            >
                                {demurrageStatus ?
                                    <span  >
                                        <b>{approvalInitialValues?.businessNumber}</b>
                                    </span> :
                                    <Select
                                        disabled={formRef.getFieldValue('account') ? false : true}
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        allowClear
                                        placeholder='Select Business No'
                                        onChange={(value, text) => businessNoOnChange(value)}
                                        showSearch
                                    >
                                        {businessDropDown.map(item => {
                                            return <Option value={item.bnId}>{item.motherVesselName + " " + " " + `(${item.businessNo})`}</Option>
                                        })}
                                    </Select>
                                }
                            </Form.Item>

                            <Form.Item
                                label={`${demurrageStatus ? "Supplier:" : `Select ${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''}:`}`}
                                initialValue={initialValues?.supplierName}
                                name="supplierName"
                                rules={[{ required: demurrageStatus ? false : true, message: `${account === BusinessPartnerTypeEnum.VENDOR ? "Supplier" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Buyer" : ''} is Required` }]}
                            >
                                {demurrageStatus ? <span> <b>{approvalInitialValues?.bpName}</b></span> :
                                    <Select
                                        disabled={formRef.getFieldValue('businessNo') ? false : true}
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        allowClear
                                        style={{ width: '100%', }}
                                        placeholder='Select Account'
                                        onChange={accountOnChange}
                                    >
                                        {supplier?.map(item => {
                                            return <Option value={item.bpId}>{item.bpName}</Option>
                                        })}
                                    </Select>
                                }
                            </Form.Item>


                            <Form.Item name={"stowagePlanInMt"} label={' MV Stowage plan'}>
                                <Input placeholder="MV Capacity" disabled={true} type={'number'}></Input>
                            </Form.Item>

                            <Form.Item name={"contractNo"} label={`Select ${account === BusinessPartnerTypeEnum.VENDOR ? "Purchase" : account === BusinessPartnerTypeEnum.CUSTOMER ? "Sale" : ''}:`}>
                                <Select
                                    disabled={approvalInitialValues?.contractNo ? true : false}
                                    onChange={contractOnChange}
                                    placeholder={'Select Contract No'}
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch>
                                    {contractsDropDown?.map((rec) => {
                                        return <Option value={rec.id}>{rec.contractNo}</Option>
                                    })}
                                </Select>
                            </Form.Item>


                            {demurrageStatus ?
                                <Form.Item
                                    label="Supply Tonnage">
                                    <span
                                    ><b>{filter?.supplyTonnage ? filter?.supplyTonnage : approvalInitialValues?.supplyTonnage ? approvalInitialValues?.supplyTonnage : initialValues?.supplyTonnage}</b></span>
                                </Form.Item>
                                :
                                <Form.Item label={' Supply Tonnage '} name="supplyTonnage" initialValue={filter?.supplyTonnage}>
                                    <Input type='number' onChange={(value) => supplyTunnageOnchange(value.target.value)} min={0} />
                                </Form.Item>}

                            <Form.Item label={<b>Load Rate Per Day</b>}>
                                <span ><b>{filter?.loadRatePerDay ? filter?.loadRatePerDay : approvalInitialValues?.loadRatePerDay ? approvalInitialValues?.loadRatePerDay : initialValues?.loadRatePerDay}</b></span>

                            </Form.Item>

                            {/* {demurrageStatus ? */}
                            {/* <Form.Item label="Demurrage Rate Per Day">
                                <span  ><b>{getLocalFormat(approvalInitialValues?.demurrgeRatePerDay, currency)}</b></span>
                            </Form.Item> */}
                            {/* : */}
                            <Form.Item label={'Demurrage Rate Per Day'} name="demurrgeRatePerDay">
                                <Input addonAfter={suffixSelector} type='number' placeholder={'0.0000'}
                                    onChange={(value) => demurrageRateOnchange(value.target.value)}
                                />
                            </Form.Item>
                            {/* } */}

                            <Form.Item label={<b>Dispatch Rate Per Day</b>}> <span  ><b>{filter?.dispatchRatePerDay ? filter?.dispatchRatePerDay : approvalInitialValues?.dispatchRatePerDay ? approvalInitialValues?.dispatchRatePerDay : initialValues?.dispatchRatePerDay}</b></span> </Form.Item>

                            {demurrageStatus ?
                                <Form.Item label="Allowed Time to Load">
                                    <span ><b>{Number(approvalInitialValues?.allowedTimeToLoad).toFixed(10)}</b></span>
                                </Form.Item>
                                :
                                <Form.Item initialValue={filter?.allowedTimeToLoad} label={'Allowed Time to Load'} name="allowedTimeToLoad">
                                    <Input type='number' onChange={daysDiff} placeholder={'0.00'} min={0} />
                                </Form.Item>}

                            {demurrageStatus ?
                                <Form.Item label="Time completed for load MV">
                                    <span
                                    ><b>{approvalInitialValues?.timeCompletedForLoadMV}</b></span></Form.Item>
                                :
                                <Form.Item label={<b>Time completed for load MV</b>} name="timeCompletedForLoadMV">
                                    <Input type='number' onChange={daysDiff} placeholder={'0.00'} min={0} />
                                </Form.Item>}


                            <Form.Item label={<b>No.of Days difference</b>}>
                                <span  ><b>{filter?.noofDaysDifference ? filter?.noofDaysDifference : approvalInitialValues?.noofDaysDifference ? approvalInitialValues?.noofDaysDifference : initialValues?.noofDaysDifference}</b></span>

                            </Form.Item>



                            <Form.Item label={`${costLabelName} `}>
                                <span >
                                    <b>
                                        {getLocalFormat(filter?.cost ? filter?.cost : approvalInitialValues?.cost ? approvalInitialValues?.cost : initialValues?.cost, currency)}
                                    </b>
                                </span>
                            </Form.Item>

                            {exChangeDate ?
                                <>
                                    <Form.Item name={'exchangeDate'} label={'Exchange Date'}>
                                        <DatePicker onChange={exChangeDateHandler} />
                                    </Form.Item>
                                    <Form.Item label={'Exchange Rate'}>
                                        {filter?.displayExchangeRate}
                                    </Form.Item>
                                </>

                                :
                                <></>}


                            <Form.Item label={<b>Total Amount</b>}>
                                <span
                                ><b>{getLocalFormat(filter?.cost ? filter?.cost : approvalInitialValues?.cost ? approvalInitialValues?.cost : initialValues?.cost, defaultCurrency)}</b></span>
                            </Form.Item>


                            {demurrageStatus === ContractStatusEnum.ACTIVE ?
                                <>

                                    {/* todo */}
                                    {/* <FormItem name={"account"} label={"Select Account"}>
                                        <Select
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            allowClear

                                            placeholder='Select Account'
                                        >
                                            <Option value={'companyAccount'}>
                                                Company Account
                                            </Option>
                                            <Option value={"buyerAccount"}>
                                                Buyer Account
                                            </Option>
                                            <Option value={"supplierAccount"}>
                                                Supplier Account
                                            </Option>
                                        </Select>
                                    </FormItem> */}

                                    <Form.Item name={"vat"} valuePropName="checked" label={"VAT"}>
                                        <Checkbox
                                            onChange={vatOnchange}
                                        >Vat Applicable(Y/N)</Checkbox>
                                    </Form.Item >

                                    <FormItem label="VAT Value" >
                                        <b>{getLocalFormat(filter?.vatValue, currency)}</b>
                                    </FormItem>


                                    <Form.Item name='companyTdsId' label={<b>TDS</b>}>
                                        <Select
                                            onChange={tdsOnchange}
                                            placeholder='Select TDS'
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={null}>Not Applicable</Option>
                                            {tdsModel?.map((rec) => {
                                                return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <FormItem label={'TDS Value'}>
                                        <b>{getLocalFormat(filter?.tds, currency)}</b>
                                    </FormItem>


                                    <Form.Item label={<b>Payable Amount</b>}><span  ><b>{getLocalFormat(filter?.totalAmount, currency)}</b></span></Form.Item>



                                </>
                                : <></>
                            }

                            <b style={{ display: `${demurrageApproval ? "none" : ""}` }}>{initialValues?.demurrageEntryDate}</b>
                            <span style={{ paddingLeft: 300, display: `${demurrageApproval ? "none" : ""}` }}>
                                {/* <Button disabled={paramsId ? true : false} type="primary" onClick={submit}>Submit</Button> */}
                                <Button type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={submit}>Submit</Button>
                            </span>
                            {demurrageApproval &&
                                <ApprovalTracking history={approvalInitialValues?.workLog} />

                            }
                            <span style={{ paddingLeft: 250, display: `${demurrageApproval ? "" : "none"}` }}>
                                <Button type="primary" onClick={approve}>Approve</Button>
                            </span>
                            <span style={{ paddingLeft: 10, display: `${demurrageApproval ? "" : "none"}` }}>
                                <Button
                                    type="primary"
                                    disabled={approvalInitialValues?.approvalStatus2 === ContractStatusEnum.CANCELLED ? true : false}
                                    danger onClick={reject}>
                                    Reject
                                </Button>
                            </span>

                        </Card>
                    </Form>
                </Col>

            </Row>
            <Drawer
                open={gridDrawer}
                onClose={() => setGridDrawer(false)}
                key={dummyRefresh}
                width={'50%'}

            >
                <DemurrageList formRef={formRef} gridData={gridData} getDemurrageData={getDemurrageData} setInitialValues={defaultValuesSetter} demurrageApproval={demurrageApproval} />

            </Drawer>

        </Card >
    )

}
export default Demurrage
