import { BusinessNumberService, ContractIdRequest, ContractModesEnum } from '@exportx/shared-models-and-services'
import { Table, Typography } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../../common'

interface bnIProps {
    contractId: string;
    pcQuantityTotal: number;
    contractType?: ContractModesEnum;
}

const AssociatedBusinessNumbers = (props: bnIProps) => {
    const { contractId, pcQuantityTotal, contractType } = props
    const navigate = useNavigate();
    // GetContractAssociatedBusinessNumberDto    // businessNumberData state data type
    const [businessNumberData, setBusinessNumberData] = useState<any>();
    const { authContext } = useAuthState();
    const businessService = new BusinessNumberService();
    const { Text } = Typography;

    useEffect(() => {
        if (contractId) {
            getAssociatedBusinessNoByPcId();
        }
    }, [contractId])

    const getAssociatedBusinessNoByPcId = () => {
        const req = { ...new ContractIdRequest(contractId), plantCode: authContext.defaultPlant, contractType };
        businessService.getAssociatedBusinessNoByPcId(req).then(res => {
            if (res.status) {
                setBusinessNumberData(res.data)
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    let pathToreDirect = '/barge-detail-view';
    const redirectToFullView = (bargeId: string) => {
        navigate(pathToreDirect, { state: { bargeId } });
    }

    const toLocaleString = (value) => {
        return Number(value).toLocaleString();
    }

    // const allocationTotal = businessNumberData?.businessNoData.reduce((a, c) => a + Number(c.allocatedQuantity), 0);
    // const deliveredTotals = businessNumberData?.unassignedBusinessNoData.reduce((a, c) => a + Number(c.bargeQuantity), 0);
    // const deliveredBusiness = businessNumberData?.businessNoData.reduce((a, c) => a + Number(c.deliveredQuantity), 0)
    // const deliveredUnAssignQuantity = businessNumberData?.unassignedPartialQuantityData.reduce((a, c) => a + Number(c.remainingPartialQuantity), 0)
    // const deliveredTotal = Number(deliveredTotals) + Number(deliveredBusiness) + Number(deliveredUnAssignQuantity)
    // const availableQty = Number(pcQuantityTotal) - Number(deliveredTotal);
    // return (
    //     <>
    //         <table className="table table-bordered table-sm">
    //             <thead>
    //                 <tr>
    //                     <th className="text-center" colSpan={5}>
    //                         Associated Business Numbers
    //                     </th>
    //                 </tr>
    //                 <tr className="table-header">
    //                     <th className=" text-left">Business No</th>
    //                     <th className="text-left">Quality</th>
    //                     <th className="text-right">Contract Quantity</th>
    //                     <th className="text-right">Quantity Allocated</th>
    //                     <th className="text-right">Quantity Delivered</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {businessNumberData?.businessNoData.map((e, index) => (
    //                     <tr key={index}>
    //                         <td className="text-left">
    //                             <Link onClick={() => { navigate('/businessnumber') }}>
    //                                 {e.businessNo}{" "}
    //                                 {e.motherVesselName
    //                                     ? "( " + e.motherVesselName + " )"
    //                                     : ""}

    //                             </Link>

    //                         </td>
    //                         <td className="text-left">{e.quality}</td>
    //                         <td className="text-right">
    //                             {e.contractQuantity
    //                                 ? toLocaleString(e.contractQuantity)
    //                                 : ""}
    //                         </td>
    //                         <td className="text-right">
    //                             {e.allocatedQuantity
    //                                 ? toLocaleString(e.allocatedQuantity)
    //                                 : ""}
    //                         </td>
    //                         <td className="text-right">
    //                             {e.deliveredQuantity
    //                                 ? toLocaleString(e.deliveredQuantity)
    //                                 : ""}
    //                         </td>
    //                     </tr>
    //                 ))}
    //                 {businessNumberData?.unassignedPartialQuantityData.map(
    //                     (e, index) => (
    //                         <tr key={index}>
    //                             <td className="text-left">
    //                                 Unassigned Partial Quantity
    //                             </td>
    //                             <td className="text-left">{e.quality}</td>
    //                             <td className="text-right">
    //                                 {e.contractQuantity
    //                                     ? toLocaleString(e.contractQuantity)
    //                                     : ""}
    //                             </td>
    //                             <td className="text-right">-</td>
    //                             <td className="text-right">
    //                                 {e.remainingPartialQuantity
    //                                     ? toLocaleString(
    //                                         e.remainingPartialQuantity
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )
    //                 )}
    //                 {businessNumberData?.unassignedBusinessNoData.map(
    //                     (e, index) => (
    //                         <tr key={index}>
    //                             <td className="text-left">
    //                                 Unassigned &nbsp;{" "}
    //                                 <a className="link-primary" onClick={() => redirectToFullView(e.bgId)}>
    //                                     {SequenceUtils.formatNumberToSpecificLength(e.bargeId)}({e.bargeNomination})
    //                                 </a>
    //                             </td>
    //                             <td className="text-left">{e.quality}</td>
    //                             <td className="text-right">
    //                                 {e.contractQuantity
    //                                     ? toLocaleString(e.contractQuantity)
    //                                     : ""}
    //                             </td>
    //                             {/* <td className='text-right'>{e.allocated_quantity ? toLocaleString(e.allocated_quantity) : ""}</td> */}
    //                             <td className="text-right">-</td>
    //                             <td className="text-right">
    //                                 {e.bargeQuantity
    //                                     ? toLocaleString(e.bargeQuantity)
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )
    //                 )}
    //                 {(businessNumberData?.businessNoData.length > 0 ||
    //                     businessNumberData?.unassignedBusinessNoData.length > 0 ||
    //                     businessNumberData?.unassignedPartialQuantityData.length >
    //                     0) && (
    //                         <tr>
    //                             <td colSpan={3}></td>
    //                             <td
    //                                 className="text-right"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Total :{" "}
    //                                 {
    //                                     allocationTotal
    //                                         ? toLocaleString(
    //                                             allocationTotal
    //                                         )
    //                                         : 0}
    //                             </td>
    //                             <td
    //                                 className="text-right"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Totals :{" "}
    //                                 {deliveredTotal
    //                                     ? toLocaleString(
    //                                         deliveredTotal
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )}

    //                 {(businessNumberData?.businessNoData.length > 0 ||
    //                     businessNumberData?.unassignedBusinessNoData.length > 0 ||
    //                     businessNumberData?.unassignedPartialQuantityData.length >
    //                     0) && (
    //                         <tr>
    //                             <td colSpan={4}></td>
    //                             <td
    //                                 className="text-right text-success"
    //                                 style={{ fontWeight: "bold" }}
    //                             >
    //                                 Remaining : &nbsp;
    //                                 {availableQty
    //                                     ? toLocaleString(
    //                                         availableQty
    //                                     )
    //                                     : 0}
    //                             </td>
    //                         </tr>
    //                     )}
    //             </tbody>
    //         </table>

    //     </>

    // )

    const columns: any[] = [
        {
            title: 'Business No',
            dataIndex: 'businessNo',
            render: (value, record) => {
                let href = ''
                if (record.assigned === 'assigned') {
                    href = `/#/bn-detail-view?bn_Id=${record.businessNoId}`
                };
                if (record.assigned === 'unAssigned') {
                    href = `/#/barge-detail-view?barge_id=${record.bgUid}`
                };

                return <>
                    <a href={href} className="link-primary">{value}</a>
                </>
            }
        },
        {
            title: 'Laycan',
            dataIndex: 'laycan',
            align: 'center',
            render: (value) => {
                return <>
                    {moment(value.split('_')[0]).format('DD MMM YYYY') + "-" + moment(value.split('_')[1]).format('DD MMM YYYY')}
                </>
            }
        },
        {
            title: 'Quality',
            dataIndex: 'contractQuality',
            align: 'center',
        },
        {
            title: 'Contract Quantity',
            dataIndex: 'contractQuantity',
            align: 'center',
        },
        {
            title: 'Quantity Allocated',
            dataIndex: 'totalAllocatedQty',
            align: 'center',
        },
        {
            title: 'Quantity Delivered',
            dataIndex: 'redraftQty',
            align: 'center',
        },
        {
            title: 'Reaming',
            dataIndex: 'redraftQty',
            align: 'center',
            render: (value, record) => {
                const reaming = Number(record.contractQuantity) - Number(record.redraftQty)
                return <>
                    {Number(reaming).toFixed(2)}
                </>
            }
        },
    ]

    return <>

        <Table
            columns={columns}
            dataSource={businessNumberData}
            bordered={true}
            pagination={false}
            summary={(pageData) => {
                let totalAllocatedQty = 0;
                let reaming = 0;
                let redraftQty = 0;
                pageData.forEach((rec) => {
                    totalAllocatedQty += rec.totalAllocatedQty ? Number(rec.totalAllocatedQty) : 0;
                    reaming += Number(rec.contractQuantity) - Number(rec.redraftQty);
                    redraftQty += Number(rec.redraftQty);
                });
                return (
                    <>
                        <Table.Summary.Row className='tableFooter'>
                            <Table.Summary.Cell index={1} colSpan={4}> </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={1}>
                                <Text><b >Allocated Total :</b><span style={{paddingLeft:'10px',marginRight:'20px'}}>{totalAllocatedQty.toFixed(2)}</span></Text>
                                </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={1}><Text><b>Deliver Total :</b>{redraftQty.toFixed(2)}</Text></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2}><Text><b>Total :</b>{reaming.toFixed(2)}</Text></Table.Summary.Cell>
                        </Table.Summary.Row>

                    </>
                )
            }}
        >

        </Table>



    </>
}

export default AssociatedBusinessNumbers