
import { CreateRolesDto, GetAllRolesDto, GetAllUnitDropDownDto, RolesService, UnitIdDto, UnitsService } from '@exportx/shared-models-and-services';
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../common';
import { RoleSelection } from './role-selection';


interface IRolesFormProps {
  initialValues: GetAllRolesDto;
  // getAllRoles: () => void;
  closeButtonHandler: () => void;
  SelectedUnitsData: any[];
}

export const RolesForm = (props: IRolesFormProps) => {
  const [formRef] = Form.useForm();
  const { authContext } = useAuthState();
  const { Option } = Select;
  const rolesService = new RolesService();
  const { initialValues, closeButtonHandler, SelectedUnitsData } = props;
  const [unitsData, setUnitsData] = useState<GetAllUnitDropDownDto[]>([])
  const unitService = new UnitsService();
  const { formatMessage: fm } = useIntl();
  const [isAdmin, setIsAdmin] = useState<boolean>(false)

  const [mastersRef] = Form.useForm()
  const [businessPartnerRef] = Form.useForm()
  const [contractsRef] = Form.useForm()
  const [businessNoRef] = Form.useForm()
  const [bargeRef] = Form.useForm()
  const [costingsRef] = Form.useForm()
  const [costingDetailsRef] = Form.useForm()
  const [lcTrackersRef] = Form.useForm()
  const [accountsRef] = Form.useForm()
  const [userRef] = Form.useForm()
  const [samplingAnalysisRef] = Form.useForm()



  useEffect(() => {
    const features = initialValues?.featureIds?.split(',')
    if (initialValues) {
      setIsAdmin(features?.includes('1'))
    }
  }, [initialValues])

  useEffect(() => {
    formRef.setFieldValue('isAdmin', isAdmin)
  }, [isAdmin])


  useEffect(() => {
    getUnitsForDropDown(authContext.user.id)
  }, [])

  const onSubmit = () => {
    formRef.validateFields().then((values: CreateRolesDto) => {
      mastersRef.validateFields().then((res) => {
        businessPartnerRef.validateFields().then((busPart) => {
          contractsRef.validateFields().then((conRef) => {
            businessNoRef.validateFields().then((bnRef) => {
              bargeRef.validateFields().then((bgRef) => {
                costingsRef?.validateFields().then((costRef) => {
                  costingDetailsRef?.validateFields().then((costDetRef) => {
                    lcTrackersRef?.validateFields().then((lcTraRef) => {
                      accountsRef?.validateFields().then((accRef) => {
                        userRef?.validateFields().then((userRef) => {
                          samplingAnalysisRef?.validateFields().then((saRef) => {
                            let featuresIds: any[] = [
                              isAdmin ? 1 : 0,
                              ...Object.values(res)?.flat(1)?.filter(Boolean),
                              ...Object.values(busPart)?.flat(1)?.filter(Boolean),
                              ...Object.values(conRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(bnRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(bgRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(costRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(costDetRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(lcTraRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(accRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(userRef)?.flat(1)?.filter(Boolean),
                              ...Object.values(saRef)?.flat(1)?.filter(Boolean),
                            ]
                            console.log('featuresIds', featuresIds)
                            if (featuresIds.length === 1 && featuresIds.find(e => e === 0)) {
                              featuresIds = initialValues?.featureIds?.split(',')
                              console.log('if', featuresIds)
                            }
                            const req = new CreateRolesDto(values.roleName, Number(values.unitId), values.description, values.rolesId, values.versionFlag, featuresIds?.toString());
                            rolesService.createRoles(req).then(res => {
                              if (res.status) {
                                AlertMessages.getSuccessMessage(res.internalMessage);
                                // getAllRoles();
                                window.location.reload()
                                closeButtonHandler();
                              } else {
                                AlertMessages.getErrorMessage(res.internalMessage);
                              };
                            });
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })

        })

      })
    }).catch(err => {
      console.log(err.message, 'error msg')
    });
  };

  const getUnitsForDropDown = (req: UnitIdDto) => {
    unitService.getAllUnitsDropDown(req).then((res) => {
      if (res.status) {
        setUnitsData(res.data)
      } else {
        setUnitsData([]);
      }
    }).catch(err => console.log(err.message, 'err message'));

  }

  return (
    <div>
      <Form layout='vertical' form={formRef} initialValues={initialValues} >
        <Row>
          <Form.Item hidden name={'rolesId'}>
            <InputNumber />
          </Form.Item>
          <Form.Item hidden name={'versionFlag'}>
            <InputNumber />
          </Form.Item>
          <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} >
            <Form.Item name='roleName' label={fm({ id: "roles.common.name", defaultMessage: 'Name' })}
              rules={[
                { required: true, message: fm({ id: "common.fillTheName", defaultMessage: 'Please fill the name' }) },
                // { pattern: new RegExp(/^[A-Za-z_2\s]*$/), message: fm({ id:"common.lettersOnly", defaultMessage: 'Name contain letters only' }) }
              ]}>
              <Input placeholder={fm({ id: "roles.common.name", defaultMessage: 'Name' })} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
            <Form.Item name='description' label={fm({ id: "roles.common.description", defaultMessage: 'Description' })}
              rules={[
                { required: true, message: fm({ id: 'roles.form.descriptionRequired', defaultMessage: 'Description is required' }) }
              ]}>
              <Input placeholder={fm({ id: "roles.common.description", defaultMessage: 'Description' })} />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={24} lg={7} xl={5} xxl={7} offset={1}>
            <Form.Item name='unitId' label={fm({ id: "roles.common.unit", defaultMessage: 'Unit' })}
              rules={[
                { required: false, message: fm({ id: 'roles.form.unitRequired', defaultMessage: 'unit  is required' }) }
              ]}>
              <Select placeholder='Select Unit'>
                {SelectedUnitsData.map((rec) => {
                  return <Option value={rec.unitId}>{rec.name}</Option>
                })}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Row justify='end'>
          <Col>
            <Form.Item name={"isAdmin"} label={fm({ id: `Admin`, defaultMessage: "Admin" })}
              valuePropName="checked" initialValue={isAdmin}>
              <Switch onChange={(checked) => { setIsAdmin(checked) }} />
            </Form.Item>
          </Col>
          {/* <Col>
                        <Button type='primary' onClick={onSubmit}>{fm({ id: "common.submitButton", defaultMessage: 'Submit' })}</Button>
                    </Col> */}
        </Row>
        <RoleSelection
          mastersRef={mastersRef}
          businessPartnerRef={businessPartnerRef}
          contractsRef={contractsRef}
          businessNoRef={businessNoRef}
          bargeRef={bargeRef}
          costingsRef={costingsRef}
          costingDetailsRef={costingDetailsRef}
          lcTrackersRef={lcTrackersRef}
          accountsRef={accountsRef}
          userRef={userRef}
          samplingAnalysisRef={samplingAnalysisRef}
          initialValues={initialValues}
        />
        <br></br>
        <Row justify='end'>
          <Col>
            <Button type='primary' onClick={onSubmit}>{fm({ id: "common.submitButton", defaultMessage: 'Submit' })}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}