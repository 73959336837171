import { Affix, Button, Card, Col, Form, Layout, Row, Space, Tag } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ContractIdRequest, ContractModesEnum, ContractStatusEnum, ContractTypesEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PurchaseContractDto, PurchaseContractService, TaxesAndItemsService, } from '@exportx/shared-models-and-services';
import { AlertMessages, FileUploadForm, isPermissionExist, useAuthState } from '../../../common';
import { useIntl } from 'react-intl';
import QualityDetailView from './quality-detail-view';
import { getFilesData } from '@exportx/ui-utils';
import SurveyorDetailsView from './surveyor-details-view';
import ContractDetailsView from './contract-details-view';
import PaymentTermsView from './payment-terms-view';
import ShippingTermsView from './shipping-terms-view';
import CommercialDocumentsView from './commercial-documents';
import AllPurchaseContractDetailedView from './all-purchase-contracts-detailed-view';
import queryString from 'query-string';

export const PurchaseContractDetailView = () => {
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const printRef = React.useRef();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<PurchaseContractDto>(null);
  const [snapData, setSnapData] = useState<PurchaseContractDto[]>([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [shippingTerms, setShippingTerms] = useState(null);
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const [filesFormRef] = Form.useForm();
  const service = new PurchaseContractService();
  const taxesAndItemsService = new TaxesAndItemsService();
  const location = useLocation();
  const paramPcId = queryString.parse(location.search)?.pc_id;
  const paramPlantCode = queryString.parse(location.search)?.plantCode;

  let { contractId }: any = paramPcId != null ? { contractId: paramPcId } : { contractId: null };
  let { plantCode }: any = paramPlantCode != null ? { plantCode: paramPlantCode } : { plantCode: authContext.defaultPlant };

  useEffect(()=>{
    if(!isPermissionExist([111])) return navigate('/')
  },[])
  useEffect(() => {
    if (contractId) {
      getSalesPreviewData(contractId, plantCode);
    }
  }, [contractId]);

  const getSalesPreviewData = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.getPurchasePreviewData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res?.snapData ? res.snapData : []);
        setContractDetails(res.data.contractDetails);
        if(res.data.contractDetails.contractDate){
          getTaxesByItemsId(res.data.contractDetails.contractDate)
        }
        setShippingTerms(res.data.shippingTerms);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };



  const sendForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.sendForApproval(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase  Order Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const addendumApproval = () => {
  //   const req = { ...new ContractIdRequest(contractId), plantCode };
  //   service.approvePurchaseAddendum(req).then(res => {
  //     if (res.status) {
  //       getSalesPreviewData(contractId, plantCode);
  //       AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
  //     } else {
  //       AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const PurchaseForReject = () => {
    const req = new ContractIdRequest(contractId, authContext.defaultPlant)
    service.purchaseForReject(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const purchaseForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode, userName: authContext.user.userName};
    if (previewData?.contractType === ContractTypesEnum.ADDENDUM) {
    service.approvePurchaseAddendum(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }else{
      service.purchaseForApproval(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  }



  const addendumClickHandler = () => {
    navigate('/po-addendum', { state: previewData });
  }



  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <> {isPermissionExist([119]) &&<Button size="small" onClick={() => purchaseForApproval()}>Approve</Button>};
   
      {isPermissionExist([120]) && <Button size="small" onClick={() => PurchaseForReject()}>Reject</Button>}</>;
    }

   
    // if (status === ContractStatusEnum.PENDING_APPROVAL) {
    //   return <><Button hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? false : true} onClick={() => addendumApproval()}>Approve</Button></>;
    // }

    if (status === ContractStatusEnum.ACTIVE && isPermissionExist([116])) {
      return <><Button hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? true : false} onClick={() => addendumClickHandler()}>Create Addendum</Button></>
    }
  }

  const setFormData = () => {
    navigate('/po-update', { state: previewData });
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        setPreviewData((prevState) => { return { ...prevState, filesData: [...prevState.filesData, ...filesData] } });
        service.addMoreFilesToPurchaseOrder({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          contractId: contractId
        }).then(res => {
          if (res.status) {
            getSalesPreviewData(contractId, plantCode);
            AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
          } else {
            AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const pcQuantityTotal = previewData?.qualityDetails.qualityData.reduce((a, c) => a + Number(c.quantity), 0);

  return (
    <>
      <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
        <Row justify="end">
          <Col><> <Button href="#/po-tracker" style={{ color: 'black' }}>PO Tracker</Button> <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Purchase Order</Button>
          </></Col></Row>
        <Card
          title={<span >PO Detail View - {contractDetails?.['contractNo']} <Tag color="#55acee">
            {contractDetails?.['status']}
          </Tag></span>}
          style={{ textAlign: 'center' }}
          headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
          ref={printRef}
          extra={<>{getExtraComponent(contractDetails?.['status'])}</>}>
          <br></br>
          <ContractDetailsView addendumData={previewData?.addendumData} contractDetails={contractDetails} shippingTerms={shippingTerms} contractMode={ContractModesEnum.PURCHASE} contractType={previewData?.contractType} plantCode={plantCode} snapShotData={snapData} />
          <br></br>
          <Card title={'Quality Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px', overflow: 'auto' }}>
            <QualityDetailView classes='contracts' qualityData={previewData?.qualityDetails?.qualityData} type={ContractModesEnum.PURCHASE} plantCode={plantCode} snapShotData={snapData} contractType={previewData?.contractType} vat={vat}/>
          </Card>
          <br></br>

          <SurveyorDetailsView surveyorDetails={previewData?.surveyorDetails} snapShotData={snapData} contractType={previewData?.contractType} />

          <PaymentTermsView paymentTerms={previewData?.paymentTerms} snapShotData={snapData} contractType={previewData?.contractType} />

          <ShippingTermsView shippingTerms={previewData?.shippingTerms} snapShotData={snapData} contractType={previewData?.contractType} />

          <CommercialDocumentsView commercialDocuments={previewData?.commercialDocuments} snapShotData={snapData} contractType={previewData?.contractType} />


          <Row>
            {previewData?.remarks && <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}>
              <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                <pre>
                  {previewData?.remarks}
                </pre>
              </Card>
            </Col>}
          </Row>

          <AllPurchaseContractDetailedView pcQuantityTotal={pcQuantityTotal} contractId={contractId} plantCode={plantCode} bpId={previewData?.contractDetails?.businessPartner} contractType={previewData?.contractType} />

          {previewData?.filesData && <FileUploadForm maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={previewData?.filesData} contractType={previewData?.contractType}>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button onClick={fileUploadFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>}
        </Card><br></br>
        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Layout >
    </>
  )
}
export default PurchaseContractDetailView;