
import React from 'react';
import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Collapse } from 'antd';
import { AnalysisDataView } from './analysis-data-view';
import ASHFusionView from './ash-fusion';
import ElementsView from './elements';
import OtherSpecs from './other-specs';
import SizeDistributionDataView from './size-distribution-data-view';

export interface IAnalysisData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    snapSpecType: any;
    contractType: ContractTypesEnum;
}
const { Panel } = Collapse;

export const SpecsViewPage = (props: IAnalysisData) => {
    const { purchaseTermData, specType, contractType, snapShotPurchaseTermData, snapSpecType } = props;

    return (
        <Collapse defaultActiveKey={['1']} >
            <Panel header="Analysis" key="1">
                <AnalysisDataView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType} />
            </Panel>
            <Panel header="Size Distribution" key="2">
                <SizeDistributionDataView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
            </Panel>
            <Panel header="ASH Fusion" key="3">
                <ASHFusionView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
            </Panel>
            <Panel header="Elements" key="4">
                <ElementsView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
            </Panel>
            <Panel header="Others" key="5">
                <OtherSpecs purchaseTermData={purchaseTermData} specType={specType}
                    snapShotPurchaseTermData={snapShotPurchaseTermData}
                    contractType={contractType} snapSpecType={snapSpecType}/>
            </Panel>
        </Collapse>
    )
}

export default SpecsViewPage;