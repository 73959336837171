import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Collapse } from 'antd';
import ASHDataView from './ash-data-view';
import { AshModel } from './ash.model';
import TypicalDataView from './typical-data-view';
import { TypicalModel } from './typical.model';
import UltimateDataView from './ultimate-data-view';
import { UltimateModel } from './ultimate.model';
export interface IAnalysisData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    snapSpecType: SpecTypesEnum;
    contractType: ContractTypesEnum;
}
const { Panel } = Collapse;
export const AnalysisDataView = (props: IAnalysisData) => {
    const { purchaseTermData, specType, contractType, snapShotPurchaseTermData } = props;
    const assignObjectValues = (childObject: any, parentObject: any) => {
        Object.keys(childObject).forEach(key => {
            childObject[key] = parentObject?.[key];
        })
        return childObject;
    }
    return (
        <Collapse defaultActiveKey={['1', '2']} >
            <Panel header="Typical" key="1">
                <TypicalDataView typical={assignObjectValues(new TypicalModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumTypical={assignObjectValues(new TypicalModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)} />
            </Panel>
            <Panel header="Ultimate" key="2">
                <UltimateDataView ultimate={assignObjectValues(new UltimateModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumUltimate={assignObjectValues(new UltimateModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)}/>
            </Panel>
            <Panel header="ASH" key="3">
                <ASHDataView ash={assignObjectValues(new AshModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumAsh={assignObjectValues(new AshModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)}/>
            </Panel>
        </Collapse>
    )
}

export default AnalysisDataView;