import { BargeCostingsService, BusinessPartnerTypeEnum, ContractStatusEnum, ContractStatusReqDto, GetDataDemurrageDto, PlantCodeReqDto } from "@exportx/shared-models-and-services"
import { Card, Input, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useAuthState } from "../../../common"
import Demurrage from "./demurrage-entry"
import { SequenceUtils, getLocalFormat } from "@exportx/ui-utils"
import { PaymentNoticePageEnum } from "../payment-notice"
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import moment from "moment"

interface IProps {
    type?: ContractStatusEnum;
    demurrageStatus?: ContractStatusEnum;
    paymentType?: PaymentNoticePageEnum;
    accountType?: BusinessPartnerTypeEnum
}

export const DemurrageApproval = (props: IProps) => {

    const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
    const bargeCostingService = new BargeCostingsService();
    const { authContext } = useAuthState();
    const [demurrageForm, setDemurrageForm] = useState<boolean>(false);
    const [searchedText, setSearchedText] = useState("");
    const [expandedIndex, setExpandedIndex] = useState([]);

    useEffect(() => {
        getDemurrageData()
    }, [props?.demurrageStatus])

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Demurrage Ref #',
            dataIndex: 'costingNo',
            width: '20%',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: 'MV Name',
            dataIndex: 'businessNumber',
            // width: '40%', 
            render: (value, record) => {
                const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`
                return <>
                    <a href={href}>{value}</a>
                </>
            }

        },
        {
            title: 'Cost Type',
            dataIndex: 'allowedTimeToLoad',//timeCompletedForLoadMV
            render: (value, record) => {
                let costType: string = ''
                if (Number(value) > Number(record.timeCompletedForLoadMV)) {
                    costType = 'Dispatch'
                } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
                    costType = 'Demurrage'
                } else {
                    costType = ' '
                }
                return <>
                    {costType}
                </>
            }
        },
        {
            title: 'Date',
            dataIndex: 'demurrageDate',
            render: (value, record) => { return <>{moment(value)?.format('DD-MM-YYYY')}</> }
        },
        {
            title: 'Account Type',
            dataIndex: 'account',
        },
        {
            title: 'Business Partner',
            dataIndex: 'bpName',
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.quoteCurrency)}
                </>
            }
        }

    ]

    const getDemurrageData = () => {
        const req = new ContractStatusReqDto(props.demurrageStatus, authContext.defaultPlant, props.accountType, props.type)
        bargeCostingService.getDemurrageData(req).then(res => {
            if (res.status) {
                setGridData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.demurrageAccountId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }

    const renderItems = (record: any) => {
        return <>
            <Demurrage
                demurrageApproval={true}
                setDemurrageForm={setDemurrageForm}
                approvalInitialValues={record}
                demurrageStatus={props.demurrageStatus}
                approvalLevel={props?.type}
            /></>
    };

    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Demurrage Approval for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
                <Input.Search
                    placeholder="Search"
                    allowClear
                    onChange={(e) => { setSearchedText(e.target.value) }}
                    onSearch={(value) => { setSearchedText(value) }}
                    style={{ width: 200, float: "right" }}
                />
                <Table
                    size='small'
                    expandIconColumnIndex={0}
                    // className='contracts'
                    rowKey={record => record.demurrageAccountId}
                    columns={tableColumns}
                    dataSource={gridData}
                    expandable={{
                        expandedRowRender: renderItems,
                        expandedRowKeys: expandedIndex,
                        onExpand: setIndex,
                        fixed: 'right'
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpCircleFilled
                                onClick={(e) => onExpand(record, e)}
                            >
                                Collapse
                            </UpCircleFilled>
                        ) : (
                            <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                        )
                    }
                    pagination={false}
                    scroll={{ x: 500 }}
                    bordered
                />

            </Card>
        </>
    )

}
export default DemurrageApproval