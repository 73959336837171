import { CPSPreviewDto, ContractStatusEnum, ContractTypesEnum, GetAllProcurementResponseDto, PriceTypeEnum, ProcurementContractService, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Descriptions, Form, Layout, Row, Space, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import PaymentTermsView from '../view-components/payment-terms-view';

interface IProps {
    contractTypeCategory?: ProcurementContractTypeEnum;
}


export const ProcurementContractDetailedView = (props: IProps) => {
    const { contractTypeCategory } = props
    const [gridData, setGridData] = useState<GetAllProcurementResponseDto>();
    const [snapData, setSnapData] = useState<GetAllProcurementResponseDto[]>([]);
    const [filesFormRef] = Form.useForm();
    const { authContext } = useAuthState();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.cpcId;
    let { cpcId }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };

    const navigate = useNavigate()
    let pathToreDirect = `/${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}o-detail-view`;

    let pcUpdatePath = '';

    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        pcUpdatePath = '/procurement-service-update'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        pcUpdatePath = '/coalServiceFee-service-update'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        pcUpdatePath = '/marketing-service-update'
    }


    let cpsAddendum = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        cpsAddendum = '/procurement-addendum'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        cpsAddendum = '/coalServiceFee-addendum'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        cpsAddendum = '/marketing-addendum'
    }

    let detailViewPath = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        detailViewPath = '/procurement-contract-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        detailViewPath = '/coalServiceFee-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        detailViewPath = '/marketing-detailedView'
    }

    const service = new ProcurementContractService();


    useEffect(() => {
        if (!isPermissionExist([147, 156, 165])) return navigate('/')
    }, [])

    useEffect(() => {
        getServiceContractPreviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpcId]);

    const getServiceContractPreviewData = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, contractTypeCategory, undefined, authContext.user.userName);
        service.getServiceContractPreviewData(req).then(res => {
            if (res.status) {
                setGridData(res.data);
                setSnapData(res.snapData);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    };

    const sendForApproval = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, undefined, undefined, authContext.user.userName);
        service.sendForApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const addendumClickHandler = () => {
        const link = `${cpsAddendum}?cpcId=${cpcId}`
        navigate(link);
    }

    const approveOrRejectServiceContract = (contractId: string, status: ContractStatusEnum, contractTypes: any) => {
        if (!contractTypes) {
            return
        }
        const req = new CPSPreviewDto(contractId, authContext.defaultPlant, contractTypes, status, authContext.user.userName);
        service.approveOrRejectServiceContract(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            } else {
                AlertMessages.getErrorMessage(res.error);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getExtraComponent = (status: any) => {
        if (status === ContractStatusEnum.DRAFT) {
            return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
        }
        if (status === ContractStatusEnum.PENDING_APPROVAL) {
            return <span>
                {PermissionExist('Approve') && <Button size="small" onClick={() => {
                    approveOrRejectServiceContract(cpcId, ContractStatusEnum.ACTIVE, gridData?.contractTypes);
                }}>Approve</Button>}
                {PermissionExist('Reject') &&
                    <Button size="small" onClick={() => {
                        approveOrRejectServiceContract(cpcId, ContractStatusEnum.DRAFT, gridData?.contractTypes);
                    }}>Reject</Button>}
            </span>
        }
        if (status === ContractStatusEnum.ACTIVE) {
            return <>{PermissionExist('Addendum') && <Button onClick={() => addendumClickHandler()}>Create Addendum</Button>}</>
        }
    }


    const fileUploadFormHandler = () => {
        filesFormRef.validateFields().then(fileValues => {
            getFilesData(fileValues.filesData).then(filesData => {
                service.addMoreFilesToCpsContracts({
                    filesData: filesData,
                    updatedUser: authContext.user.userName,
                    pcID: cpcId
                }).then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getServiceContractPreviewData();
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message);
                })
            });
        });
    }

    const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
        for (const snapDate of snapData) {
            let valueAtGivenKey: any = snapDate;
            for (const key of keys) {
                valueAtGivenKey = valueAtGivenKey?.[key];
            }
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    if (isRawData) {
                        return value;
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.contractNo.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            }
        };
        return value
    }

    const valiDateChildWithSnapShotChild = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = snapData?.[0]?.quality?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    console.log('came', key, value, valueAtGivenKey)
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const PermissionExist = (type: string) => {
        if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
            return type === 'Approve' ? isPermissionExist([151]) : type === 'Reject' ? isPermissionExist([152]) : isPermissionExist([146]) // no feature id's for addendum todo in feature
        } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
            return type === 'Approve' ? isPermissionExist([169]) : type === 'Reject' ? isPermissionExist([170]) : isPermissionExist([164])
        }
        return type === 'Approve' ? isPermissionExist([160]) : type === 'Reject' ? isPermissionExist([161]) : isPermissionExist([155])

    }


    const paymentTerms = { lc: gridData?.lc, others: gridData?.others, telegraphicTransfer: gridData?.telegraphicTransfer, lcValue: gridData?.lcValue, lcType: gridData?.lcType, lcCurrency: gridData?.lcCurrency, paymentMode: gridData?.paymentMode }
    const columns: ColumnProps<any>[] = [
        {
            title: 'LayCan',
            dataIndex: 'layCan',
            render: (value, record) => {
                return <>
                    {value[0] === null || value[1] === null ? "-" : moment(value[0]).format('DD MMM YY') + " To " + moment(value[0]).format('DD MMM YY')}
                </>
            }
        },
        {
            title: 'Quality',
            dataIndex: 'purchaseQuality',
            align: 'center',
            render: (value, row: any, index) => (
                <span>{valiDateChildWithSnapShotChild(index, 'purchaseQuality', value)}</span>
            ),
        },
        {
            title: 'Purchase Type',
            dataIndex: 'pcPurchaseType',
            align: 'center',
            render: (value, row: any, index) => (
                <span>{valiDateChildWithSnapShotChild(index, 'pcPurchaseType', value)}</span>
            ),
        },
        {
            title: 'Price Type ',
            dataIndex: 'priceType',
            align: 'center',
            render: (value, row: any, index) => (
                <span>{valiDateChildWithSnapShotChild(index, 'priceType', value)}</span>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantityInMT',
            align: 'center',
            render: (value, row: any, index) => (
                <span>{valiDateChildWithSnapShotChild(index, 'quantityInMT', value)}</span>
            ),

        },
        {
            title: 'Price',
            dataIndex: 'totalPrice',
            align: 'center',
            render: (value, record, index) => {
                console.log(valiDateChildWithSnapShotChild(index, 'pricePMT', record.pricePMT))
                if (isPermissionExist([150, 159, 168])) {
                    return <>
                        {record.priceType === PriceTypeEnum.LUMP_SUM ? valiDateChildWithSnapShotChild(index, 'totalPrice', value) : record.priceType === PriceTypeEnum.PER_MT ? <>{valiDateChildWithSnapShotChild(index, 'pricePMT', record.pricePMT)} (PMT)  </> : undefined}
                    </>
                } else return <></>

            }
        },
        {
            title: "QualityAdjustment",
            dataIndex: 'qualityAdjustment',
            align: 'center',
            render: (value, row: any, index) => (
                <span>{valiDateChildWithSnapShotChild(index, 'qualityAdjustment', value)}</span>
            ),

        },

    ];

    const remarks: ColumnProps<any>[] = [
        {
            title: "Remarks",
            dataIndex: "remarks"
        }
    ]

    const link = `/#/detailCustomer?bp_id=${gridData?.vendorId}`
    const purchaseOrSalesDetailView = `/#${pathToreDirect}?${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}c_id=${gridData?.purchaseContractNo}`

    return (
        <>
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
                <Card
                    style={{ textAlign: 'center' }}
                    className='default-card-class'
                    title={<span style={{ color: 'white' }}> {gridData?.contractType} Contract -  {(gridData?.contractNo)}
                        <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
                        </span>
                    </span>
                    }
                    extra={<>{getExtraComponent(gridData?.status)}</>}
                >
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractType"}>
                                    {valiDateWithSnapShotData(['contractType'], gridData?.contractType, false)}
                                </Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractDate"}>
                                    {valiDateWithSnapShotData(['contractDate'], gridData?.contractDate ? moment(gridData?.contractDate).format('DD-MM-YYYY h:mm A') : '', false)}</Descriptions.Item>
                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={`${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Contract" : "Purchase Contract"}`} ><a><Link href={purchaseOrSalesDetailView}>
                                    {valiDateWithSnapShotData(['purchaseContract'], gridData?.purchaseContract, false)}
                                </Link></a></Descriptions.Item>
                            </Descriptions></Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Vendor"}><span><Link href={link}>
                                    {valiDateWithSnapShotData(['vendor'], gridData?.vendor, false)}
                                </Link></span></Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Currency"}>
                                    {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                </Descriptions.Item>

                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>

                                <Descriptions.Item label={"Approved By"}>
                                    {gridData?.approvedBy}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Approved Date"}>
                                    {gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {/* <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={'Remarks'}>
                                    {valiDateWithSnapShotData(['remarks'], gridData?.remarks, false)}
                                </Descriptions.Item>
                            </Descriptions></Col> */}
                    </Row>
                    <Row>

                        <Descriptions>
                            <Descriptions.Item label={"AddendumData"}>
                                {gridData?.addendumData.map((rec) => {
                                    const link = `/#${detailViewPath}?cpcId=${rec.addendumContractId}`
                                    return <><a href={link} className="link-primary" >
                                        {`${rec.addendumContractNo} ,`}
                                    </a></>
                                })}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>

                    <br />

                    <Card>
                        <Table
                            dataSource={gridData?.quality ? gridData?.quality : []}
                            columns={columns}
                            pagination={false} />
                    </Card>

                    <Card >
                        <PaymentTermsView paymentTerms={paymentTerms} />
                    </Card>
                    <Card>
                        <Table dataSource={Array(gridData)} columns={remarks}
                            pagination={false} /></Card>

                    {gridData?.filesData &&
                        <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button
                                                onClick={fileUploadFormHandler}
                                                type="primary">
                                                Save and Next
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>
                    }
                </Card>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            <Col>{(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={() => {
                                const link = `${pcUpdatePath}?cpcId=${cpcId}`
                                navigate(link);
                            }}>Edit</Button>}</Col>
                        </Row>
                    </Card>
                </Affix>
            </Layout>
        </>
    );

}
export default ProcurementContractDetailedView;