import { BargeCostingsService, BusinessPartnerTypeEnum, ContractStatusEnum, ContractStatusReqDto, GetDataDeadFreightDto } from "@exportx/shared-models-and-services"
import { getLocalFormat } from "@exportx/ui-utils"
import { Input, Card, Table } from 'antd';
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useAuthState } from "../../../common"
import DeadFreight from "./dead-freight-entry"
import { SequenceUtils } from "@exportx/ui-utils"
import { PaymentNoticePageEnum } from '../payment-notice'
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import moment from 'moment';

interface IProps {
    type?: ContractStatusEnum;
    deadFreightStatus?: ContractStatusEnum;
    paymentType?: PaymentNoticePageEnum;
    accountType?: BusinessPartnerTypeEnum
}


export const DeadFreightApproval = (props: IProps) => {
    console.log('DeadFreightApproval', props)

    const [gridData, setGridData] = useState<GetDataDeadFreightDto[]>();
    const bargeCostingService = new BargeCostingsService();
    const { authContext } = useAuthState();
    const [deadFreightForm, setDeadFreightForm] = useState<boolean>(false);
    const [searchedText, setSearchedText] = useState("");
    const [expandedIndex, setExpandedIndex] = useState([]);

    useEffect(() => {
        getDeadFreightData()
    }, [props?.deadFreightStatus])

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Dead Freight Ref #',
            dataIndex: 'costingNo',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },

        {
            title: 'MV Name',
            dataIndex: 'businessNo',
            render: (value, record) => {
                const href = `/#/bn-detail-view?bn_Id=${record.bnId}`
                return <>
                    {<a href={href} style={{ fontSize: 12 }}>{value}</a>}
                </>
            }

        },

        {
            title: 'PricePerMT',
            dataIndex: 'pricePerMT',
            // width: 40,
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.baseCurrency)}
                </>
            }
        },
        {
            title: 'Account Type',
            dataIndex: 'account',
        },
        {
            title: 'BusinessPartner',
            dataIndex: 'supplierName',
        },
        {
            title: 'Date',
            dataIndex: 'deadFreightDate',
            render: (value, record) => { return <>{moment(value)?.format('DD-MM-YYYY')}</> }
        },
        {
            title: 'DeadFreight QTY',
            dataIndex: 'deadFreight',
            render: (value, record) => {
                return <>
                    {value + " MT"}
                </>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'deadFreightBasePrice',
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.baseCurrency)}
                </>
            }
        }

    ]

    const getDeadFreightData = () => {
        const req = new ContractStatusReqDto(props.deadFreightStatus, authContext.defaultPlant, props.accountType, props.deadFreightStatus)
        bargeCostingService.getDeadFreightData(req).then(res => {
            if (res.status) {
                setGridData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.demurrageAccountId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        return <>
            <DeadFreight
                deadFreightApproval={true}
                setDeadFreightForm={setDeadFreightForm}
                approvalInitialValues={record}
                deadFreightStatus={props.deadFreightStatus}
                accountType={props.accountType}
                approvalLevel={props?.type}
            /></>
    };
    return (
        <>
            <Card title={<span style={{ color: 'white' }}>Dead Freight Approval for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
                <Input.Search
                    placeholder="Search"
                    allowClear
                    onChange={(e) => { setSearchedText(e.target.value) }}
                    onSearch={(value) => { setSearchedText(value) }}
                    style={{ width: 200, float: "right" }}
                />
                <Table
                    size='small'
                    expandIconColumnIndex={0}
                    // className='contracts'
                    rowKey={record => record.demurrageAccountId}
                    columns={tableColumns}
                    dataSource={gridData}
                    expandable={{
                        expandedRowRender: renderItems,
                        expandedRowKeys: expandedIndex,
                        onExpand: setIndex,
                        fixed: 'right'
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpCircleFilled
                                onClick={(e) => onExpand(record, e)}
                            >
                                Collapse
                            </UpCircleFilled>
                        ) : (
                            <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                        )
                    }
                    pagination={false}
                    scroll={{ x: 500 }}
                    bordered
                />

            </Card>
        </>
    )

}
export default DeadFreightApproval