import { EditOutlined, FilePdfTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { BargeCostIdReqDto, BargeCostingVouchersParentDto, CommercialCoalCostingService, ContractModesEnum, ContractTypeDto, CostingTypesEnum, PerFormaGridDataDto, PerformaCoalCostingService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Card, Divider, FormInstance, Input, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import AddInvoiceDrawer from '../../add-invoice-drawer';
import Invoice from '../../invoice/invoice';
import { getLocalFormat, SequenceUtils } from '@exportx/ui-utils';

interface CostingsIProps {
    contractType: ContractModesEnum;
    setPerFormaId: (value: string) => void;
    costingType: CostingTypesEnum;
    referenceFeatures: ReferenceFeatures;
    setGridDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    formRef?: FormInstance<any>;
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>;
}


export const SavedCostingsGrid = (props: CostingsIProps) => {
    const [data, setData] = useState<PerFormaGridDataDto[]>([]);
    const { contractType, costingType, setPerFormaId, referenceFeatures, setGridDrawer } = props;
    const { authContext } = useAuthState();
    const [drawerOpen, setDrawerOpen] = useState<boolean>()
    const [costingId, setCostingId] = useState<any>()
    const [dummyRefresh, setDummyRefresh] = useState<number>(1);
    const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>()
    const commercialCostingsService = new CommercialCoalCostingService();
    const [searchedText, setSearchedText] = useState("");

    useEffect(() => {
        if (costingType === CostingTypesEnum.PERFORMA)
            getPerFormCoalGridData();
        else {
            getCommercialCoalGridData();
        }


    }, [contractType, costingType]);


    const getPerFormCoalGridData = () => {
        const performaCostingsService = new PerformaCoalCostingService();
        const req = new ContractTypeDto();
        req.contractType = contractType;
        req.plantCode = authContext.defaultPlant;
        performaCostingsService.getPerFormaCoalGridData(req).then(res => {
            if (res.status) {
                setData(res.data);
            } else {
                console.log(res.internalMessage)
            }
        }).catch(err => console.log(err.message))
    }

    const getCommercialCoalGridData = () => {
        const req = new ContractTypeDto();
        req.contractType = contractType;
        req.plantCode = authContext.defaultPlant;
        commercialCostingsService.getCommercialCoalGridData(req).then(res => {
            if (res.status) {
                setData(res.data);
            } else {
                console.log(res.internalMessage)
            }
        }).catch(err => console.log(err.message))
    };


    const getVouchersData = (id) => {
        if (costingType === CostingTypesEnum.PERFORMA) {
            perFormaVouchersData(id);
        } else {
            getAllCommercialVouchersData(id);
        }
    }

    const getAllCommercialVouchersData = (id) => {
        const req = new BargeCostIdReqDto(id, authContext.defaultPlant, contractType)
        commercialCostingsService.getAllCommercialVouchersData(req).then(res => {
            if (res.status) {
                setVouchers(res.data);
            } else {
                console.log(res.internalMessage);
                setVouchers(undefined);
            }
        }).catch(err => console.log(err.message));
    };


    const perFormaVouchersData = (id) => {
        const performaCostingsService = new PerformaCoalCostingService();
        const req = new BargeCostIdReqDto(id, authContext.defaultPlant, contractType)
        performaCostingsService.getPerformanceContractsInvoice(req).then(res => {
            if (res.status) {
                setVouchers(res.data);
            } else {
                console.log(res.internalMessage);
                setVouchers(undefined);
            }
        }).catch(err => console.log(err.message));
    };
    const objectValue = (referenceFeatures: ReferenceFeatures, id: string,) => {
        const object = {
            [ReferenceFeatures.PERFORMA_PC_COSTING]: `/#/po-detail-view?pc_id=${id}`,
            [ReferenceFeatures.COMMERCIAL_PC_COSTING]: `/#/po-detail-view?pc_id=${id}`,
            [ReferenceFeatures.PERFORMA_SC_COSTING]: `/#/so-detail-view?sc_id=${id}`,
            [ReferenceFeatures.COMMERCIAL_SC_COSTING]: `/#/so-detail-view?sc_id=${id}`,
            [ReferenceFeatures.PROCUREMENT_FEES]: `/#/procurement-contract-detailedView?cpcId=${id}`,
            [ReferenceFeatures.MARKETING_FEES]: `/#/marketing-detailedView?cpcId=${id}`,
        }
        return object[referenceFeatures]
    }

    const tableColumns: ColumnsType<any> = [
        {
            width: 100,
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            render: (text, rowData) => (
                <span>
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => {
                                setPerFormaId(rowData.costingId);
                                setGridDrawer(false);
                            }}
                            style={{ color: '#1890ff', fontSize: '15px' }}
                        />
                    </Tooltip>
                    <Divider type='vertical'></Divider>
                    <Tooltip placement="topRight" title="Add Invoice">
                        <PlusSquareTwoTone onClick={() => {
                            setDrawerOpen(true);
                            setCostingId((prev) => { return { ...prev, costingId: rowData.costingId, totalAmount: rowData.amount } });

                        }}
                            style={{ color: '#1890ff', fontSize: '15px' }}
                        />
                    </Tooltip>
                    <Divider type='vertical'></Divider>

                    <PDFDownloadLink
                        document={<Invoice invoice={vouchers} key={dummyRefresh} demurrageName={''} costingName={referenceFeatures} />}
                        fileName={`${rowData.costingNo}.pdf`}
                    >
                        <Tooltip placement="topRight" title="Invoice">
                            <FilePdfTwoTone onClick={() => {
                                getVouchersData(rowData.costingId);
                                setDummyRefresh(prev => prev + 1)
                            }}
                                style={{ color: '#1890ff', fontSize: '15px' }}
                            />
                        </Tooltip>
                    </PDFDownloadLink>
                    {/* <Divider type='vertical'></Divider>
                    <ViewAllFilesByFeatureId featuresRefId={rowData.costingId} featuresRefName={referenceFeatures}/> */}
                </span>
            ),
        },
        {
            title: 'Costing No',
            dataIndex: 'costingNo',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
            render: (value, record: any, index) => {
                return <span>{value} </span>;
            }

        },
        {
            title: 'Costing Date',
            dataIndex: 'costingDate',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            render: (text, record) => {
                return moment(record.costingDate).format('YYYY-MM-DD')
            },
        },
        {
            title: 'Coal Supplier',
            dataIndex: 'bpName',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: 'Contract NO',
            dataIndex: 'contractNo',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, rowData) => {
                const link = objectValue(referenceFeatures, rowData.contractId)
                return <> {
                    <a href={link} className="link-primary" >{value}</a>
                }
                </>
            }
        },
        ...(contractType !== ContractModesEnum.PROCUREMENT_FEES && contractType !== ContractModesEnum.MARKETING_FEES) ? [{
            title: 'Business No',
            dataIndex: 'motherVesselName',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            render: (value,rowData) => {
                if(rowData.businessNumber) {
                    const link = `/#/bn-detail-view?bn_Id=${rowData.businessNumber}`
                    return <a href={link} target='_blank' className="link-primary" >{value}</a>
    
                }
                return <span>{value}</span>
            }
        }] : [],
        {
            title: 'Barges',
            dataIndex: 'bargeNo',
            render: (value, record, index) => {
                const link = `/#/barge-detail-view?barge_id=${record.bargeId}`
                return <>
                    <a href={link}>{SequenceUtils.formatNumberToSpecificLength(value) + " " + `(${record.bgNomination})`}</a>
                </>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            onCell: (record, index) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{getLocalFormat(value, authContext.defaultPlantCurrency)} </span>;
            }
        },
    ]

    const getTableData = (values: PerFormaGridDataDto[]): any[] => {
        const empty = [];
        values?.forEach((rec, pIndex) => {
            rec?.barges?.forEach((child, index) => {
                empty.push({
                    costingId: rec.costingId,
                    amount: rec.amount,
                    costingNo: rec.costingNo,
                    costingDate: rec.costingDate,
                    bpName: rec.bpName,
                    contractNo: rec.contractNo,
                    businessNumber: rec.businessNumber,
                    motherVesselName: rec.motherVesselName,
                    contractId: rec.contractId,
                    rowSpan: index === 0 ? rec.barges.length : 0,
                    bargeId: child.bargeId,
                    bargeNo: child.bargeNo,
                    bgNomination: child.bgNomination
                })
            })
        })
        return empty;
    }




    return (
        <>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />
            <Table
                className='contracts'
                scroll={{ x: 500 }}
                columns={tableColumns}
                dataSource={getTableData(data)}
                // pagination={false}
                bordered={true}
            >
            </Table>
            <AddInvoiceDrawer
                referenceFeatures={referenceFeatures}
                key={dummyRefresh}
                setDummyRefresh={setDummyRefresh}
                openDrawer={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                costingId={costingId}
            />

        </>


    )
}

export default SavedCostingsGrid;