import { Card, Checkbox, Collapse, Row, Col, Form, theme, CollapseProps, GetProp } from "antd";
import { useEffect, useState, type CSSProperties } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';


export const CostingsSelection = ({ formRef, initialValues }) => {

  const [values, setValues] = useState({
    performa_purchase: [],
    performa_purchase_invoice: [],
    performa_purchase_payments: [],
    com_purchase: [],
    com_purchase_invoice: [],
    com_purchase_payments: [],
    performa_sales: [],
    performa_sales_invoices: [],
    performa_sales_payments: [],
    com_sales: [],
    com_sales_invoices: [],
    com_sales_payments: [],
    procurement_fees: [],
    procurement_fees_invoices: [],
    procurement_fees_payments: [],
    marketing_fees: [],
    marketing_fees_invoices: [],
    marketing_fees_payments: [],
    barge_costing: [],
    barge_costing_invoices: [],
    barge_costing_payments: [],
    chemical_spray: [],
    chemical_spray_invoices: [],
    chemical_spray_payments: [],
    export_tax: [],
    export_tax_invoices: [],
    export_tax_payments: [],
    doc_charges: [],
    doc_charges_invoices: [],
    doc_charges_payments: [],
    lap_charges: [],
    lap_charges_invoices: [],
    lap_charges_payments: [],
    sam_charges: [],
    sam_charges_invoices: [],
    sam_charges_payments: [],
    pre_ship_inspection: [],
    pre_ship_inspection_invoices: [],
    pre_ship_inspection_payments: [],
    other_expenses: [],
    other_expenses_invoices: [],
    other_expenses_payments: [],
    ret_cargo_charges: [],
    ret_cargo_charges_invoices: [],
    ret_cargo_charges_payments: [],
    float_crane_charges: [],
    float_crane_charges_invoices: [],
    float_crane_charges_payments: [],
    stevedor_costing: [],
    stevedor_costing_invoices: [],
    stevedor_costing_payments: [],
    freight_costing: [],
    freight_costing_invoices: [],
    freight_costing_payments: [],
    pnbp_charges: [],
    pnbp_charges_invoices: [],
    pnbp_charges_payments: [],
    other_income: [],
    other_income_invoices: [],
    other_income_payments: [],
    insurance: [],
    insurance_invoices: [],
    insurance_payments: [],
    dead_freight: [],
    dead_freight_invoices: [],
    dead_freight_payments: [],
    demurrage: [],
    demurrage_invoices: [],
    demurrage_payments: [],
    cre_deb_notes: [],
    cre_deb_notes_invoices: [],
    cre_deb_notes_payments: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          performa_purchase: features?.filter((cou) => 270 <= +cou && +cou <= 278)?.map(Number),
          performa_purchase_invoice: features?.filter((cou) => 279 <= +cou && +cou <= 286)?.map(Number),
          performa_purchase_payments: features?.filter((cou) => 287 <= +cou && +cou <= 290)?.map(Number),

          com_purchase: features?.filter((cou) => 291 <= +cou && +cou <= 299)?.map(Number),
          com_purchase_invoice: features?.filter((cou) => 300 <= +cou && +cou <= 307)?.map(Number),
          com_purchase_payments: features?.filter((cou) => 308 <= +cou && +cou <= 311)?.map(Number),

          performa_sales: features?.filter((cou) => 312 <= +cou && +cou <= 320)?.map(Number),
          performa_sales_invoices: features?.filter((cou) => 321 <= +cou && +cou <= 328)?.map(Number),
          performa_sales_payments: features?.filter((cou) => 329 <= +cou && +cou <= 332)?.map(Number),

          com_sales: features?.filter((cou) => 333 <= +cou && +cou <= 341)?.map(Number),
          com_sales_invoices: features?.filter((cou) => 342 <= +cou && +cou <= 349)?.map(Number),
          com_sales_payments: features?.filter((cou) => 350 <= +cou && +cou <= 353)?.map(Number),

          procurement_fees: features?.filter((cou) => 354 <= +cou && +cou <= 362)?.map(Number),
          procurement_fees_invoices: features?.filter((cou) => 363 <= +cou && +cou <= 370)?.map(Number),
          procurement_fees_payments: features?.filter((cou) => 371 <= +cou && +cou <= 374)?.map(Number),

          marketing_fees: features?.filter((cou) => 375 <= +cou && +cou <= 382)?.map(Number),
          marketing_fees_invoices: features?.filter((cou) => 383 <= +cou && +cou <= 390)?.map(Number),
          marketing_fees_payments: features?.filter((cou) => 391 <= +cou && +cou <= 394)?.map(Number),

          barge_costing: features?.filter((cou) => 395 <= +cou && +cou <= 403)?.map(Number),
          barge_costing_invoices: features?.filter((cou) => 404 <= +cou && +cou <= 411)?.map(Number),
          barge_costing_payments: features?.filter((cou) => 412 <= +cou && +cou <= 415)?.map(Number),

          chemical_spray: features?.filter((cou) => 416 <= +cou && +cou <= 424)?.map(Number),
          chemical_spray_invoices: features?.filter((cou) => 425 <= +cou && +cou <= 432)?.map(Number),
          chemical_spray_payments: features?.filter((cou) => 433 <= +cou && +cou <= 436)?.map(Number),

          export_tax: features?.filter((cou) => 437 <= +cou && +cou <= 445)?.map(Number),
          export_tax_invoices: features?.filter((cou) => 446 <= +cou && +cou <= 453)?.map(Number),
          export_tax_payments: features?.filter((cou) => 454 <= +cou && +cou <= 457)?.map(Number),

          doc_charges: features?.filter((cou) => 458 <= +cou && +cou <= 466)?.map(Number),
          doc_charges_invoices: features?.filter((cou) => 467 <= +cou && +cou <= 474)?.map(Number),
          doc_charges_payments: features?.filter((cou) => 475 <= +cou && +cou <= 478)?.map(Number),

          lap_charges: features?.filter((cou) => 479 <= +cou && +cou <= 487)?.map(Number),
          lap_charges_invoices: features?.filter((cou) => 488 <= +cou && +cou <= 495)?.map(Number),
          lap_charges_payments: features?.filter((cou) => 496 <= +cou && +cou <= 499)?.map(Number),

          sam_charges: features?.filter((cou) => 500 <= +cou && +cou <= 508)?.map(Number),
          sam_charges_invoices: features?.filter((cou) => 509 <= +cou && +cou <= 516)?.map(Number),
          sam_charges_payments: features?.filter((cou) => 517 <= +cou && +cou <= 520)?.map(Number),

          pre_ship_inspection: features?.filter((cou) => 521 <= +cou && +cou <= 529)?.map(Number),
          pre_ship_inspection_invoices: features?.filter((cou) => 530 <= +cou && +cou <= 537)?.map(Number),
          pre_ship_inspection_payments: features?.filter((cou) => 538 <= +cou && +cou <= 541)?.map(Number),

          other_expenses: features?.filter((cou) => 542 <= +cou && +cou <= 550)?.map(Number),
          other_expenses_invoices: features?.filter((cou) => 551 <= +cou && +cou <= 558)?.map(Number),
          other_expenses_payments: features?.filter((cou) => 559 <= +cou && +cou <= 562)?.map(Number),

          ret_cargo_charges: features?.filter((cou) => 563 <= +cou && +cou <= 571)?.map(Number),
          ret_cargo_charges_invoices: features?.filter((cou) => 572 <= +cou && +cou <= 579)?.map(Number),
          ret_cargo_charges_payments: features?.filter((cou) => 580 <= +cou && +cou <= 583)?.map(Number),

          float_crane_charges: features?.filter((cou) => 584 <= +cou && +cou <= 592)?.map(Number),
          float_crane_charges_invoices: features?.filter((cou) => 593 <= +cou && +cou <= 600)?.map(Number),
          float_crane_charges_payments: features?.filter((cou) => 601 <= +cou && +cou <= 604)?.map(Number),

          stevedor_costing: features?.filter((cou) => 605 <= +cou && +cou <= 613)?.map(Number),
          stevedor_costing_invoices: features?.filter((cou) => 614 <= +cou && +cou <= 621)?.map(Number),
          stevedor_costing_payments: features?.filter((cou) => 622 <= +cou && +cou <= 625)?.map(Number),

          freight_costing: features?.filter((cou) => 626 <= +cou && +cou <= 633)?.map(Number),
          freight_costing_invoices: features?.filter((cou) => 634 <= +cou && +cou <= 641)?.map(Number),
          freight_costing_payments: features?.filter((cou) => 642 <= +cou && +cou <= 645)?.map(Number),

          pnbp_charges: features?.filter((cou) => 646 <= +cou && +cou <= 653)?.map(Number),
          pnbp_charges_invoices: features?.filter((cou) => 654 <= +cou && +cou <= 661)?.map(Number),
          pnbp_charges_payments: features?.filter((cou) => 662 <= +cou && +cou <= 665)?.map(Number),

          other_income: features?.filter((cou) => 666 <= +cou && +cou <= 673)?.map(Number),
          other_income_invoices: features?.filter((cou) => 674 <= +cou && +cou <= 681)?.map(Number),
          other_income_payments: features?.filter((cou) => 682 <= +cou && +cou <= 685)?.map(Number),

          insurance: features?.filter((cou) => 686 <= +cou && +cou <= 693)?.map(Number),
          insurance_invoices: features?.filter((cou) => 694 <= +cou && +cou <= 701)?.map(Number),
          insurance_payments: features?.filter((cou) => 702 <= +cou && +cou <= 705)?.map(Number),

          dead_freight: features?.filter((cou) => 706 <= +cou && +cou <= 713)?.map(Number),
          dead_freight_invoices: features?.filter((cou) => 714 <= +cou && +cou <= 721)?.map(Number),
          dead_freight_payments: features?.filter((cou) => 722 <= +cou && +cou <= 725)?.map(Number),

          demurrage: features?.filter((cou) => 726 <= +cou && +cou <= 733)?.map(Number),
          demurrage_invoices: features?.filter((cou) => 734 <= +cou && +cou <= 741)?.map(Number),
          demurrage_payments: features?.filter((cou) => 742 <= +cou && +cou <= 745)?.map(Number),

          cre_deb_notes: features?.filter((cou) => 746 <= +cou && +cou <= 753)?.map(Number),
          cre_deb_notes_invoices: features?.filter((cou) => 754 <= +cou && +cou <= 761)?.map(Number),
          cre_deb_notes_payments: features?.filter((cou) => 762 <= +cou && +cou <= 765)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 10,
    background: 'rgb(241, 239, 234)',
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
      {
        key: '1',
        label: 'Performa Purchase',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Purchase Purchase Costing">
            <Form.Item name="performa_purchase" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={270}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={271}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={272}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={273}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={274}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={275}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={276}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={277}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={278}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Purchase Invoices">
            <Form.Item name="performa_purchase_invoice" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={279}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={280}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={281}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={282}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={283}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={284}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={285}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={286}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Purchase Payments">
            <Form.Item name="performa_purchase_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={287}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={288}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={289}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={290}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '2',
        label: 'Commercial Purchase',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Commercial Purchase Costing">
            <Form.Item name="com_purchase" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={291}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={292}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={293}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={294}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={295}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={296}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={297}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={298}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={299}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Purchase Invoices">
            <Form.Item name="com_purchase_invoice" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={300}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={301}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={302}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={303}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={304}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={305}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={306}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Purchase Payments">
            <Form.Item name="com_purchase_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={308}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={309}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={310}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={311}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '3',
        label: 'Performa Sales',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Performa Sales Costing">
            <Form.Item name="performa_sales" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={312}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={313}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={314}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={315}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={316}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={317}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={318}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={319}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={320}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Sales Invoices">
            <Form.Item name="performa_sales_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={321}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={322}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={323}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={324}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={325}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={326}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={327}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={328}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Sales Payments">
            <Form.Item name="performa_sales_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={329}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={330}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={331}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={332}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '4',
        label: 'Commercial Sales',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Commercial Sales Costing">
            <Form.Item name="com_sales" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={333}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={334}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={335}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={336}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={337}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={338}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={339}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={340}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={341}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Sales Invoices">
            <Form.Item name="com_sales_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={342}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={343}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={344}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={345}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={346}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={347}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={348}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={349}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Sales Payments">
            <Form.Item name="com_sales_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={350}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={351}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={352}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={353}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '5',
        label: 'Procurement Fees',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Procurement Fees Costing">
            <Form.Item name="procurement_fees" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={354}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={355}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={356}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={357}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={358}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={359}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={360}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={361}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={362}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Procurement Fees Invoices">
            <Form.Item name="procurement_fees_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={363}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={364}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={365}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={366}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={367}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={368}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={369}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={370}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Procurement Fees Payments">
            <Form.Item name="procurement_fees_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={371}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={372}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={373}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={374}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '6',
        label: 'Marketing Fees',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Marketing Fees Costing">
            <Form.Item name="marketing_fees" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={375}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={376}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={377}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={378}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={379}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={380}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={381}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={382}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value="4">Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Marketing Fees Invoices">
            <Form.Item name="marketing_fees_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={383}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={384}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={385}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={386}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={387}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={388}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={389}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={390}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Marketing Fees Payments">
            <Form.Item name="marketing_fees_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={391}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={392}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={393}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={394}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '7',
        label: 'Barge Costing',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Barge Costing">
            <Form.Item name="barge_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={395}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={396}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={397}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={398}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={399}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={400}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={401}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={402}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={403}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Barge Costing Invoices">
            <Form.Item name="barge_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={404}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={405}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={406}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={407}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={408}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={409}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={410}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={411}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Barge Costing Payments">
            <Form.Item name="barge_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={412}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={413}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={414}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={415}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '8',
        label: 'Chemical Spray',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Chemical Spray Costing">
            <Form.Item name="chemical_spray" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={416}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={417}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={418}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={419}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={420}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={421}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={422}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={423}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={424}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Chemical Spray Invoices">
            <Form.Item name="chemical_spray_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={425}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={426}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={427}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={428}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={429}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={430}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={431}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={432}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Chemical Spray Payments">
            <Form.Item name="chemical_spray_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={433}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={434}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={435}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={436}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '9',
        label: 'Export Tax',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Export Tax Costing">
            <Form.Item name="export_tax" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={437}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={438}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={439}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={440}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={441}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={442}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={443}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={444}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={445}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Export Tax Invoices">
            <Form.Item name="export_tax_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={446}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={447}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={448}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={449}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={450}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={451}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={452}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={453}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Export Tax Payments">
            <Form.Item name="export_tax_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={454}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={455}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={456}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={457}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '10',
        label: 'Documentation Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Documentation Charges Costing">
            <Form.Item name="doc_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={458}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={459}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={460}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={461}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={462}>462</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={463}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={464}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={465}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={466}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Documentation Charges Invoices">
            <Form.Item name="doc_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={467}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={468}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={469}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={470}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={471}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={472}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={473}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={474}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Documentation Charges Payments">
            <Form.Item name="doc_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={475}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={476}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={477}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={478}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '11',
        label: 'Laporan Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Laporan Charges Costing">
            <Form.Item name="lap_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={479}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={480}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={481}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={482}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={483}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={484}>Business Approval</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={487}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Laporan Charges Invoices">
            <Form.Item name="lap_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={488}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={489}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={490}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={491}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={492}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={493}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={494}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={495}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Laporan Charges Payments">
            <Form.Item name="lap_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={496}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={497}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={498}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={499}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '12',
        label: 'Sampling & Analasyis Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges">
            <Form.Item name="sam_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={500}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={501}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={502}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={503}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={504}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={505}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={506}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={507}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={508}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges Invoices">
            <Form.Item name="sam_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={509}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={510}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={511}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={512}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={513}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={514}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={515}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={516}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges Payments">
            <Form.Item name="sam_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={517}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={518}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={519}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={520}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '13',
        label: 'Pre Shipment Inspection',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Pre Shipment Inspection">
            <Form.Item name="pre_ship_inspection" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={521}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={522}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={523}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={524}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={525}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={526}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={527}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={528}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={529}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Pre Shipment Inspection Invoices">
            <Form.Item name="pre_ship_inspection_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={530}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={531}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={532}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={533}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={534}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={535}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={536}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={537}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Pre Shipment Inspection Payments">
            <Form.Item name="pre_ship_inspection_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={538}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={539}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={540}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={541}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '14',
        label: 'Other Expenses',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Other Expenses">
            <Form.Item name="other_expenses" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={542}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={543}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={544}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={545}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={546}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={547}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={548}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={549}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={550}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Expenses Invoices">
            <Form.Item name="other_expenses_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={551}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={552}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={553}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={554}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={555}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={556}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={557}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={558}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Expenses Payments">
            <Form.Item name="other_expenses_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={559}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={560}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={561}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={562}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '15',
        label: 'Return Cargo Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Return Cargo Charges">
            <Form.Item name="ret_cargo_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={563}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={564}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={565}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={566}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={567}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={568}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={569}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={570}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={571}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Return Cargo Charges Invoices">
            <Form.Item name="ret_cargo_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={572}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={573}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={574}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={575}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={576}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={577}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={578}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={579}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Return Cargo Charges Payments">
            <Form.Item name="ret_cargo_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={580}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={581}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={582}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={583}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '16',
        label: 'Floating Crane Charges',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Floating Crane Charges">
            <Form.Item name="float_crane_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={584}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={585}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={586}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={587}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={588}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={589}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={590}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={591}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={592}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Floating Crane Invoices">
            <Form.Item name="float_crane_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={593}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={594}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={595}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={596}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={597}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={598}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={599}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={600}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Floating Crane Payments">
            <Form.Item name="float_crane_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={601}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={602}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={603}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={604}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '17',
        label: 'Stevedore Costing',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Stevedore Costing">
            <Form.Item name="stevedor_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={605}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={606}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={607}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={608}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={609}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={610}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={611}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={612}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={613}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Stevedore Costing Invoices">
            <Form.Item name="stevedor_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={614}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={615}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={616}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={617}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={618}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={619}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={620}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={621}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Stevedore Costing Payments">
            <Form.Item name="stevedor_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={622}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={623}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={624}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={625}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '19',
        label: 'Freight Costing',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Freight Costing">
            <Form.Item name="freight_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={626}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={627}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={628}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={629}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={629}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={630}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={631}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={632}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={633}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Freight Costing Invoices">
            <Form.Item name="freight_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={634}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={635}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={636}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={637}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={638}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={639}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={640}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={641}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Freight Costing Payments">
            <Form.Item name="freight_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={642}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={643}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={644}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={645}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '20',
        label: 'PNBP Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="PNBP Charges">
            <Form.Item name="pnbp_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={646}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={647}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={648}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={649}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={650}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={651}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={652}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={653}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="PNBP Charges Invoices">
            <Form.Item name="pnbp_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={654}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={655}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={656}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={657}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={658}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={659}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={660}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={661}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="PNBP Charges Payments">
            <Form.Item name="pnbp_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={662}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={663}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={664}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={665}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '21',
        label: 'Other Income',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Other Income">
            <Form.Item name="other_income" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={666}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={667}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={668}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={669}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={670}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={671}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={672}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={673}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Income Invoices">
            <Form.Item name="other_income_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={674}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={675}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={676}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={677}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={678}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={679}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={680}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={681}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Income Payments">
            <Form.Item name="other_income_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={682}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={683}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={684}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={685}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '18',
        label: 'Insurance',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Insurance">
            <Form.Item name="insurance" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={686}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={687}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={688}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={689}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={690}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={691}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={692}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={693}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Insurance Invoices">
            <Form.Item name="insurance_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={694}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={695}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={696}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={697}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={698}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={699}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={700}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={701}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Insurance Payments">
            <Form.Item name="insurance_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={702}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={703}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={704}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={705}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },



      {
        key: '22',
        label: 'Dead Freight',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Dead Freight">
            <Form.Item name="dead_freight" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={706}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={707}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={708}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={709}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={710}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={711}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={712}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={713}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Dead Freight Invoices">
            <Form.Item name="dead_freight_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled
                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={714}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={715}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={716}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={717}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={718}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={719}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={720}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={721}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Dead Freight Payments">
            <Form.Item name="dead_freight_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={722}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={723}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={724}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={725}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '23',
        label: 'Demurrage',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Demurrage">
            <Form.Item name="demurrage" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={726}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={727}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={728}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={729}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={730}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={731}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={732}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={733}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Demurrage Invoices">
            <Form.Item name="demurrage_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={734}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={735}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={736}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={737}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={738}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={739}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={740}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={741}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Demurrage Payments">
            <Form.Item name="demurrage_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={742}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={743}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={744}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={745}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '24',
        label: 'Credit And Debit Notes',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Credit And Debit Notes">
            <Form.Item name="cre_deb_notes" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={746}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={747}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={748}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={749}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={750}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={751}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={752}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={753}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Credit And Debit Notes Invoices">
            <Form.Item name="cre_deb_notes_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={754}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={755}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={756}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={757}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={758}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={759}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={760}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={761}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Credit And Debit Notes Payments">
            <Form.Item name="cre_deb_notes_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Checkbox
                      value='1,2,3,4'
                      disabled

                    >
                      Select All
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={762}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={763}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={764}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={765}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },

    ];


  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Collapse
        size="large"
        expandIconPosition='end'
        bordered={false}
        defaultActiveKey={['0']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
    </Form>

  )
}