import { CheckCircleOutlined, CopyOutlined, DownCircleFilled, EditOutlined, FileExcelFilled, PlusSquareOutlined, RotateRightOutlined, SplitCellsOutlined, UpCircleFilled } from '@ant-design/icons';
import { ContractDetailsDto, ContractIdRequest, ContractModesEnum, ContractStatusEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, SalesContractService, TaxesAndItemsService } from '@exportx/shared-models-and-services';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Input, Modal, Row, Table, Tabs, Tooltip } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import ContractTransfer from './contract-transfer';
import QualityDetailView from './quality-detail-view';


const { TabPane } = Tabs;
export const SalesContractView = () => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [, setPage] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState([]);
  const service = new SalesContractService();
  let pathToreDirect = '/#/so-detail-view';
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [plantCode, setPlantCode] = useState<any>()
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const taxesAndItemsService = new TaxesAndItemsService();
  const [transferFormRef] = Form.useForm();
  const [contractDate, setContractDate] = useState<any>();

  useEffect(() => {
    if (!isPermissionExist([96])) return navigate('/')

    getAllSalesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, []);

  const getAllSalesData = () => {
    service.getAllSaleContracts({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        const processedData = res.data.map(item => {
          const uniqueQualities = Array.from(new Set(item.qualities.split(' / '))).join(' / ');
          return { ...item, qualities: uniqueQualities };
        });
        setGridData(processedData);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
        return false
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  };


  useEffect(() => {
    getTaxesByItemsId(contractDate)
  }, [contractDate]);



  const transferModelOncancel = () => {
    setShowTransferModal(false);
    transferFormRef.resetFields();
  }

  const tableColumns: any = [
    {
      title: 'Contract No.',
      dataIndex: 'contractNo',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.contractNo !== null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const isAnyKeyMatches = new Set(Object.keys(record).map((key) => {
          return String(record[key]).toLowerCase().includes(value.toLocaleString())
        }))
        if (isAnyKeyMatches.size && isAnyKeyMatches.has(true))
          return true;
        else
          return false;
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const link = `${pathToreDirect}?sc_id=${record.pcId}`
        // return <><a href={link} className="link-primary" onClick={() => redirectToFullView(record.pcId, record.plantCode)}>
        //     {record.contractNo}
        // </a></>
        return <><a href={link} className="link-primary" >
          {record.contractNo}
        </a></>
      }
    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      width: 150,
      align: 'left',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      width: 230,
      align: 'left',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <>
        {record?.customerName}
      </>,
    },
    {
      title: 'Quality',
      dataIndex: 'quality',
      width: 200,
      align: 'left',
      render: (text, record) => <b>
        {
          `${record.qualities}`
        }
      </b>
    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.currencyName.localeCompare(b.currencyName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      align: 'center',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <>
        <StatusTag status={record.status} />
      </>,
    },
    {
      title: 'Vessel Type',
      dataIndex: 'vesselType',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.vesselType.localeCompare(b.vesselType),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Load Port',
      dataIndex: 'pol',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.pol.localeCompare(b.pol),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Load Rate',
      dataIndex: 'loadingRateForGeared',
      children: [
        {
          title: 'Geared',
          dataIndex: 'loadingRateForGeared',
          width: 130,
          align: 'left',
          sorter: (a, b) => a.loadingRateForGeared.localeCompare(b.loadingRateForGeared),
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Gearless',
          dataIndex: 'loadingRateForGearless',
          width: 130,
          align: 'left',
          sorter: (a, b) => a.loadingRateForGearless.localeCompare(b.loadingRateForGearless),
          sortDirections: ['descend', 'ascend'],
        }]
    },
    {
      title: "Action",
      align: 'left',
      className: 'operations',
      fixed: 'right',
      children: [
        {
          title: '-',
          dataIndex: 'action',
          fixed: 'right',
          width: 150,
          align: 'left',
          render: (text, rowData: any) => {
            return rowData.isTransferredContract ? <b>This Contract Has Been Transferred</b> :
              <span>
                {(rowData.status === ContractStatusEnum.DRAFT || rowData.status === ContractStatusEnum.PENDING_APPROVAL) ?
                  <>
                    {isPermissionExist([97]) && <Tooltip placement="top" title={fm({ id: "common.edit", defaultMessage: 'Edit' })}>
                      <EditOutlined type="edit"
                        onClick={() => {
                          editClickHandler(rowData.pcId, rowData.plantCode)
                        }}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                      />
                    </Tooltip>} <span> | </span></> : null
                }

                <>{isPermissionExist([100]) && <Tooltip placement="top" title={fm({ id: "common.clone", defaultMessage: 'Clone' })}>
                  <CopyOutlined
                    style={{ color: '#1890ff', fontSize: '14px' }}
                    onClick={() => {
                      cloneHandler(rowData.pcId, rowData.plantCode);
                    }} />
                </Tooltip>}
                  <span> | </span></>

                {(rowData.status === ContractStatusEnum.DRAFT) ? <>{isPermissionExist([96]) && <Tooltip placement="top" title={fm({ id: "common.sendForApproval", defaultMessage: 'Send For Approval' })}>
                  <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: '#1890ff', fontSize: '14px' }}
                    onClick={() => {
                      sendForApproval(rowData.pcId, rowData.plantCode);
                    }} className={`hover ${rowData.status !== ContractStatusEnum.DRAFT ? 'disabled-button' : ''}`} />
                </Tooltip>}<span> | </span></> : null}
                {(rowData.status === ContractStatusEnum.PENDING_APPROVAL) ?
                  <>{isPermissionExist([104]) && <Tooltip placement="top" title={fm({ id: "common.approve", defaultMessage: 'Approve' })}>
                    <CheckCircleOutlined type='approve'
                      onClick={() => {
                        approveSaleOrder(rowData.pcId, rowData.plantCode);
                      }}
                      style={{ color: '#1890ff', fontSize: '14px' }}
                    />
                  </Tooltip>}<span> | </span></> : null
                }
                {(rowData.status === ContractStatusEnum.ACTIVE) ?
                  <>
                    {isPermissionExist([101]) &&
                      <Tooltip placement="top" title={'Addendum'}>
                        <PlusSquareOutlined type="edit"
                          onClick={() => {
                            addendumClickHandler(rowData.pcId, rowData.plantCode);
                          }}
                          style={{ color: '#1890ff', fontSize: '14px' }}
                        />
                      </Tooltip>} <span> | </span></> : null
                }

                {rowData.transferLogId == null && <span>
                  <>{isPermissionExist([102]) && <Tooltip title='Transfer'><RotateRightOutlined className={'editSamplTypeIcon'} type="transfer" onClick={() => { setShowTransferModal(true); setPlantCode({ plantCode: rowData?.plantCode, previousContractId: rowData?.pcId }) }}
                    style={{ color: '#1890ff', fontSize: '14px' }}
                  /></Tooltip>}<span> | </span></>
                  <Tooltip title='Sub Job'><SplitCellsOutlined className={'editIcon'} type="subPo"
                    onClick={() => {
                    }}
                    style={{ color: '#1890ff', fontSize: '14px' }}
                  /></Tooltip>
                </span>}
              </span>
          }


        },
      ]
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record.pcId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }

  const renderItems = (record: ContractDetailsDto, index, indent, expanded) => {
    // getTaxesByItemsId(record.contractDate);
    setContractDate(record.contractDate)
    return <QualityDetailView pcId={record.pcId} type={ContractModesEnum.SALES} classes='contracts' plantCode={record.plantCode} snapShotData={[]} contractType={record.contractType} vat={vat} />
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    // cloneArr.splice(0, 1);
    // cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`Sales-${currentDate}.xlsx`);
  };

  const cloneHandler = (pcId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(pcId), plantCode };
    service.cloneSaleOrder(req).then(res => {
      if (res.status) {
        getAllSalesData();
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const editClickHandler = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.getSalesPreviewData(req).then(res => {
      if (res.status) {
        navigate('/so-update', { state: res.data });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const addendumClickHandler = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.getSalesPreviewData(req).then(res => {
      if (res.status) {
        navigate('/so-addendum', { state: res.data });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const sendForApproval = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.sendForApproval(req).then(res => {
      if (res.status) {
        getAllSalesData()
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const approveSaleOrder = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.approveSaleOrder(req).then(res => {
      if (res.status) {
        getAllSalesData()
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order approved successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  return (<>
    <Card
      title={<span style={{ color: 'white' }}>Sale Order Details</span>}
      style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
    >
      <Row justify='space-between'>
        <Col span={12}>
          <Row>
            <Col >
              <Card title={"Total SO's: " + gridData.length} style={{ textAlign: 'left', backgroundColor: '#bfbfbf' }} bodyStyle={{ display: 'none' }}></Card>
            </Col>
            <Col >
              <Card title={'OPEN :' + gridData.filter(el => el.status === ContractStatusEnum.DRAFT).length} style={{ textAlign: 'left', backgroundColor: '#f6e552' }} bodyStyle={{ display: 'none' }}></Card>
            </Col>
            <Col>
              <Card title={'IN-PROGRESS :' + gridData.filter(el => el.status == ContractStatusEnum.ACTIVE).length} style={{ textAlign: 'left', backgroundColor: '#f2a637' }} bodyStyle={{ display: 'none' }}></Card>
            </Col>

          </Row>
        </Col>
        <Col>
          <Row>
            <Col><Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} /></Col>
            <Col>
              {isPermissionExist([95]) &&
                <Tooltip placement="topRight" title="Create">
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => navigate('/so-creation')}
                  >
                    Create
                  </Button>
                </Tooltip>
              }
              {isPermissionExist([106]) &&
                <Tooltip placement="topRight" title="Export As Excel">
                  <Button
                    disabled={gridData.length === 0}
                    size='small'
                    type="default"
                    className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                    onClick={handleExport}
                    icon={<FileExcelFilled />}
                  >Export</Button>
                </Tooltip>
              }

            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        expandIconColumnIndex={0}
        className='contracts'
        rowKey={record => record.pcId}
        columns={tableColumns}
        dataSource={gridData}
        expandable={{
          expandedRowRender: renderItems,
          expandedRowKeys: expandedIndex,
          onExpand: setIndex,
          fixed: 'right'
        }}
        expandIcon={({ expanded, onExpand, record }) =>
          expanded ? (
            <UpCircleFilled
              onClick={(e) => onExpand(record, e)}
            >
              Collapse
            </UpCircleFilled>
          ) : (
            <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
          )
        }
        pagination={{
          onChange(current) {
            setExpandedIndex([]);
            setPage(current);
          }
        }}
        scroll={{ x: 500 }}
        size="large"
        bordered
      />

    </Card>

    <Modal
      open={showTransferModal}
      //key={Date.now()}
      onCancel={transferModelOncancel}
      footer={null} width={750}>
      <ContractTransfer plantCode={plantCode} formRef={transferFormRef} contractType={"Sale Contract"} />
    </Modal>

  </>);
}

export default SalesContractView;