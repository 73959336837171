import Icon, { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { BPDropDownDto, BpCategoryGetDto, BpCurrencyResponseDto, BusinessPartnerService, BusinessPartnerTypeEnum, CPSPreviewDto, ContractIdRequest, ContractStatusEnum, CurrencyDto, CurrencyService, PaymentModesEnum, PcQualitiesDropDownDto, PcTypesDropDownDto, PriceTypeEnum, ProcurementContractService, ProcurementContractTypeEnum, PurchaseContractService, PurchaseDropDownDto, SalesContractService } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Button, Card, Col, Divider, Form, Input, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, useAuthState } from '../../../common';
import DatePicker from '../../../common/data-picker/date-picker';
import PaymentTerm from '../creation-forms/payment-terms';
import QualitiesForm from './qualities-form';
import queryString from 'query-string';


const { Option } = Select;


interface IProcurementServiceContractProps {
    contractType?: ProcurementContractTypeEnum;
    contractId?: string;
    setProcurementModal?: (value: boolean) => void;
    getProcurementServiceFees?: () => void;
    closeDrawer?: string;
    setDummyState?: React.Dispatch<React.SetStateAction<number>>;
    poAction?: string
}


export const ProcurementServiceContract = (props: IProcurementServiceContractProps) => {
    const { authContext } = useAuthState();
    const { formatMessage: fm } = useIntl();

    const { contractType, contractId, poAction, setProcurementModal, getProcurementServiceFees, closeDrawer, setDummyState } = props;
    const [contractsData, setContractsData] = useState<PurchaseDropDownDto[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
    const [businessPartners, setBusinessPartners] = useState<BPDropDownDto[]>([]);
    const [qualitiesData, setQualitiesData] = useState<BpCurrencyResponseDto>();
    const [contractCurrency, setContractCurrency] = useState(authContext.defaultPlantCurrency);
    const [formValues, setFormValues] = useState<any>({ paymentTerms: { paymentMode: PaymentModesEnum.TELEGRAPHIC_TRANSFER }, filesData: [''], });
    const [activeSubFormData, setActiveSubFormData] = useState({ priceType: PriceTypeEnum.LUMP_SUM, });
    const [activeIndex, setActiveIndex] = useState(0);
    const [subFormData, setSubFormData] = useState([]);
    const [qualitiesDropdown, setQualitiesDropdown] = useState<PcQualitiesDropDownDto[]>([]);
    const [pcTypes, setPcTypes] = useState<PcTypesDropDownDto[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.cpcId;
    let { cpcId }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };




    const [formRef] = Form.useForm();
    const [subFormRef] = Form.useForm();




    const businessPartnerService = new BusinessPartnerService();
    const currencyService = new CurrencyService();
    const purchase = new PurchaseContractService();
    const sales = new SalesContractService();
    const service = new ProcurementContractService();


    let pathToreDirect = '';
    if (contractType === ProcurementContractTypeEnum.Procurement_Fees) {
        pathToreDirect = '/procurementServiceFee';
    }
    if (contractType === ProcurementContractTypeEnum.Coal_Service_Fees) {
        pathToreDirect = '/coalServiceFee';
    }
    if (contractType === ProcurementContractTypeEnum.Marketing_Fees) {
        pathToreDirect = '/marketing';
    }


    useEffect(() => {
        formRef.setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        setActiveIndex(subFormData.length);
    }, [subFormData])


    useEffect(() => {
        if (contractType === ProcurementContractTypeEnum.Marketing_Fees) {
            getAllSaleContractsForDropDown();
            getAllBusinessPartnersDropDown();
        } else {
            getAllPurchaseContracts();
            getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
        }
        getCurrencyData();

    }, []);


    useEffect(() => {
        if (cpcId)
            getServiceContractPreviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpcId]);

    const getServiceContractPreviewData = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, contractType, undefined, authContext.user.userName);
        service.getServiceContractPreviewData(req).then(res => {
            if (res.status) {
                purchaseContractOnChange(res.data.purchaseContractNo, true)
                setFormValues(res.data);
                setSubFormData(res.data.quality);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    };

    useEffect(() => {
        if (formValues?.contractDate) {
            formRef.setFieldValue('contractDate', moment(formValues.contractDate))
        }
    }, [formValues?.contractDate])




    const getAllPurchaseContracts = () => {
        purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setContractsData(res.data);
                if (contractId) {
                    purchaseContractOnChange(contractId)
                }
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };

    const getAllSaleContractsForDropDown = () => {
        sales.getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setContractsData(res.data);
                if (contractId) {
                    purchaseContractOnChange(contractId)
                }
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };


    const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
        businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
            if (res.status) {
                setBusinessPartners(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };
    const getAllBusinessPartnersDropDown = () => {
        businessPartnerService.getAllBusinessPartnersDropDown().then(res => {
            if (res.status) {
                setBusinessPartners(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };

    const getCurrencyData = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrencies(res.data);
            }
        }).catch(err => {
            console.log('error:' + err.message);
        });
    }

    const purchaseContractOnChange = (value, isUsEffectCall?: boolean) => {
        if (value === undefined) {
            formRef.resetFields();
            subFormRef.resetFields();
            setFormValues(undefined);
            setSubFormData([]);
            setActiveSubFormData({ priceType: PriceTypeEnum.LUMP_SUM });
        } else {
            if (value !== formValues?.purchaseContractNo) {
                setSubFormData([]);
            }
            getQualitiesData(value, isUsEffectCall);
        }
    }

    useEffect(() => {
        if (qualitiesData)
            setQualities();
    }, [qualitiesData]);

    useEffect(() => {
        if (activeSubFormData?.['pcQualitiesId'])
            setPcTypesHandler(activeSubFormData?.['pcQualitiesId']);
    }, [activeSubFormData]);



    const getQualitiesData = (contractId: string, isUsEffectCall?: boolean) => {
        const req = new ContractIdRequest(contractId, authContext.defaultPlant)
        const service = contractType === ProcurementContractTypeEnum.Marketing_Fees ? sales : purchase;
        service.getQualitiesDropDown(req).then(res => {
            if (res.status) {
                setQualitiesData(res.data);
                if (!isUsEffectCall) {
                    const vendorId = res.data?.businessPartner;
                    const currencyId = res.data?.currency;
                    const contractDate = moment(res.data?.contractDate);
                    const purchaseContractNo = contractId;
                    setFormValues(prev => {
                        return {
                            ...prev,
                            purchaseContractNo,
                            vendorId,
                            currencyId,
                            contractDate
                        }
                    });
                    setContractCurrency(res.data.currency);
                }
            }
        }).catch(err => {
            console.log('error:' + err.message);
        })
    }

    const rowEditHandler = (rowData: any, index) => {
        let newRwoData = { ...rowData };
        if (rowData?.layCan) {
            newRwoData = {
                ...rowData,
                layCan: [moment(rowData.layCan[0]), moment(rowData.layCan[1])],
            }
        }
        setActiveSubFormData(newRwoData);
        setActiveIndex(index);
    }

    const rowDeleteHandler = (index) => {
        setSubFormData(prev => {
            const prevSubFormData = [...prev]
            prevSubFormData.splice(index, 1);
            return prevSubFormData;
        });
        setActiveIndex(subFormData.length);
    }

    const getColumns = () => {
        const columns: ColumnProps<any>[] = [];
        if (formRef.getFieldValue('purchaseContractNo')) {
            columns.push(
                {
                    title: 'Laycan',
                    dataIndex: 'layCan',
                    align: 'center',
                    render: (value, record) => {
                        const layCan = record?.layCan ? Array.isArray(record?.layCan) ? `${moment(record.layCan[0]).format('DD MMM YYYY')} - ${moment(record.layCan[1]).format('DD MMM YYYY')}` : `${moment(record.layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(record.layCan.split(',')[1]).format('DD MMM YYYY')}` : ''
                        return <>
                            {layCan}
                        </>
                    }
                },
                {
                    title: 'Quality',
                    dataIndex: 'purchaseQuality',
                    align: 'center',
                },
                {
                    title: 'Type',
                    dataIndex: 'pcPurchaseType',
                    align: 'center',
                }
            )
        }
        columns.push({
            title: 'Price Type ',
            dataIndex: 'priceType',
            align: 'center',
        },
            {
                title: 'Quantity',
                dataIndex: 'quantityInMT',
                align: 'center',

            },
            {
                title: 'Price',
                dataIndex: 'totalPrice',
                align: 'center',
                render: (value, record) => {
                    return <>
                        {record?.priceType === PriceTypeEnum.LUMP_SUM ? value : record?.priceType === PriceTypeEnum.PER_MT ? record?.pricePMT + " (PMT) " : undefined}
                    </>

                }
            },
            {
                title: "QualityAdjustment",
                dataIndex: 'qualityAdjustment',
                align: 'center',

            },


            {
                title: fm({ id: "mdm.common.action", defaultMessage: 'Action' }),
                dataIndex: 'action',
                width: '20%',
                render: (_text, rowData: any, index) => (
                    <span>
                        <Tooltip placement="top" title={'Edit'}>
                            <Icon component={EditOutlined} onClick={_e => {
                                rowEditHandler(rowData, index)
                            }} style={{ color: "#1890ff", fontSize: '24px' }} />
                        </Tooltip>
                        <Divider type="vertical" />
                        <Popconfirm
                            onConfirm={_e => {
                                rowDeleteHandler(index);
                            }}
                            onCancel={_e => {
                                // ToDo
                            }}
                            title={'Are you sure! you want to delete this record ?'}>
                            <Tooltip placement="top" title={'Delete'}>
                                <Icon component={DeleteFilled} style={{ color: "red", fontSize: '24px' }} />
                            </Tooltip>
                        </Popconfirm>
                    </span>)
            })
        return columns;
    }

    const subFormHandler = (values: any) => {
        if (Number(subFormRef.getFieldValue('quantityInMT')) == 0) {
            AlertMessages.getErrorMessage('Quantity Should not be 0')
            return false;
        }
        if (formRef.getFieldValue('purchaseContractNo')) {
            if (!validateQuantityExceededOrNot()) {
                return
            }
        }
        setSubFormData(prev => {
            const data = [...prev]
            const currentData = prev[activeIndex] ? prev[activeIndex] : {};
            data[activeIndex] = { ...currentData, ...values };
            return data
        });
        setActiveSubFormData({ priceType: PriceTypeEnum.LUMP_SUM });
        subFormRef.resetFields();
        if (formRef.getFieldValue('purchaseContractNo')) {
            subFormRef.setFieldsValue({ priceType: PriceTypeEnum.LUMP_SUM, quantityInMT: undefined, layCan: undefined, totalPrice: undefined, pcQualitiesId: undefined, pcPurchaseTypeId: undefined });
        } else {
            subFormRef.setFieldsValue({ priceType: PriceTypeEnum.LUMP_SUM, quantityInMT: undefined, totalPrice: undefined, });
        }
        setActiveIndex(prevIndex => {
            if (subFormData.length == prevIndex) {
                return prevIndex + 1;
            } else {
                return subFormData.length + 1;
            }
        });
    }

    const setPcTypesHandler = (qualityId?: string) => {
        let pcTypesToBeSet = [];
        const selectedQualityId = qualityId ? qualityId : subFormRef.getFieldValue('pcQualitiesId')
        if (qualitiesData && selectedQualityId) {
            const rec = qualitiesData.pcQualities.filter(rec => rec.pcqId === selectedQualityId)[0]
            if (rec.pcTypes) {
                pcTypesToBeSet = rec.pcTypes
            } else {
                pcTypesToBeSet = []
            }
        }
        setPcTypes(pcTypesToBeSet)
    }

    const setQualities = () => {
        let qualitiesToSet = []
        if (qualitiesData) {
            qualitiesToSet = qualitiesData.pcQualities
        }
        setQualitiesDropdown(qualitiesToSet);
    }


    const formValuesChangeHandler = (changedValues: any, values: any) => {
        const changedValueKeys = Object.keys(changedValues);
        if (changedValueKeys.includes('pcQualitiesId')) {
            if (qualitiesData && subFormRef.getFieldValue('pcQualitiesId')) {
                const rec = qualitiesData.pcQualities.filter(rec => rec.pcqId === subFormRef.getFieldValue('pcQualitiesId'))[0]
                setActiveSubFormData(prev => {
                    return {
                        ...prev,
                        ...values,
                        layCan: [moment(rec.layCan.split(',')[0]), moment(rec.layCan.split(',')[1])],
                        quantityInMT: rec.pcqQuantity,
                        purchaseQuality: rec.pcqQuality
                    }
                })
                setPcTypesHandler();
            }
        } else {
            if (changedValueKeys.includes('quantityInMT') || changedValueKeys.includes('pricePMT')) {
                if (subFormRef.getFieldValue('priceType') === PriceTypeEnum.PER_MT) {
                    const pricePMT = Number.isNaN(Number(subFormRef.getFieldValue('pricePMT'))) ? 1 : Number(subFormRef.getFieldValue('pricePMT'));
                    const quantityInMT = Number.isNaN(Number(subFormRef.getFieldValue('quantityInMT'))) ? 1 : Number(subFormRef.getFieldValue('quantityInMT'));
                    values['totalPrice'] = pricePMT * quantityInMT;
                }
            }
            if (qualitiesData && subFormRef.getFieldValue('pcPurchaseTypeId')) {
                const rec = qualitiesData.pcQualities.filter(rec => rec.pcqId === subFormRef.getFieldValue('pcQualitiesId'))[0];
                if (rec.pcTypes) {
                    const rec1 = rec.pcTypes.filter(rec => rec.pcTypeId === subFormRef.getFieldValue('pcPurchaseTypeId'))[0];
                    values['pcPurchaseType'] = rec1?.purchaseType
                }
            }
            setActiveSubFormData(prev => {
                return {
                    ...prev,
                    ...values
                }
            })
        }
    }

    const validateQuantityExceededOrNot = () => {
        const rec = qualitiesData.pcQualities.filter(rec => rec.pcqId === subFormRef.getFieldValue('pcQualitiesId'))[0];
        console.log(subFormRef.getFieldValue('pcQualitiesId'))
        if (rec && rec.pcqQuantity) {
            const nonActiveQualities = subFormData.filter((val, index) => {
                if (subFormRef.getFieldValue('pcQualitiesId') === val.pcQualitiesId && index != activeIndex) {
                    return val;
                }
            })
            const nonActiveQualitiesTotal = nonActiveQualities.reduce((total, obj) => Number(obj.quantityInMT) + total, 0);
            const overallPresentQty = Number(nonActiveQualitiesTotal) + Number(subFormRef.getFieldValue('quantityInMT'));
            if (Number(overallPresentQty) <= Number(rec.pcqQuantity)) {
                return true;
            } else {
                AlertMessages.getErrorMessage('Quantity Should not exceed than contract quantity ' + rec.pcqQuantity)
                return false;
            }
        } else {
            AlertMessages.getErrorMessage('Quantity Should not exceed than contract quantity ' + rec.pcqQuantity)
            return false;
        }
    }


    const createProcurementAddendum = () => {
        formRef.validateFields().then(async values => {
            if (subFormData.length === 0) {
                AlertMessages.getErrorMessage('Please Enter Quantity Details');
                return;
            }
            const procurementData = { ...values, plantCode: authContext.defaultPlant, quality: subFormData, contractType: contractType, versionFlag: formValues?.versionFlag };
            procurementData.filesData = await getFilesData(values.filesData)
            service.createProcurementAddendum(procurementData).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    if (closeDrawer) {
                        setProcurementModal(false);
                        getProcurementServiceFees();
                        setDummyState(prev => prev + 1);
                    } else {
                        setTimeout(() => {
                            navigate(pathToreDirect, { state: { returnContractType: contractType } });
                        }, 1000);
                    }

                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })
        })
    }


    const saveOrUpdate = () => {
        formRef.validateFields().then(async values => {
            if (subFormData.length === 0) {
                AlertMessages.getErrorMessage('Please Enter Quantity Details');
                return;
            }
            const procurementData = { ...values, plantCode: authContext.defaultPlant, quality: subFormData, contractType: contractType, versionFlag: formValues?.versionFlag };
            procurementData.filesData = await getFilesData(values.filesData)
            service.createProcurementContract(procurementData).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    if (closeDrawer) {
                        setProcurementModal(false);
                        getProcurementServiceFees();
                        setDummyState(prev => prev + 1);
                    } else {
                        setTimeout(() => {
                            navigate(pathToreDirect, { state: { returnContractType: contractType } });
                        }, 1000);
                    }

                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })
        })
    };

    const submit = () => {
        if (formValues.status === ContractStatusEnum.ACTIVE) {
            createProcurementAddendum();
        } else {
            saveOrUpdate();
        };
    };

    return (
        <Card
            title={<span> {contractType} Contract </span>}
            className='default-card-class'
            actions={[
                <Row justify='space-around'>
                    <Col><Button onClick={() => { setProcurementModal(false); formRef.resetFields(); setDummyState((prev) => prev + 1); }}>Cancel</Button></Col>
                    <Col>
                        <Button onClick={() => { submit(); }} type="primary">
                            Submit
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Form
                form={formRef}
                layout='vertical'
                initialValues={formValues}
                autoComplete='off'>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item name={"pcID"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item name={"contractNo"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item name="purchaseContractNo"
                            label={contractType === ProcurementContractTypeEnum.Marketing_Fees ? 'Sale Contract' : fm({ id: "mdm.components.procurmentservice.purchaseContact", defaultMessage: 'Purchase Contract' })}
                            rules={[{ required: true, message: 'Please select Contract No' }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder={contractType === ProcurementContractTypeEnum.Marketing_Fees ? fm({ id: `common.procurmentservice.reqPurchaseContract`, defaultMessage: `select Sale Contract` }) : fm({ id: `common.procurmentservice.reqPurchaseContract`, defaultMessage: ` select Purchase Contract` })}
                                style={{ minWidth: 200, width: '100%' }}
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                onChange={(value) => purchaseContractOnChange(value, false)}
                                disabled={contractId ? true : poAction ? true : false}
                            >
                                {contractsData.map(item => {
                                    return <Option value={item.pcId} key={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
                        <Form.Item
                            name="contractDate"
                            label={fm({ id: "mdm.components.procurmentservice.contractDate", defaultMessage: 'Contract Date' })}
                            rules={[{ required: true, message: 'Contract Date' }]}
                        >
                            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
                        <Form.Item name="vendorId"
                            label={fm({ id: "mdm.components.procurmentservice.vendor", defaultMessage: 'Vendor' })}
                            rules={[{ required: true, message: fm({ id: `common.procurmentservice.reqVendor`, defaultMessage: `Please select Vendor` }) }]}>
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                placeholder={fm({ id: `common.procurmentservice.reqVendor`, defaultMessage: `Please select Vendor` })}
                                style={{ width: '100%' }}
                            >

                                {businessPartners.map(item => {
                                    return <Option value={item.bpId} key={item.bpId}>{item.bpName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
                        <Form.Item name="currencyId"
                            label={fm({ id: "mdm.components.procurmentservice.currency", defaultMessage: 'Currency' })}
                            rules={[{ required: true, message: fm({ id: `common.procurmentservice.reqCurrency`, defaultMessage: `Please Select Currency` }) }]}>
                            <Select
                                allowClear
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={fm({ id: `common.procurmentservice.reqCurrency`, defaultMessage: `Please Select Currency` })}
                                onChange={currency => setContractCurrency(currency)}
                            >
                                {currencies.map(item => {
                                    return <Option value={item.currencyCode} key={item.currencyCode}>{item.currencyName}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <QualitiesForm contractType={contractType} contractCurrency={contractCurrency} purchaseContractNo={formRef.getFieldValue('purchaseContractNo')} initialValues={activeSubFormData} subFormHandler={subFormHandler} subFormRef={subFormRef} qualities={qualitiesDropdown} pcTypes={pcTypes} formValuesChangeHandler={formValuesChangeHandler} />
                <br />
                <Card>
                    <Table
                        dataSource={subFormData}
                        columns={getColumns()}
                        pagination={false}
                    />
                </Card>
                <br></br>
                <PaymentTerm formRef={formRef} initialValues={formValues?.paymentTerms ? formValues?.paymentTerms : undefined} layoutType={'vertical'} />
                <br />

                <Form.Item
                    name="remarks"
                    label={fm({ id: `mdm.components.procurmentservice.reqRemarks`, defaultMessage: `REMARKS` })}>
                    <Input.TextArea placeholder={fm({ id: `mdm.components.procurmentservice.reqRemarks`, defaultMessage: `REMARKS` })}
                    />
                </Form.Item>

                <FileUploadForm maxCount={5} layoutType='vertical' formRef={formRef} initialValues={poAction !== 'addendum' ? formValues?.filesData : ['']} />
            </Form>
        </Card>
    )
}

export default ProcurementServiceContract;