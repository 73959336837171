import './shipping-terms-form.css';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { ShippingTermsDto, ShippingTermsService } from '@exportx/shared-models-and-services';
import { AlertMessages, useAuthState } from '../../../../../common';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
export interface ShippingTermsFormProps {

}

export function ShippingTermsForm(
  props: ShippingTermsFormProps
) {
  const { state }: any = useLocation();
  const { authContext } = useAuthState();//to access global authorized user data(Login user Data which is passed as created user or updated user)
  //Common for all Page Components
  const { isUpdate, shippingtermsData, isNewRecord } = state ? state : { isUpdate: false, shippingtermsData: null, isNewRecord: true }; // Read values passed on router  
  const [form] = Form.useForm();
  const service = new ShippingTermsService();
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();




  const save = (Data: ShippingTermsDto) => {
    service.create(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Shipping Terms Created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  let pathToreDirect = '/shippingTerms';


  const submitFormHandler = () => {
    form.validateFields().then(values => {
      submitForm(values);
    })
  };

  const updateUser = (Data: ShippingTermsDto) => {
    service.update(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Shipping Terms Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const submitForm = (values: any) => {
    if (isUpdate && shippingtermsData?.shippingTermsId) {
      updateUser({ ...shippingtermsData, ...values, updatedUser: authContext.user.userName });
      return;
    }
    const createDto: ShippingTermsDto = new ShippingTermsDto(values.shippingTermsId, values.shippingTermsName, values.isActive, authContext.user.userName);
    save(createDto);
  }



  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card bodyStyle={{ overflow: 'auto' }} title={fm({ id: 'mdm.components.Shipping Form.header', defaultMessage: 'Shipping Terms' })}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect)}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >
      <Form layout="vertical" form={form} initialValues={shippingtermsData} autoComplete='off'>
        <Row>
          <Form.Item name="shippingTermsId" style={{ display: 'none' }}>
            <Input hidden />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="createdUser">
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 6 }} xl={{ span: 6 }}>
            <Form.Item
              name="shippingTermsName"
              label={fm({ id: "mdm.components.shippingTermsForm.shippingTermsName", defaultMessage: 'Shipping Terms Name' })}
              rules={[
                {
                  required: true,
                  message: 'Enter the Shipping Term Name'
                },
                // {

                //   pattern: /^[^-\s\\0-9\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z ]*$/,
                //   message: `Should contain only alphabets.`
                // }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {(isNewRecord || isUpdate) &&
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onReset} >
                Clear
              </Button>
              <Button type="primary" htmlType="submit" onClick={submitFormHandler}>
                Submit
              </Button>


            </Col>
          </Row>}
      </Form>
    </Card>


  );
}

export default ShippingTermsForm;
