import { CPSPreviewDto, ContractStatusEnum, ContractTypeReqDto, ProcurementContractService, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import StatusApprovalsButton from '../../../common/status-tag/status-approvals-button-component';

interface contractTypeCategory {
  contractTypeCategory?: ProcurementContractTypeEnum;
}


export const ProcurementServiceGrid = (props: contractTypeCategory) => {
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState<any>([])
  const service = new ProcurementContractService()
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("")
  const { contractTypeCategory } = props
  const { authContext } = useAuthState();

  let pathToreDirect = '';
  if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
    pathToreDirect = '/procurement-contract-detailedView'
  }
  if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
    pathToreDirect = '/coalServiceFee-detailedView'
  }
  if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
    pathToreDirect = '/marketing-detailedView'
  }

  useEffect(()=>{
    if(!isPermissionExist([147,156,165])) return navigate('/')

  },[])

  useEffect(() => {
    if (contractTypeCategory) {
      getAllServiceContracts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractTypeCategory]);

  const getAllServiceContracts = () => {
    const req = new ContractTypeReqDto();
    req.contractType = contractTypeCategory;
    req.plantCode = authContext.defaultPlant;
    service.getAllServiceContracts(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };


  const procurementStatusApproval = (status: ContractStatusEnum, id: string) => {
    const req = new CPSPreviewDto(id, authContext.defaultPlant, undefined, status, authContext.user.userName)
    service.procurementStatusApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllServiceContracts()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }


  const columns: ColumnsType<any> = [
    {
      title: fm({ id: "mdm.components.procurmentService.contractNumber", defaultMessage: 'Contract Number' }),
      dataIndex: 'contractNo',
      key: 'contractNo',
      sorter: (a, b) => a.contractNo !== null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendor)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, record) => {
        const link = `/#${pathToreDirect}?cpcId=${record.pcID}`
        return PermissionExist('view') ?  <Link href={link} className="link-primary" >{record.contractNo}</Link> : <span>{record.contractNo}</span>
      },
    },

    {
      title: fm({ id: "mdm.components.procurmentService.contractDate", defaultMessage: 'Contract Date' }),
      dataIndex: 'contractDate',
      sorter: (a, b) => a.contractDate !== null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      key: 'contractDate',

    },

    {
      title: fm({ id: "mdm.components.procurmentService.vendorName", defaultMessage: 'Vendor Name' }),
      dataIndex: 'vendor',
      sorter: (a, b) => a.vendor !== null ? a.vendor.localeCompare(b.vendor) : "Have Null Values",
      key: 'vendor',

    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.pricePMT != null ? a.pricePMT.localeCompare(b.pricePmt) : "Have Null Values",
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },
    {
      title: fm({ id: 'mdm.components.procurmentService.status', defaultMessage: "Status" }),
      dataIndex: 'Action',
      hidden: isPermissionExist([151, 160, 169, 152, 161, 170]),
      sorter: (a, b) => a.status !== null ? a.status.localeCompare(b.status) : "Have Null Values",
      render: (_record, value) => <>
        <StatusApprovalsButton status={value.status} statusApproval={procurementStatusApproval} id={value.pcID} from={contractTypeCategory} />
      </>,

    },

  ];

  const createHandler = () => {
    let path = '/procurement-service-create';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
      path = '/procurement-service-create'
    } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
      path = '/marketing-service-create'
    } else {
      path = '/coalServiceFee-service-create'
    }
    navigate(path)
  }

  const PermissionExist =(type: string) =>{
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
      return type === 'Create' ?  isPermissionExist([146])  : isPermissionExist([147])
    } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
      return type === 'Create' ? isPermissionExist([164])  : isPermissionExist([165])
    }
    return type === 'Create' ? isPermissionExist([155]) : isPermissionExist([156])

  }
  return (

    <>
      <Card
        className='default-card-class'
        title={<span> {contractTypeCategory} Contract <Divider type='vertical' plain></Divider><Tag color='blue'> {{ gridData } ? gridData.length : null}</Tag> </span>}
        extra={PermissionExist('Create') ? <Button
          onClick={() => createHandler()
          }
        >
          Create
        </Button> : null}>
        <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} style={{ width: 200, float: "right" }} />
        <Table columns={columns} dataSource={gridData} pagination={false}
          rowKey={(row) => row.pcID} />
      </Card>
    </>



  )
}

export default ProcurementServiceGrid;